import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { SessionService } from '@services';
import { AppSettings } from '@settings'
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'embryo-header-user-profile',
	templateUrl: './HeaderUserProfileDropdown.component.html',
	styleUrls: ['./HeaderUserProfileDropdown.component.scss']
})

export class HeaderUserProfileDropdownComponent implements OnInit {
	public imgUser;
	private localStorageService = inject(LocalStorageService)
	private $onDestroy = new Subject<void>()

	constructor(
		public router : Router,
		public service_session:SessionService,
		private _snackBar: MatSnackBar,
		private settings : AppSettings
	) { }

	ngOnInit() {
		this.localStorageService.userInfoObservable.pipe(takeUntil(this.$onDestroy)).subscribe(res=>this.imgUser = this.settings.urls.hostFiles+res.foto+this.settings.imgXS)
	  }

	irAPerfil(){
		this.router.navigate(['/admin-panel/account/profile'])
	}

	desconectarSession(){
		//localStorage.clear();
		this._snackBar.dismiss()
		this.service_session.logout()
		/*this.service_session.notificarLoginStatus()
		this.router.navigate(['/home'])*/
	}  
	//log out method 
	logOut(){
		document.getElementById('html').classList.remove("admin-panel");
		this.router.navigate(['/session/signin']);
	}
}
