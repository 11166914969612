import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterial} from '@app/_helpers/';
import { PreguntasAdminComponent } from './preguntas/preguntas.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PreguntasRoutes } from './Preguntas.routing';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [	
		PreguntasAdminComponent
	],
	imports: [
		CommonModule,
		AngularMaterial,
		NgxPaginationModule,
		RouterModule.forChild(PreguntasRoutes)
	]
})

export class PreguntasAdminModule { }
