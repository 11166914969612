import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Inject, Injectable, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppSettings } from '@settings'; //modulo global de direcciones.
import { ToastaConfig, ToastaService, ToastOptions } from 'ngx-toasta';
import { LocalStorageService } from './local-storage-service';




@Injectable()

@Injectable()
export class FavoritosService {
  //estadoUsuario;
  private usuarioConectado
  private $favoritosChange = new Subject<any>() //subject se encarga de producir el flujo de eventos
  public favoritosChange  = this.$favoritosChange.asObservable()
  estadoRefreshFavoritos;
  host = this.appSettings.urls['host']
  recurso = this.appSettings.urls['favoritas'];
  public localStorageWishlist : any;
  private $whishListCountSource = new BehaviorSubject<Number>(0)
  public navbarWishlistProdCount = this.$whishListCountSource.asObservable();
  private localStorageService = inject(LocalStorageService)

  constructor(
    private http: HttpClient,
    private appSettings:AppSettings,
    private toastyService: ToastaService,
    private toastyConfig: ToastaConfig,
    ) {
      
    }

    public async getFavoritos(idComprador){
        return this.http.get(`${this.host+this.recurso}/compradorID/${idComprador}`).toPromise()
    }

    public async addToWishlist(data: any){
        if(this.localStorageService.getCompradorInfo().ID){
           let dato = {
              'compradorID' : this.localStorageService.getCompradorInfo().ID,
              'publicacionID' : data['ID']
           }
           let result = await this.http.post(`${this.host+this.recurso}`,dato).toPromise()
           console.log(result)
           data.idFavorito=result['data'].ID
           //console.log(data)
        }
  
        let toastOption: ToastOptions = {
           title: "Agregando producto",
           msg: "Agregando a favoritos",
           showClose: true,
           timeout: 1000,
           theme: "material"
        };
  
        let products : any;
        //console.log(localStorage.getItem("wishlist_item"))
        products = JSON.parse(localStorage.getItem("wishlist_item")) || [];
        let productsLength = products.length;
  
        let found = products.some(function (el, index) {
           if(el.titulo == data.titulo){
              if(!data.cantidadMinima) { data.cantidadMinima = 1}
              products[index]['cantidad'] = data.cantidadMinima;
              return  true;
           }
        });
        if (!found) { products.push(data); }
  
        if(productsLength == products.length) {
           toastOption.title = "Producto repetido";
           toastOption.msg = "Ya tienes este producto en favoritos";
        }
  
        this.toastyService.wait(toastOption);
        setTimeout(() => {
           localStorage.setItem("wishlist_item", JSON.stringify(products));
           this.calculateLocalWishlistProdCounts();
        }, 500);
        
     }

     public async removeLocalWishlistProduct(product: any) {
        let products: any = JSON.parse(localStorage.getItem("wishlist_item"));
        console.log(products)
        for (let i = 0; i < products.length; i++) {
           if (products[i].ID === product.ID) {
              let res = await this.deleteFavorito(products[i].idFavorito)
              products.splice(i, 1);
             
              console.log(res)
              break;
           }
        }
  
        const toastOption: ToastOptions = {
           title: "Eliminar producto",
           msg: "Quitando producto de favoritos",
           showClose: true,
           timeout: 1000,
           theme: "material"
        };
  
  
        this.toastyService.wait(toastOption);
        setTimeout(() => {
           // ReAdding the products after remove
           localStorage.setItem("wishlist_item", JSON.stringify(products));
           this.calculateLocalWishlistProdCounts();
        }, 500);
        
     }

     public async deleteFavorito(idFavorito){
        return this.http.delete(`${this.host+this.recurso}/id/${idFavorito}`).toPromise()
     } 

    public calculateLocalWishlistProdCounts() {

        this.localStorageWishlist = null;
        this.localStorageWishlist = JSON.parse(localStorage.getItem("wishlist_item")) || [];
        this.$whishListCountSource.next(this.localStorageWishlist.length)
        //this.navbarWishlistProdCount = +((this.localStorageWishlist).length);
    }

    public notificarFavoritosChange(){
        this.$favoritosChange.next()
    }
    
}