//---------------------------- @angular ---------------------------------------------//
import { HttpClientModule } from '@angular/common/http';
import { Inject, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router, RouterModule, Scroll } from '@angular/router';
//-------------------------------------- @ngx y extras -------------------------------------//
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ToastaModule } from 'ngx-toasta';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { routes } from './app-routing.module';
import { AppSettings } from './app.settings';
//--------------------------------------Modulos globales ---------------------------------------//
import { AdminPanelModule } from './Pages/AdminPanel/admin-panel.module'
import { TemplatesModule } from './Pages/Templates/Templates.module';
import { GlobalModule } from '@app/_helpers';
//-------------------------------------Componente globales -------------------------//
import { AutocompleteComponent } from './theme/components/autocomplete/autocomplete.component';
import { FooterOneComponent } from './theme/components/Layouts/Footer/FooterOne/FooterOne.component';
import { FixedHeaderComponent } from './theme/components/Layouts/Header/FixedHeader/FixedHeader.component';
import { HeaderTwoComponent } from './theme/components/Layouts/Header/HeaderTwo/HeaderTwo.component';
import { MenuComponent } from './theme/components/Layouts/Menu/Menu/Menu.component';
import { SideBarMenuComponent } from './theme/components/Layouts/Menu/SidebarMenu/SidebarMenu.component';
import { PaymentDetailSideBarComponent } from './theme/components/Layouts/PaymentDetailSideBar/PaymentDetailSideBar.component';
import { NotFoundComponent } from './theme/components/NotFound/NotFound.component';
import { CartComponent} from '@components/Cart/Cart.component';
import { CoreModule } from './_helpers';
import { MenuItems } from './_models';
import { PagesComponent} from '@app/Pages/pages.component';
import { PopUpComponent } from './theme/components/PopUp/popup.component';
//--------------------------------------Variables globales y service globales -----//

import { EmbryoService, MenuService, SessionService, WebSocketService,ProvinciasService,CarritoService } from './_services';
import { RoleGuardService } from '@app/_helpers/';
import { MobbexGuardService } from '@app/_helpers/mobbex.guard'
import { RoleGuardServiceGlobal } from '@app/_helpers/';
import { FavoritosService } from './_services/favoritos.service';
/*********************************** ANALYTICS **************************************/
import { ServiceWorkerModule } from '@angular/service-worker';
import { OpcionesEnvioComponent } from './theme/components/OpcionesEnvio/opcionesEnvio.component';
import { AppInstallBannerComponent } from './theme/components/AppInstallBanner/appInstallBanner.component';
import { ChatService } from './_services/chat.service';
import { ChatSocketService } from './_services/chatSocket.service';
import { ReactiveFormsModule } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { ViewportScroller, registerLocaleData } from '@angular/common';
import { LocalStorageRefService } from './_services/local-storage-ref.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import localeEsAR from '@angular/common/locales/es-AR'; // Importa el archivo de datos regionales para es-AR





@NgModule({
    declarations: [
        PagesComponent,
        AppComponent,
        PopUpComponent,
        FooterOneComponent,
        MenuComponent,
        SideBarMenuComponent,
        NotFoundComponent,
        PaymentDetailSideBarComponent,
        HeaderTwoComponent,
        FixedHeaderComponent,
        AutocompleteComponent,
        CartComponent,
        OpcionesEnvioComponent,
        AppInstallBannerComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'embryo-seo-pre' }),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes,{anchorScrolling:'enabled',scrollPositionRestoration:'enabled'}),
        /*Mugan86GoogleAnalyticsModule.forRoot(
           {
             analyticsId: 'UA-181009143-1',
             showLog: false
           }
         ),*/
        TemplatesModule,
        FlexLayoutModule,
        HttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        ToastaModule.forRoot(),
        SlickCarouselModule,
        //PerfectScrollbarModule,
        AdminPanelModule,
        BrowserAnimationsModule,
        NgxMatSelectSearchModule,
        CoreModule,
        GlobalModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        AppSettings,
        MenuItems,
        MenuService,
        EmbryoService,
        /*{
           provide: PERFECT_SCROLLBAR_CONFIG,
           useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },*/
        SessionService,
        AppSettings,
        RoleGuardService,
        MobbexGuardService,
        RoleGuardServiceGlobal,
        ProvinciasService,
        CarritoService,
        FavoritosService,
        WebSocketService,
        ChatService,
        ChatSocketService,
        LocalStorageRefService,
        {
          provide:MAT_DATE_LOCALE,
          useValue:'es-AR'
        }
    ],
    exports: [
        RouterModule,
        ToastaModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  noScrollUrls=['/about/faq']
   constructor(private router: Router, private viewportScroller: ViewportScroller, private route:ActivatedRoute,@Inject(LOCALE_ID) private locale: string) {
      viewportScroller.setOffset([0, 60]);
      registerLocaleData(localeEsAR, 'es-AR');
      router.events.pipe(filter(e=>e instanceof NavigationEnd)).subscribe((e:NavigationEnd)=>{
        if(!this.noScrollUrls.includes(e.url.split('?')[0])){
          this.viewportScroller.scrollToPosition([0,0])
        }
      })
      /*router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {
        console.log('router event')
        if (e.anchor) {
          console.log('scroll ancla',e.anchor)
          // anchor navigation
          // setTimeout is the core line to solve the solution 
          setTimeout(() => {
            this.viewportScroller.scrollToAnchor(e.anchor);
          },1500)
        } else if (e.position) {
          // backward navigation
          setTimeout(()=>{
            this.viewportScroller.scrollToPosition(e.position);
          },1500)
        } else {
          // forward navigation
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });*/
      
    }

 }
