import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable, inject } from '@angular/core';
import { AppSettings } from '@settings'; //modulo global de direcciones.
import { map } from "rxjs/operators"; //mapeo de respuesta de base de datos.
import { LocalStorageService } from './local-storage-service';

@Injectable({
  providedIn: 'root'
})

export class PreguntasService {
  host = this.appSettings.urls['host'] // variable global con la ip de la api.
  recurso_preguntas = this.appSettings.urls['preguntas']
  private localStorageService = inject(LocalStorageService)

  constructor(private http: HttpClient,
    public appSettings:AppSettings) { }

  getPreguntas(vendedorId:number, page:number, estado:string){
    return this.http.get(`${this.host+this.recurso_preguntas}/vendedorID/${vendedorId}/page/${page}/estado/${estado}`).pipe(map(res => res ));
  }

  patchRespuesta(idPregunta:number,respuesta:string,pregunta:string,idComprador:number,idPublicacion){
    console.log(`${this.host+this.recurso_preguntas}/respuesta/id/${idPregunta}`)
    return this.http.patch(`${this.host+this.recurso_preguntas}/respuesta/id/${idPregunta}`,
    { 'respuesta':respuesta,
      'pregunta':pregunta,
      'compradorID':idComprador,
      'publicacionID': idPublicacion
    }).pipe(map(res => res ))
  }

  patchEstado(idPregunta:number,estado:string){
    return this.http.patch(`${this.host+this.recurso_preguntas}/estado/id/${idPregunta}`,{'estado':estado}).pipe(map(res => res ))
  }

  cantidadPreguntas(){
    return this.http.get(`${this.host+this.recurso_preguntas}/cant/vendedorID/${this.localStorageService.getVendedorInfo().ID}`).pipe(map(res => res ))
  }
}
