import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PublicidadesService} from '@services';
import { AppSettings} from '@settings';

@Component({
   selector: 'embryo-HomePageTwoSlider',
   templateUrl: './HomePageTwoSlider.component.html',
   styleUrls: ['./HomePageTwoSlider.component.scss'],
   providers : [PublicidadesService,AppSettings]
})
export class HomePageTwoSliderComponent implements OnInit, OnChanges {

   public urlFiles = this.appSettings.urls['hostFiles'];
   public extension = this.appSettings.imgPubLG;
   public cargando;

   @Input() isRTL : any;

   slideConfig : any;
   
   slides = [];

   constructor(
      public service_publicidades:PublicidadesService,
      public appSettings:AppSettings
   ) { }

   ngOnInit() {
      this.cargando = false;
      this.cargarPublicidades();
   }

   ngOnChanges() {
      /*VARIABLE DE CONFIGURACION DEL CARROUSEL */
      this.slideConfig = {
         infinite: true,
         centerMode: true,
         centerPadding: '400px',
         slidesToShow: 1,
         slidesToScroll: 1,
         autoplay: false,
         autoplaySpeed: 2000,
         dots: true,
         rtl: this.isRTL,
         responsive: [
            {
               breakpoint: 1400,
               settings: {
                  arrows: false,
                  centerMode: false,
                  centerPadding: '300px',
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: false,
                  autoplaySpeed: 2000
               }
            },
            {
               breakpoint: 1199,
               settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '150px',
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: true,
                  autoplaySpeed: 2000
               }
            },
            {
               breakpoint: 899,
               settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '75px',
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: true,
                  autoplaySpeed: 2000
               }
            },
            {
               breakpoint: 768,
               settings: {
                  arrows: false,
                  centerMode: false,
                  centerPadding: '0',
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: true,
                  autoplaySpeed: 2000
               }
            },
            {
               breakpoint: 480,
               settings: {
                  arrows: false,
                  centerMode: false,
                  centerPadding: '0',
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: true,
                  autoplaySpeed: 2000
               }
            }
         ]
      };
      /*FIN | VARIABLE DE CONFIGURACION DEL CARROUSEL */
   }

   /*FUNCION QUE LLAMA A LA API GENERICO Y PREGUNTA CUALES SON LAS IMAGENES DEL SOCALO DE HOME QUE POSEE HIPERABASTO */

   async cargarPublicidades(){
      this.cargando = true; 
      let result = await this.service_publicidades.getPublicidades('HOME').toPromise();
      if(result['code'] == 200){
         let socalo = result['data'][0];
         socalo.pubs.forEach(pub => {
            let img = {
               img : this.urlFiles+pub.img+this.extension+pub.imgType,
               url: pub.url
            }
            this.slides.push(img);
         });
      }else{
         console.log(result);
      }
      this.cargando = false;
      this.slideConfig = Object.assign({},this.slideConfig);
   }

   /*FIN | FUNCION QUE LLAMA A LA API GENERICO Y PREGUNTA CUALES SON LAS IMAGENES DEL SOCALO DE HOME QUE POSEE HIPERABASTO */


   /*FUNCION QUE SIMPLEMENTE HACE UN TARGET _BLANK Y ABRE LA DIRECCION DE LA PUBLICIDAD */

   irAPublicidad(url){
      console.log(url);
      window.open(url, "_blank");
   }

   /*FIN | FUNCION QUE SIMPLEMENTE HACE UN TARGET _BLANK Y ABRE LA DIRECCION DE LA PUBLICIDAD */

}
