export class Tarjeta{
    constructor(){
        this.last = false;
    }

    ID;
    personaID:string;
    tipo:string;
    numero:string;
    titular:string;
    vencimiento:string;
    estado:string;
    //flag
    last:boolean;

}