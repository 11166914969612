import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, NgZone, inject} from '@angular/core';
import { AppSettings } from '@settings';
import { EmbryoService } from '@services';
import { FavoritosService } from '@app/_services/favoritos.service';
import { LocalStorageService } from '@app/_services/local-storage-service';

@Component({
  selector: 'embryo-ProductGrid',
  templateUrl: './ProductGrid.component.html',
  styleUrls: ['./ProductGrid.component.scss'],
  providers: [AppSettings],
  //changeDetection : ChangeDetectionStrategy.OnPush
})
export class ProductGridComponent implements OnInit {

   @Input() products : any ;

   @Input() currency : string;

   @Input() gridLength : any;

   @Input() gridThree : boolean = false;

   @Input() snTienda

   @Output() addToCart: EventEmitter<any> = new EventEmitter();

   @Output() addToWishList: EventEmitter<any> = new EventEmitter();

   @Output() onCantidadChange : EventEmitter<{product:any,cantidad:number}> = new EventEmitter()

   loaded = false;

   public fav = false
   public sinStock
   public menorAMinimo
   public vistaOrdenador

   trackByObjectID(index, hit) {
      return hit.objectID;
   }
   
   constructor(
      public appSetting: AppSettings,
      public embryoService : EmbryoService,
      private _favoritosService : FavoritosService
      ) { 
   }

   ngOnChanges(): void {
      //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
      //Add '${implements OnChanges}' to the class.
      console.log('change')
   }

   ngOnInit() {
      console.log(this.products)
      if(screen.width >= 767){
         this.vistaOrdenador = true
      }else{
         this.vistaOrdenador = false
      }
      this.comprobarFavoritos(this.products)
   }

   comprobarFavoritos(products){
      products.forEach(producto => {
         if(this.checkWishAlready(producto)){
            producto.inWishList = true
         }
      });
   }

   public addToCartProduct(value:any) {
      console.log(value)
      value.cantidadSeleccionada = value.compraMinima
      this.addToCart.emit(value);
   }

   public onLoad() {
      this.loaded = true;
   }

   public cantidadChange(event:{product:any,cantidad:number}){
      this.onCantidadChange.emit(event)
      console.log(event)
   }

   public addToWishListFunction(value){
      this.addToWishList.emit(value)
   }

   public checkWishAlready(singleProduct){
      let products = this._favoritosService.localStorageWishlist
      let control = false;
      products?.forEach(producto=>{
         if(producto.ID == singleProduct.ID){
            control = true

         }
      })
      return control
   }

   public DeleteWishList(singleProduct,parentClass){
      document.getElementById(parentClass).getElementsByTagName('i')[0].classList.remove('favorito-icon-selected');
      this._favoritosService.removeLocalWishlistProduct(singleProduct)
   }

}
