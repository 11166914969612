import { HttpClient, HttpParams } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppSettings } from '@settings'; //modulo global de direcciones.
import { map } from "rxjs/operators"; //mapeo de respuesta de base de datos.
import { DeleteListDialogComponent } from '@components/DeleteListDialog/DeleteListDialog.component';
import { PublicacionCatalogoEdit } from '@app/_interfaces/publicacion-catalogo-edit.interface';
import { VentasListadoResponse } from '@app/_interfaces/ventas-list.interface';

@Injectable()

export class ProductsService {
  host = this.appSettings.urls['host'] // variable global con la ip de la api.
  recurso_categorias = this.appSettings.urls['categorias']
  recurso_productos = this.appSettings.urls['productos']
  recurso_marcas = this.appSettings.urls['marcas']
  recurso_publicaciones = this.appSettings.urls['publicaciones']
  recurso_medidas = this.appSettings.urls['unidadesMedidas']
  recurso_carritos = this.appSettings.urls['carritos']
  constructor(
    private http: HttpClient,
    public appSettings:AppSettings,
    private dialog: MatDialog,
  ) 
  { 
  
  }

  getCategoriasVendedor(){
    return this.http.get(`${this.host+this.recurso_categorias}/edit`)
  }

  getCategorias(){
    return this.http.get(`${this.host+this.recurso_categorias}`)
  }

  getProductoAtributos(id:number){
    return this.http.get(`${this.host+this.recurso_productos}/id/${id}`)
  }

  getProductos(idCategoria:number){
    return this.http.get(`${this.host+this.recurso_productos}/categoriaid/${idCategoria}`)
  }

  getMarcas(){
    return this.http.get(`${this.host+this.recurso_marcas}`).pipe(map(res => res ))
  }

  getUnidades(){
    return this.http.get(`${this.host+this.recurso_medidas}`).pipe(map(res => res))
  }

  getPublicacionesUsuarioId(idUsuario:number,page:number){
    return this.http.get(`${this.host+this.recurso_publicaciones}/edit/vendedorid/${idUsuario}/page/${page}`)
  }

  getPublicacionEditId(idPublicacion:number){
    return this.http.get(`${this.host+this.recurso_publicaciones}/edit/id/${idPublicacion}`)
  }

  getPublicacionCatalogoEditId(idPublicacion:number){
    return this.http.get<{code:number,data:PublicacionCatalogoEdit}>(`${this.host+this.recurso_publicaciones}/catalogo/edit/id/${idPublicacion}`)
  }

  getProductoPublicacionEditId(idPublicacion:number,idProducto:number){
    return this.http.get(`${this.host+this.recurso_publicaciones}/atributos/edit/publicacionID/${idPublicacion}/productoID/${idProducto}`)
  }

  getDescuentosPublicacionId(idPublicacion:number){
    return this.http.get(`${this.host+this.recurso_publicaciones}/precios/edit/publicacionID/${idPublicacion}`)
  }

  getVentas(idVendedor:number,page:number,estado:string){
    return this.http.get(`${this.host+this.recurso_carritos}/vendedorID/${idVendedor}/page/${page}/estado/${estado}`)
  }

  getVentasEstadoEnvio(idVendedor:number,estadoEnvio:string){
    return this.http.get<VentasListadoResponse>(`${this.host+this.recurso_carritos}/vendedorID/${idVendedor}/estado-envio/${estadoEnvio}`)
  }

  getVentasFromTo(from:string,to:string,idVendedor:number,page:number,estado:string,tipo:'COMPRA'|'PRECOMPRA'|'CATALOGO'='COMPRA'){
    let params = new HttpParams()
    .set('estado',estado)
    .set('from',from)
    .set('to',to)
    .set('tipo',tipo)
    console.log(params)
    return this.http.get<VentasListadoResponse>(`${this.host+this.recurso_carritos}/vendedorID/${idVendedor}/p/${page}/l/10`,{params})
  }

  getMensajesCarritos(vendedorId:Number,page:Number,limit:Number,estado?:string){
    return this.http.get(`${this.host+this.recurso_carritos}/con-chat/vendedorID/${vendedorId}/p/${page}/l/${limit}/e/${(estado)? estado : ''}`)
  }

  getCarrito(carritoID:number){
    return this.http.get(`${this.host+this.recurso_carritos}/ID/${carritoID}`)
  }

  /**
   * obtiene los productos en los que el vendedor ha generado publicaciones
   * @param idVendedor 
   */
  getProductosVendedor(idVendedor:number){
    return this.http.get<{code:number,data:Array<{ID:number,nombre:string}>}>(`${this.host}/productos/vendedorID/${idVendedor}`)
  }



  postPublicacion(productoCompleto){
    let json = JSON.stringify(productoCompleto)
    json = JSON.parse(json)
    console.log(json)
    return this.http.post(`${this.host+this.recurso_publicaciones}`, json)
  }

  postImgPublicacion(imagen,usuarioID,publicacionID){
    return this.http.post(`${this.host+this.recurso_publicaciones}/imgs/vendedorid/${usuarioID}/publicacionid/${publicacionID}`, imagen)
  }

  postAtributosPublicacion(data){
    data = JSON.stringify(data)
    data = JSON.parse(data)
    console.log(data)
    return this.http.post(`${this.host+this.recurso_publicaciones}/atributos`,data)
  }

  postDescuento(descuento){
    return this.http.post(`${this.host+this.recurso_publicaciones}/precios`,descuento)
  }

  postBusqueda(titulo,vendedorID,page){
    let body = {'titulo': titulo}
    return this.http.post(`${this.host+this.recurso_publicaciones}/search/titulo/vendedorID/${vendedorID}/page/${page}`,body)
  }

  putPublicacionId(idPublicacion:number, datosPublicacion){
    let json = datosPublicacion
    console.log(json)
    return this.http.put(`${this.host+this.recurso_publicaciones}/id/${idPublicacion}`, json)
  }

  putPublicacionCatalogoId(idPublicacion:number,datosPublicacion){
    return this.http.put(`${this.host+this.recurso_publicaciones}/catalogo/id/${idPublicacion}`, datosPublicacion)
  }

  putDescuentoId(idDescuento:number, descuento){
    return this.http.put(`${this.host+this.recurso_publicaciones}/precios/id/${idDescuento}`,descuento)
  }

  putAtributoId(idAtributo:number, atributo){
    return this.http.put(`${this.host+this.recurso_publicaciones}/atributos/id/${idAtributo}`, atributo)
  }

  deleteImgPublicacion(idImg,url){
    let json = {'url':url}
    return this.http.patch(`${this.host+this.recurso_publicaciones}/imgs/id/${idImg}`, json)
  }
  
  deleteDialog(data:string,titulo:string){
		let dialogRef : MatDialogRef<DeleteListDialogComponent>;
		dialogRef = this.dialog.open(DeleteListDialogComponent);
    dialogRef.componentInstance.data = data;
    dialogRef.componentInstance.titulo = titulo
		
		return dialogRef.afterClosed();
  }
  
  deleteDescuento(idDescuento:number,publicacionID){
    return this.http.delete(`${this.host+this.recurso_publicaciones}/precios/id/${idDescuento}/publicacionID/${publicacionID}`)
  }
  
  patchEstado(id:number,estado){
    return this.http.patch(`${this.host+this.recurso_publicaciones}/estado/id/${id}`,estado)
  }

  patchEstadoVenta(id:number,estado){
    return this.http.patch(`${this.host+this.recurso_carritos}/estado/id/${id}`,estado)
  }

  patchEstadoEnviado(id:number,estado){
    return this.http.patch(`${this.host+this.recurso_carritos}/enviado/id/${id}`,estado)
  }

  patchEstadoRecibido(id:number,estado){
    return this.http.patch(`${this.host+this.recurso_carritos}/recibido/id/${id}`,estado)
  }
  
}
