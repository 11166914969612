import { Injectable, inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "@app/app.settings";
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { WebSocketService } from "./websocket.service";
import { SessionService } from "./session.service";
import { ChatService } from "./chat.service";
import { LocalStorageService } from "./local-storage-service";

@Injectable()
export class NotificacionesService {
    host = this.appSettings.urls['host'] // variable global con la ip de la api.
    recurso_notif = this.appSettings.urls['notificaciones']
    private cantNot = 0
    private $cantNotifSource = new BehaviorSubject<Number>(0)
    public $cantNotificaciones: Observable<Number> = this.$cantNotifSource.asObservable()
    public socketNotificaciones: Subject<any> //aca no habia tipado
    public socketConnected: boolean
    private localStorageServise = inject(LocalStorageService)
    constructor(
        private http: HttpClient,
        public appSettings: AppSettings,
        public _webSocketService: WebSocketService,
        public _sessionService: SessionService,
        private _chatService:ChatService
    ) {

        if (!this.socketConnected && this.localStorageServise.getUserInfo()?.ID) {
            console.log('conectando al socket')
            this.socketConnected = true
            this.socketNotificaciones = <Subject<any>>_webSocketService.connectNotificaciones().pipe(map((response) => {
                console.log(response)
                return response
            }))
        }
        this._sessionService.estadoUsuarioInterno.subscribe(res => {
            console.log('intentando conexion socket')
            if (res) {
                if (!this.socketConnected) {
                    console.log('conectando al socket')
                    this.socketConnected = true
                    this.socketNotificaciones = <Subject<any>>_webSocketService.connectNotificaciones().pipe(map((response) => {
                        console.log(response)
                        return response
                    }))
                }
            } else {
                this.socketConnected = false
                this._webSocketService.disconnectNotificaciones()
            }
        })

        /*this._chatService.$cantMensajes.subscribe(res=>{
            console.log(res)
            if(res==0){
                this.cantNot--
                this.$cantNotifSource.next(this.cantNot)
            }
        })*/

    }



    getCantidadNoLeidas(personaID: number) {
        this.http.get(`${this.host + this.recurso_notif}/personas/cant/no-leidas/personaID/${personaID}`).toPromise().then(
            res => {
                console.log(res)
                if (res['code'] == 200) {
                    this.cantNot += res['data'].cant
                    this.$cantNotifSource.next(this.cantNot)
                } else {
                    this.cantNot = 0
                    this.$cantNotifSource.next(this.cantNot)
                }
            }
        )
        //obtengo mensajes sin leer
        /*this._chatService.getCantidadNoLeidos(personaID).toPromise().then(res=>{
            console.log(res)
            if(res['code'] == 200){
                if(res['data']>0){
                    console.log('add notification')
                    this.addNotification()
                    this._chatService.setCantidadMensajes(res['data'])
                }
            }
        })*/
    }

    getNotificacionesNoLeidas(personaID: Number, page: Number, limit: Number) {
        return this.http.get(`${this.host + this.recurso_notif}/no-leidas/personaID/${personaID}/p/${page}/l/${limit}`).pipe(map(res => res))
    }

    patchVisto(notificacionId, personaId) {
        let json = {
            notificacionID: notificacionId,
            personaID: personaId
        }
        this.$cantNotifSource.next(--this.cantNot)
        return this.http.patch(`${this.host + this.recurso_notif}/snVisto`, json).pipe(map(res => res))
    }

    patchVistoTodas(personaId){
        let json = {
            personaID : personaId
        }
        return this.http.patch(`${this.host + this.recurso_notif}/snVisto/all`,json).pipe(map(res=>res))
    }

    addNotification() {
        this.cantNot++
        this.$cantNotifSource.next(this.cantNot)
    }

    marcarTodasVisto() {
        this.cantNot = 0
        this.$cantNotifSource.next(this.cantNot)
    }

    quitarNotifiacionMensajes(){
        this.cantNot--
        this.$cantNotifSource.next(this.cantNot)
    }

    restartNotifications(){
        this.cantNot = 0
        this.$cantNotifSource.next(0)
    }


}
