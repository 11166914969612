<div class="contenedor">
    <input
      matInput
      [matAutocomplete]="auto"
      (keyup)="handleChange($event)"
      style="width: 100%; padding: 10px; outline: none;"
    />
  
    <mat-autocomplete
      #auto="matAutocomplete"
      style="margin-top: 30px; max-height: 600px"
    >
      <div *ngFor="let index of state.indices || []">
        <mat-option
          *ngFor="let option of index.hits"
          [value]="option.titulo"
          (click)="verProducto(option.ID)"
        >
         <a [routerLink]="['/products/id/',option.ID]">{{ option.titulo }}</a>
        </mat-option>
      </div>
    </mat-autocomplete>
    <button type="button" style="color:black;" mat-fab class="close-btn" (click)="toggleSearch()">
        <i class="material-icons">
        close
        </i>
    </button>
</div>