import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Persona } from '@models';
import { SessionService } from '@services';
import { AppSettings } from '@app/app.settings';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'mobbex-checkout',
  templateUrl: './Mobbex.component.html',
  styleUrls: ['./Mobbex.component.css'],
})
export class MobbexComponent implements OnInit {

    urlMobbex
    urlMobbexSafe: SafeResourceUrl
  
    constructor(public sanitizer: DomSanitizer) {
    
    }

    ngOnInit() {
        this.urlMobbexSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.urlMobbex);
    }
    
}
