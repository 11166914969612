import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from "rxjs/operators"; //mapeo de respuesta de base de datos.
import { AppSettings } from '@settings'; //modulo global de direcciones.
import { environment } from '@environments/environment';
import { HomePublicacionesList } from '@app/_interfaces/home-publicaciones-list.interface';
import { VendedorDetalleResponse } from '@app/_interfaces/vendedor-detalle.interface';

@Injectable()
export class PublicacionesService {
  host = environment.host // variable global con la ip de la api.
  recurso_publicaciones = this.appSettings.urls['publicaciones']
  recurso_vendedores = this.appSettings.urls['vendedores']

  constructor(
    private http: HttpClient,
    public appSettings:AppSettings,
    private dialog: MatDialog,
  ) { }

  getPublicacionId(idPublicacion:number){
    return this.http.get(`${this.host+this.recurso_publicaciones}/id/${idPublicacion}`).pipe(map(res => res ));
  }

  getPublicacionIdCompradorId(idPublicacion:number, idComprador:number){
    return this.http.get(`${this.host+this.recurso_publicaciones}/id/${idPublicacion}/compradorID/${idComprador}`).pipe(map(res => res ));
  }

  getPublicacionVendedor(idPublicacion: any, idUsuario: any){
    return this.http.get(`${this.host+this.recurso_publicaciones}/relacionados/id/${idPublicacion}/vendedorID/${idUsuario}`).pipe(map(res => res ));
  }

  getDatosTienda(idVendedor: any){
    return this.http.get<VendedorDetalleResponse>(`${this.host+this.recurso_vendedores}/id/${idVendedor}`).pipe(map(res => res ))
  }

  getEstadisticasVendedor(idVendedor: any){
    return this.http.get(`${this.host+this.recurso_publicaciones}/estadisticas/vendedorID/${idVendedor}`).pipe(map(res => res))
  }

  getEstadisticasVendedorPublicaciones(idVendedor: any){
    return this.http.get(`${this.host+this.recurso_publicaciones}/estadisticas/vendedorID/${idVendedor}/estados`).pipe(map(res => res))
  }

  getEstadisticasMasViews(idVendedor: any, fechaDesde: any, fechaHasta: any){
    return this.http.get(`${this.host+this.recurso_publicaciones}/estadisticas/mas-views/vendedorID/${idVendedor}/from/${fechaDesde}/to/${fechaHasta}`).pipe(map(res => res))
  }

  getEstadisticasMasGanancias(idVendedor: any, fechaDesde: any, fechaHasta: any){
    return this.http.get(`${this.host+this.recurso_publicaciones}/estadisticas/mas-ganancias/vendedorID/${idVendedor}/from/${fechaDesde}/to/${fechaHasta}`).pipe(map(res => res))
  }

  
  
  putManyPrecioStock(precio: any, stock: any ,IDs: any){
    let json = {
      precio: precio,
      IDs: IDs,
      stock: stock
    }

    console.log("json put", json)
    return this.http.put(`${this.host+this.recurso_publicaciones}/many`,json).pipe(map(res => res))
  }
}
