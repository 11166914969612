import { Component, Input } from "@angular/core";
import {  MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'info-precio-envio',
    templateUrl: './infoPrecioEnvio.component.html',
    styleUrls:['./infoPrecioEnvio.component.scss']
})

export class InfoPrecioEnvioComponent{
    @Input() snGratis
    @Input() snEnvioPropio

    constructor(private dialogRef: MatDialogRef<InfoPrecioEnvioComponent>){

    }

    cerrarModal(){
        this.dialogRef.close()
    }
}