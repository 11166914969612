<div class="product-detail-page section-gap bg-white" *ngIf="detailData">
  <div class="container">
    <div fxLayout='row wrap' fxLayoutAlign="" class="col-gap">
      <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md=90 fxFlex.lg="80" fxFlex.xl="90">
        <div fxLayout='row wrap' class="col-gap position-relative">
          <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md=50 fxFlex.lg="50" fxFlex.xl="50">
            <div fxLayout='row wrap'>
              <div fxFlex.xs="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.lg="15" fxFlex.xl="15">
                <div class="product-detail-image-gallery pr-4">
                  <!--Grilla de imagenes-->
                  <mat-grid-list cols="1" rowHeight="1:1" gutterSize="20px">
                    <mat-grid-tile *ngFor="let img of detailData?.image_gallery; let i=index">
                      <div class="product-image-gallery-item">
                        <a id="{{i}}_img" class="p-link" [ngClass]="{ 'border-active': i === 0 }"
                          href="javascript:void(0)" (mouseenter)="getImagePath(img.img, i)">
                          <img appLazyLoad width="50" height="50" loading="lazy" src="{{appSettings.urls.hostFiles+img.img+appSettings.imgXS}}" alt="">
                        </a>
                      </div>
                    </mat-grid-tile>
                  </mat-grid-list>
                </div>
              </div>
              <!--imagen grande-->
              <div fxFlex.xs="85" fxFlex.sm="85" fxFlex.md="85" fxFlex.lg="85" fxFlex.xl="85" class="relative"
                *ngIf="mainImgPath">
                <embryo-ImgZoom [imgSrc]="appSettings.urls.hostFiles+mainImgPath+appSettings.imgSM"
                  imgStyle="width:auto; height:auto;"
                  resultStyle="background-repeat: no-repeat; z-index: 2; position:absolute;
                                   -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                                    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); top: 0;left: 100%;"
                  lensStyle="width:100px; height:100px" containerStyle="">
                </embryo-ImgZoom>
              </div>
            </div>
          </div>
          <!--detalles del producto-->
          <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md=50 fxFlex.lg="50" fxFlex.xl="50">
            <div class="product-detail-content">
              <!--<a href="javascript:void(0)" [routerLink]="['/']">Back to Shop</a>-->
              <h3>{{detailData?.titulo}}</h3>
              <div fxLayoutAlign="start center" class="mb-3">
                <!--<div>
                  <a href="javascript:void(0)" class="text-underline" (click)="reviewPopup(detailData)">Agregar un comentario</a>
                </div>-->
              </div>
              <div class="row">
                <div class="product-detail-price mb-1 col-6" *ngIf="!descuentoAplicado">
                  <h3 class="precio">{{totalPrice | currency:currency}} </h3>
                </div>
                <div class="product-detail-price mb-1 col-6" *ngIf="descuentoAplicado">
                  <div class="row">
                    <h5 class="precioAnterior">{{precioUnitario | currency:currency}}</h5>
                  </div>
                  <div class="row">
                    <h3 class="precio">{{totalPrice | currency:currency}}
                      <span class="porcentajeDescuento">{{descuento}}% OFF</span>
                    </h3>
                  </div>
                </div>
                <div class="col-6" *ngIf="detailData?.precios.length>0">
                  <button mat-stroked-button class="mb-1 boton-desc col-12" (click)="verDescuentos()">
                    <span class="cp">¡Mira los descuentos!</span>
                  </button>
                </div>
              </div>
              <ul class="no-style mb-4">
                <li>
                  <span class="font-medium"> Stock </span>:
                  <span *ngIf="detailData?.stock">{{detailData?.stock}}
                    <span *ngIf="detailData?.stock>1">{{detailData?.unidadesMedida?.nombrePlural}}</span>
                    <span *ngIf="detailData?.stock==1">{{detailData?.unidadesMedida?.nombre}}</span>
                  </span>

                  <span *ngIf="!(detailData?.stock)">Sin stock</span>
                </li>
                <li>
                  <span class="font-medium">Marca </span>: {{detailData?.marca?.nombre}}
                </li>
              </ul>
              
              <!--TODO el selector de cantidad debe aparecer disponible aunque la publicacion este en el carrito-->
              <!--<div class="mb-3 select-group" *ngIf="!embryoService.getStatePub(detailData?.ID) && !isVendedor">-->
                <div class="mb-3 select-group" *ngIf="!isVendedor">
                <mat-form-field class="d-flex">
                  <input matInput type="number" placeholder="Cantidad" [(ngModel)]="cantidadCompra"
                    (change)="calcularPrecio()" (keyup)="calcularPrecio()">
                  <span matSuffix>{{detailData?.unidadesMedida.siglas}}</span>

                  <mat-hint align="start" class="text-danger col-12 pl-0 pr-0" *ngIf="compraBaja">La compra mínima es de
                    {{compraMinima}}
                    <span *ngIf="detailData?.stock>1">{{detailData?.unidadesMedida?.nombrePlural}}</span>
                    <span *ngIf="detailData?.stock==1">{{detailData?.unidadesMedida?.nombre}}</span>
                  </mat-hint>
                  <mat-hint align="start" *ngIf="noCantidad">La cantidad debe ser mayor a 0</mat-hint>
                  <mat-hint align="start" *ngIf="sinStock">
                    Solo {{detailData?.stock}}
                    <span *ngIf="detailData?.stock>1"><strong>{{detailData?.unidadesMedida?.nombrePlural}}</strong>
                      disponibles</span>
                    <span *ngIf="detailData?.stock==1"><strong>{{detailData?.unidadesMedida?.nombre}}</strong>
                      disponible</span>
                  </mat-hint>
                </mat-form-field>
              </div>
              <div class="mb-3 select-group" >
                <div class="row  align-items-center">
                  <div class="col-1">
                    <mat-icon style="font-size:28px !important;" [ngClass]="{'free-shipping': shippingPrice==0}">local_shipping</mat-icon>
                  </div>
                  <div class="col text-left" *ngIf="_showAddress">
                    <span class="estilo_precio" *ngIf="shippingPrice>0">$ {{shippingPrice}}</span>
                    <span class="envio_gratis" *ngIf="shippingPrice==0">Envío gratis</span>
                    <span *ngIf="shippingPrice === undefined || shippingPrice === null">Error al calcular el envío, intenta mas tarde</span>
                    <span class="material-icons info-precio-envio" (click)="infoPrecioEnvio(shippingPrice==0)">
                      info
                    </span>
                    <mat-progress-bar *ngIf="_cargando" mode="indeterminate"></mat-progress-bar>
                  </div>

                  <div class="col text-left" *ngIf="_showMessageAddress">
                    <span class="estilo_precio">Agregue o seleccione una dirección para conocer el costo de envío</span>
                  </div>
                  <div class="col text-left" *ngIf="_showMessageLogin">
                    <span class="estilo_precio">Inicie sesión para conocer el costo de envío</span>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <span><a class="call-to-action-link" (click)="verOpcionesEnvio()" *ngIf="_showAddress && snEnvioPropio">Ver más opciones de envío</a></span>
                  </div>
                </div>

                <!--<div class="row" *ngIf="envioVendedor">
                  <div class="col">
                    <span>Envío a cargo del vendedor <mat-icon>live_help</mat-icon></span>
                    
                  </div>
                </div>-->
                <div class="mt-2 row " *ngIf="_showAddress">
                  <div class="col">
                    <span class="estilo_label">Dirección de envío: {{direccionAEnviar}}</span>
                  </div>
                </div>
              </div>
              <div class="row mt-2" *ngIf="_showButtonAddress">
                  <button mat-raised-button class="ubicacion col-6" (click)="openDialog()">
                      <mat-icon> location_on
                      </mat-icon>      
                      {{buttonChangeAddressText}}
                  </button>
              </div>
              <div class="mb-4">
                <a href="javascript:void(0)" *ngIf="!fav" class="favorito" (click)="addToWishlist(detailData)"><i
                    class="material-icons favorito-icon">favorite</i></a>
                <a href="javascript:void(0)" *ngIf="fav" class="favorito" (click)="addToWishlist(detailData)"><i
                    class="material-icons favorito-icon-selected">favorite</i></a>
              </div>
              <!--TODO En algun momento agregar el boton de agregar a carrito en esta seccion en vez de esta logica con el input-->
              <!--<div class="mb-5 row">
                <embryo-AddToCardButton [product]="publicacionDetalle" (addToCart)="addToCart($event)" (onCantidadChange)="cantidadChange($event)"></embryo-AddToCardButton>
              </div>-->
              <div *ngIf="!enCarrito && !sinStockInicial && !isVendedor"
                class="mb-5 row">
                <button mat-raised-button class="agregar-carrito col-6" (click)="addToCart(publicacionDetalle)">
                  <span class="material-icons">
                    add_shopping_cart
                  </span>
                  Agregar al carrito
                </button>
                <!--<button class="button-lg" mat-raised-button (click)="buyNow(detailData)">Ir a tienda</button>-->
              </div>
              <div *ngIf="sinStockInicial" class="mb-5 row">
                <button mat-raised-button disabled class="col-6 sin-stock">
                  Sin stock
                </button>
                <!--<button class="button-lg" mat-raised-button (click)="buyNow(detailData)">Ir a tienda</button>-->
              </div>
              
              <div class="mb-5 row" *ngIf="enCarrito">
                <button mat-raised-button class="ver-carrito col-6"
                  [routerLink]="['/cart/id/',detailData?.vendedor.ID]">
                  <span class="material-icons">
                    shopping_cart
                  </span>
                  Ver carrito
                </button>
              </div>
              <!--<div fxLayoutAlign="start center">
                <div class="mr-3"> <span>Compartir</span></div>
                <div class="detail-product-share">
                  <embryo-SocialShare></embryo-SocialShare>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="spects-header">
      <h3 class="m-0 subrayado">ESPECIFICACIONES</h3>
    </div>-->
    <mat-tab-group [color]="'accent'"  mat-align-tabs="center">
      <mat-tab label="ESPECIFICACIONES"> 
        <div class="row">
          <div *ngFor="let caracteristica of detailData?.publicacionesAtributos" class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div>
              <strong class="spectContent">{{caracteristica?.nombre}}</strong>
            </div>
            <div>
              <span class="spectTitle">{{caracteristica?.valor}}<span *ngIf="caracteristica.children != null">
                  {{caracteristica?.children?.valor}}</span></span>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="publicacionDetalle.descripcion != null" label="DESCRIPCIÓN">
        <div class="row">
          <div class="col-lg-12 col-md-6 col-sm-12 mb-3">
            <div>
              <span class="descripcion" [innerHTML]="publicacionDetalle.descripcion"></span>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    
  
      <!--<div class="spects-header">
        <h3 class="m-0 subrayado">DESCRIPCIÓN</h3>
      </div>-->
      
  </div>
</div>