<mat-tab-group  (selectedTabChange)="cambioPest($event)">
    <mat-tab label="Preguntas pendientes">
         <div *ngIf="!preguntasGrid || preguntasGrid.length==0">
            <mat-card class="product-card">
               <mat-card-content class="product-content relative">
                  <h3>No hay preguntas por responder ¡Está al día!</h3>
               </mat-card-content>
               <!-- product-content -->
            </mat-card>
         </div>
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" *ngIf="preguntasGrid && preguntasGrid.length>0">
            <div fxFlex.gt-md="25" fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="100" *ngFor="let pregunta of preguntasGrid| paginate:{itemsPerPage: 12, currentPage: paginaActual,totalItems: cantItems, id:'pendientes' }; let i=index;">
               <mat-card class="product-card">
                  
                  <div class="wishlist-icon">
                     <button mat-mini-fab color="warn" (click)="borrarPregunta(i,pregunta.ID)" title="Eliminar pregunta"><mat-icon> delete </mat-icon></button>
                  </div>
                  <!-- wishlist-icon-->
                  <mat-card-content class="product-content relative">
                     <h6>Publicacion: {{pregunta.publicacion.titulo}}</h6>
                     <h6>Fecha de pregunta:{{pregunta.createdAt}}</h6>
                     <h4><a class="title-link">{{pregunta?.pregunta}}</a></h4>
                     <mat-form-field class="w-100">
                        <textarea #respuesta matInput placeholder="Responde de manera concisa la pregunta del comprador"></textarea>
                     </mat-form-field>
                        <button mat-button (click)="responder(i,pregunta.ID,respuesta.value)">Enviar respuesta</button>
                  </mat-card-content>
                  <!-- product-content -->
               </mat-card>
            </div>
         </div>
         <pagination-controls id="pendientes" *ngIf="cantItems>12" (pageChange)="cambiarPagina($event)"
         previousLabel="Anterior"
         nextLabel="Siguiente"
         ></pagination-controls>
    </mat-tab>
   <mat-tab label="Preguntas respondidas"> 
      <div fxLayout="row wrap" fxLayoutAlign="start stretch" *ngIf="preguntasRespGrid && preguntasRespGrid.length>0">
         <div fxFlex.gt-md="25" fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="100" *ngFor="let pregunta of preguntasRespGrid| paginate:{itemsPerPage: 12, currentPage: paginaActualResp,totalItems: cantItemsResp, id:'respondidas' }; let i=index;">
            <mat-card class="product-card">
               <!-- wishlist-icon-->
               <mat-card-content class="product-content relative">
                  <h6>Publicacion: {{pregunta.publicacion.titulo}}</h6>
                  <h6>Fecha de pregunta:{{pregunta.createdAt}}</h6>
                  <h4><a class="title-link">{{pregunta?.pregunta}}</a></h4>
                  <mat-form-field class="w-100">
                     <textarea matInput disabled >{{pregunta.respuesta}}</textarea>
                  </mat-form-field>
               </mat-card-content>
               <!-- product-content -->
            </mat-card>
         </div>
      </div>
      <pagination-controls id="respondidas" *ngIf="cantItemsResp>12" (pageChange)="cambiarPagina($event)"
      previousLabel="Anterior"
      nextLabel="Siguiente"
      ></pagination-controls>
   </mat-tab>
  </mat-tab-group>