import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppSettings } from '@settings'; //modulo global de direcciones.
import { DeleteListDialogComponent } from '@app/theme/components/DeleteListDialog/DeleteListDialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from './local-storage-service';


@Injectable()
export class SucursalesService {
  estadoUsuario;
  //estadoRefreshFavoritos;
  host = this.appSettings.urls['host']
  recurso = this.appSettings.urls['sucursales']
  private localStorageService = inject(LocalStorageService)
  constructor(
    private http: HttpClient,
    private appSettings:AppSettings,
    private dialog: MatDialog) {

    }

    getSucursales(idVendedor,page){
        return this.http.get(`${this.host+this.recurso}/vendedorID/${idVendedor}/page/${page}`)
    }

    getSucursal(id){
        return this.http.get(`${this.host+this.recurso}/id/${id}`)
    }

    getCostosEnvio(sucursalID){
        return this.http.get(`${this.host+this.recurso}/precios-envios/sucursalID/${sucursalID}`)
    }

    postSucursal(sucursal){
        sucursal = JSON.stringify(sucursal)
        console.log(sucursal)
        return this.http.post(`${this.host+this.recurso}`,sucursal).pipe(map(res=>res))
    }

    postSucursales(sucursales){
        sucursales = JSON.stringify(sucursales)
        console.log(sucursales)
        return this.http.post(`${this.host+this.recurso}/many`,sucursales).pipe(map(res=>res))
    }

    postPreciosEnvios(preciosEnvios){
        return this.http.post(`${this.host+this.recurso}/precios-envios/many`,preciosEnvios).pipe(map(res=>res))
    }

    putSucursales(sucursal){
        //sucursal = JSON.stringify(sucursal)
        return this.http.put(`${this.host+this.recurso}/id/${sucursal.ID}`,sucursal).pipe(map(res=>res))
    }

    putPreciosEnvio(precios){
        return this.http.put(`${this.host+this.recurso}/precios-envios/many`,precios).pipe(map(res=>res))
    }

    putEnvioPropio(idSucursal,json){
        return this.http.put(`${this.host+this.recurso}/envio-min-sn/id/${idSucursal}`,json).pipe(map(res=>res))
    }

    patchEstadoSucursal(sucursal,estado){
        let json = {
            'estado':estado,
            'vendedorID':this.localStorageService.getVendedorInfo().ID
        }
        console.log(json)
        return this.http.patch(`${this.host+this.recurso}/estado/id/${sucursal.ID}`,json).pipe(map(res=>res))
    }

    patchEstadoPrecioEnvio(precioId,estado){
        let json = {
            'estado':estado
        }
        return this.http.patch(`${this.host+this.recurso}/precios-envios/estado/id/${precioId}`,json).pipe(map(res=>res))
    }

    deleteDialog(data:string,titulo:string){
		let dialogRef : MatDialogRef<DeleteListDialogComponent>;
		dialogRef = this.dialog.open(DeleteListDialogComponent);
        dialogRef.componentInstance.data = data;
        dialogRef.componentInstance.titulo = titulo
		
		return dialogRef.afterClosed();
    }

    
  
}