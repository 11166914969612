import { Injectable } from '@angular/core';
import { MenuService } from '../_services/menu-items.service';
import { MenuCategoria } from './menu-categorias';
/*
 * Menu interface
 */
export interface Menu {
	state: string;
	name?: string;
	type?: string;
	icon?: string;
	children?: Menu[];
}

/* 
HeaderOneItems antes
  {
  state: "home",
    name: "HOME",
    type: "sub",
    icon: "home",
    children: [
      {  
        state: 'home', 
        name: 'HOME ONE',
        type: 'link',
        icon: 'home'
      },
      {  
        state: 'home-two', 
        name: 'HOME TWO',
        type: 'link',
        icon: 'home'
      }, {
        state:'home-three',
        name: 'HOME THREE',
        type: 'link',
        icon: 'home'
      }
    ]
  },
  {
    state: "",
    name : "SHOP",
    type: "sub",
    icon: "pages",
    children: [
      {  
        state: 'products/men/4', 
        name: 'PRODUCT DETAILS',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {  
        state: 'cart', 
        name: 'CART',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {  
        state: 'checkout', 
        name: 'CHECKOUT',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {  
        state: 'checkout/payment', 
        name: 'PAYMENT',
        type: 'link',
        icon: 'arrow_right_alt'
      }
    ]
  },
  {
    state:'products/accessories',
    name:"ACCESSORIES",
    type:"link",
    icon: 'party_mode'
  }{
  state: "pages",
  name: "PAGES",
  type: "sub",
  icon: "pages",
  children: [
     {  state: 'about', 
        name: 'ABOUT',
        type: 'link',
        icon: 'arrow_right_alt',
     },
     {  state: 'term-condition', 
        name: 'TERM AND CONDITION',
        type: 'link',
        icon: 'arrow_right_alt',
     },
     {  
        state: 'privacy-policy', 
        name: 'PRIVACY POLICY',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {  
        state: 'blogs/detail', 
        name: 'BLOG DETAIL',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {  
        state: 'faq', 
        name: 'FAQ',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {  
        state: 'not-found', 
        name: '404 PAGE',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {  
         state: 'account/profile', 
         name: 'User Profile',
         type: 'link',
         icon: 'arrow_right_alt',
      },
      {
        state:'session',
        name:"SESSION",
        type:"subChild",
        icon: 'supervised_user_circle',
        children: [
            {  
            state: 'session/signin', 
            name: 'SIGN IN',
            type: 'link',
            icon: 'arrow_right_alt',
            },
            {  
                state: 'session/signup', 
                name: 'REGISTER',
                type: 'link',
                icon: 'arrow_right_alt',
            },
            {  
                state: 'session/forgot-password', 
                name: 'FORGET PASSWORD',
                type: 'link',
                icon: 'arrow_right_alt',
            },
            {  
                state: 'session/thank-you', 
                name: 'THANK YOU',
                type: 'link',
                icon: 'arrow_right_alt',
            }
          ]
       }
    ],
},
 
*/

var HeaderOneItems = new Array<MenuCategoria>();

const FooterOneItems= [
  {
     state:'',
     name:"ABOUT",
     type:"sub",
     icon: '',
     children: [
      {  
         state: 'about', 
         name: 'ABOUT',
         type: 'link',
         icon: 'arrow_right_alt',
      },
      {  
         state: 'term-condition', 
         name: 'TERM AND CONDITION',
         type: 'link',
         icon: 'arrow_right_alt',
      },
      {  
        state: 'privacy-policy', 
        name: 'PRIVACY POLICY',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {  
        state: 'faq', 
        name: 'FAQ',
        type: 'link',
        icon: 'arrow_right_alt',
       },
       {  
         state:'contact',
         name:"CONTACT US",
         type:"link",
         icon: 'perm_contact_calendar',
       }
    ]
  }, 
  {
    state:'',
    name:"SESSION",
    type:"sub",
    icon: '',
    children: [
        {  
        state: 'session/signin', 
        name: 'SIGN IN',
        type: 'link',
        icon: 'arrow_right_alt',
        },
        {  
            state: 'session/signup', 
            name: 'REGISTER',
            type: 'link',
            icon: 'arrow_right_alt',
        },
        {  
            state: 'session/forgot-password', 
            name: 'FORGET PASSWORD',
            type: 'link',
            icon: 'arrow_right_alt',
        },
        {  
            state: 'session/thank-you', 
            name: 'THANK YOU',
            type: 'link',
            icon: 'arrow_right_alt',
        }
    ]
  },
  {
    state:'',
    name:"CATEGORIES",
    type:"sub",
    mega:true,
    icon: '',
    children: [
      {  
        state: 'products/women', 
        name: 'WOMEN',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {  
        state: 'products/men', 
        name: 'MEN',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {  
        state: 'products/accesories', 
        name: 'ACCESSORIES',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {  
        state: 'products/gadgets', 
        name: 'GADGETS',
        type: 'link',
        icon: 'arrow_right_alt',
      }
    ]
  },
  {
    state:'',
    name:"SOCIAL",
    type:"sub",
    icon: '',
    children: [
      {
        state: 'https://www.facebook.com/', 
        name: 'Facebook',
        type: 'social_link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'https://twitter.com/', 
        name: 'Twitter',
        type: 'social_link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'https://www.youtube.com/', 
        name: 'Youtube',
        type: 'social_link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'https://plus.google.com', 
        name: 'Google Plus',
        type: 'social_link',
        icon: 'arrow_right_alt',
      }
    ]
  }

]

@Injectable()
export class MenuItems {

  constructor(
    public service_menu:MenuService
  	) {
      //this.cargarMenu();

    }

    async cargarMenu(){
      let result = await this.service_menu.get().toPromise();
      HeaderOneItems = []
      let menuCategoria = new MenuCategoria();
      //AGREGADO DE PRINCIPAL 
      menuCategoria.state = 'home';
      menuCategoria.name = 'Principal';
      menuCategoria.icon = 'home';
      menuCategoria.mega = false;
      menuCategoria.type = 'link';
      HeaderOneItems.push(menuCategoria);
      //AGREGADO DE CATEGORIAS
      console.log(result);
      menuCategoria = new MenuCategoria();
      menuCategoria.state = 'products';
      menuCategoria.name = 'Categorías';
      menuCategoria.icon = 'style';
      menuCategoria.mega = true;
      menuCategoria.type = 'sub';
      
      //console.log(menuCategoria);
      result['data'].forEach((elem,index)=>{

        //console.log(index);
        menuCategoria.children[index] = new MenuCategoria();
        if(menuCategoria.children.length == 0)
          menuCategoria.children[index].state = 'products/categoria/'+elem.nombre;
        else 
        menuCategoria.children[index].state = 'products/categoria/'+elem.nombre;
        menuCategoria.children[index].name = elem.nombre;
        menuCategoria.children[index].iconCustom = elem.customIcon;
        menuCategoria.children[index].mega = false;
        menuCategoria.children[index].type = 'sub';
        if(index == 1)
        menuCategoria.children[index].hidden = false;
        else
        menuCategoria.children[index].hidden = true;

        elem.children.forEach((elem1,index1)=>{
          menuCategoria.children[index].children[index1] = new MenuCategoria();
          menuCategoria.children[index].children[index1].state = 'products/categoria/'+elem.nombre+'/subcat/'+elem1.nombre;
          menuCategoria.children[index].children[index1].name = elem1.nombre;
          menuCategoria.children[index].children[index1].icon = elem1.icon;
          menuCategoria.children[index].children[index1].mega = false;
          menuCategoria.children[index].children[index1].type = 'link';

        })

        //menuCategoria.children.push(elem);
      })

      HeaderOneItems.push(menuCategoria);
      //console.log(HeaderOneItems);
      /*LLENADO DE CONTACTANOS 
      menuCategoria = new MenuCategoria();
      menuCategoria.state = 'contact';
      menuCategoria.name = 'Contactanos';
      menuCategoria.icon = 'perm_contact_calendar';
      menuCategoria.type = 'link';
      HeaderOneItems.push(menuCategoria);
      */
      /*AGREGO TIENDAS ONLINE*/
      menuCategoria = new MenuCategoria();
      menuCategoria.state = 'tiendas/listado';
      menuCategoria.name = 'Tiendas';
      menuCategoria.icon = 'store';
      menuCategoria.type = 'link';
      HeaderOneItems.push(menuCategoria);

      /*AGREGO CATALOGO ONLINE*/
      menuCategoria = new MenuCategoria();
      menuCategoria.state = 'catalogos';
      menuCategoria.name = 'Catalogos';
      menuCategoria.icon = 'store';
      menuCategoria.type = 'link';
      HeaderOneItems.push(menuCategoria);

      /*AGREGO PREGUNTAS FRECUENTES*/
      menuCategoria = new MenuCategoria();
      menuCategoria.state = 'about/faq';
      menuCategoria.name = 'Preguntas frecuentes';
      menuCategoria.icon = 'contact_support';
      menuCategoria.type = 'link';
      HeaderOneItems.push(menuCategoria);

      /*AGREGO NOSOTROS*/
      menuCategoria = new MenuCategoria();
      menuCategoria.state = 'about/about';
      menuCategoria.name = 'Nosotros';
      menuCategoria.icon = 'emoji_people';
      menuCategoria.type = 'link';
      HeaderOneItems.push(menuCategoria);


      //LLENADO DE ADMIN PANEL
      
      menuCategoria = new MenuCategoria();
      menuCategoria.state = 'admin-panel';
      menuCategoria.name = 'Panel';
      menuCategoria.type = 'link';
      menuCategoria.icon = 'settings';

      HeaderOneItems.push(menuCategoria);


      /*AGREGO REGISTRAR MI NEGOCIO*/
      menuCategoria = new MenuCategoria();
      menuCategoria.state = 'about/info-vendedor';
      menuCategoria.name = 'Registrar tu negocio';
      menuCategoria.type = 'link';
      menuCategoria.icon = 'storefront';

      HeaderOneItems.push(menuCategoria);

    }
   /*
    * Get all header menu
    */
   getMainMenu(): Menu[] {    
     //console.log(HeaderOneItems)
      return HeaderOneItems;
   }

   /*
    * Get all footer menu
    */
   getFooterOneMenu(): Menu[] {
      return FooterOneItems;
   }
}
