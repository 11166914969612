import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { PublicacionCatalogoItem } from '@app/_interfaces/publicaciones-catalogo-listado.interface';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'selector-cantidad',
    templateUrl: './selector-cantidad.component.html',
    styleUrls: ['./selector-cantidad.component.scss'],
    standalone:true,
    imports:[
        MatFormFieldModule,
        ReactiveFormsModule,
        MatIconModule,
        NgIf
    ]
})
export class SelectorCantidadComponent implements OnInit {
    @Input() product: PublicacionCatalogoItem
    @Input() cantidadInicial: number = 1
    @Output() onCantidadChange: EventEmitter<number> = new EventEmitter()
    //TODO cambiar esta cantidad estatica por un input que traica la cantidad que hay en carrito o 1 si recién se agrega
    protected cantidadInput = new FormControl<number>(this.cantidadInicial)
    private $onDestroy = new Subject()
    constructor() { }

    ngOnInit(): void { 
        this.cantidadInput.valueChanges.pipe(
            takeUntil(this.$onDestroy),
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(cantidad=>{
            if(cantidad !== null && this.cantidadInput.valid)
                this.onCantidadChange.emit(cantidad)
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['cantidadInicial']){
            this.cantidadInput.setValue(this.cantidadInicial,{emitEvent:false})
        }
        if(changes['product']){
            this.cantidadInput.setValidators([Validators.max(this.product.stock),Validators.min(this.product.compraMinima)])
        }
    }

    ngOnDestroy(): void {
        this.$onDestroy.next()
    }

    sumarCarrito(){
        this.cantidadInput.setValue(this.cantidadInput.value+1)
    }

    descontarCarrito(){
        let cantidadInicial = this.cantidadInput.value
        if(cantidadInicial>0){
            this.cantidadInput.setValue(cantidadInicial-1)
        }
    }
}
