import { Component, Input } from "@angular/core";
import {  MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { EnviosService } from "@app/_services";

@Component({
    selector:'opciones-envio',
    templateUrl:'./opcionesEnvio.component.html',
    styleUrls:['./opcionesEnvio.component.scss'],
    providers:[EnviosService]
})

export class OpcionesEnvioComponent{
    @Input() domicilio
    @Input() vendedor
    public loading: boolean =true
    public infoEnvio

    constructor(private _enviosService: EnviosService,
                public dialogRef: MatDialogRef<OpcionesEnvioComponent>,
                private router : Router){

    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.infoPrecios()
    }

    async infoPrecios(){
        this.loading = true
        let res 
        res = await this._enviosService.postInfoPrecios(this.domicilio,this.vendedor.ID).toPromise()
        console.log(res)
        if(res.code == 201){
            this.infoEnvio = res.data
        }
        if(res.code == 404){//no hay mas opciones de envio para tu ubicacion
            
        }
        this.loading = false
        
    }

    navigate(url){
        this.router.navigate([url])
        this.dialogRef.close()
    }

    cerrarModal(){
        console.log('cerrar modal')
        this.dialogRef.close(false)
    }
}