import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
//import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastaModule } from 'ngx-toasta';
import { AdminPanelRoutes } from './admin-panel-routing';
import { AdminMenuItems } from './Core/Menu/MenuItems/MenuItems';
import { MenuToggleModule } from './Core/Menu/MenuToggle.module';
import { MainAdminPanelComponent } from './Main/Main.component';
import { GlobalModule } from '@app/_helpers';
import { MatListModule} from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PreguntasAdminModule } from './Preguntas/Preguntas.module';
import { AdminHeaderComponent } from './Shared/Header/Header.component';
import { MenuListItemsComponent } from './Shared/MenuListItems/MenuListItems.component';
import { SideBarComponent } from './Shared/SideBar/SideBar.component';
import { WidgetModule } from './Widget/Widget.module';
import { MatDialogModule} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';




/*const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};*/


@NgModule({
	declarations: [	
		MainAdminPanelComponent,
		SideBarComponent,
		MenuListItemsComponent,
		AdminHeaderComponent
	],
	imports: [
		CommonModule,
		MenuToggleModule,
		WidgetModule,
      	//FlexLayoutModule,
		//PerfectScrollbarModule,
		RouterModule.forRoot(AdminPanelRoutes,{anchorScrolling:'enabled',scrollPositionRestoration:'enabled'}),
		HttpClientModule,
		GlobalModule,
		ToastaModule.forRoot(),
		NgxSpinnerModule,
		PreguntasAdminModule,
		MatDialogModule,
		MatListModule,
		MatSidenavModule,
		MatExpansionModule
	],
	providers : [
		AdminMenuItems	
	],
	exports : [
		RouterModule,
		ToastaModule,
		MatListModule
	],
	schemas:[]
	

})

export class AdminPanelModule { }
