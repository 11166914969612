<!--*ngIf="(mobWidth >= mobScreenSize) else elseButtonBlock" quito eso de ng-container para que el diseño responsive sea como el de pc y no tengamos problema 404 GIANNI-->
<ng-container >
   <!--BOTON QUE TIENE EL CARRITO -->
   <button class="btn-outline-none" mat-mini-fab [matMenuTriggerFor]="viewdropmenu" [matBadgeHidden]="hiddenBadge" matBadge="   
   {{count}}" matBadgePosition="after" matBadgeColor="accent">
      <i class="material-icons notranslate">
         shopping_cart
      </i>
   </button>
   <!--FIN | BOTON QUE TIENE EL CARRITO -->
   <!--MENU QUE SE LEVANTA AL APRETAR EL BOTON DEL CARRITO -->
   <mat-menu #viewdropmenu="matMenu">
         <!--SI HAY PRODUCTOS ENTONCES SE MUESTRA EL LISTADO DE PRODUCTOS  -->
      <ng-container *ngIf="(cartProducts && cartProducts.length > 0); else elseBlock">
         <!-- PRIMER NIVELES DE CARRITO , ACA SE ARMAN LOS MULTI CARROS -->
         <div *ngFor="let product of cartProducts;">
            <div class="drop-wrap" style="margin: 5%;">
               <h5><strong> {{ product.vendedor?.empresa }} </strong></h5>
               <!-- ITERACION DE PUBLICACIONES DE UN CARRITO -->
               <div fxFlex="row wrap" class="cart-menu-list p-2" fxLayoutAlign="start center" *ngFor="let publicacion of product.publicaciones; let i = index;">
                  <!-- IMAGEN DE PUBLICACION-->
                  <div fxFlex.xs="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.lg="25" fxFlex.xl="25"> 
                     <a href="javascript:void(0);"><img appLazyLoad loading="lazy" [src]="appSettings.urls.hostFiles+publicacion?.imgPortada+appSettings.imgXS" width="60" alt="cart-image"></a> 
                  </div>
                  <!--FIN | IMAGEN DE PUBLICACION-->
                  <!-- NOMBRE DE PUBLICACION-->
                  <div fxFlex.xs="75" fxFlex.sm="75" fxFlex.md="75" fxFlex.lg="75" fxFlex.xl="75" class="px-2">
                     <h6 class="mb-0"><a [routerLink] = "['/products/id',publicacion.ID]">{{publicacion?.titulo}}</a></h6>
                     <div class="precio-container">
                        <span>{{calculatePrice(publicacion) | currency:currency }}</span>
                        <button mat-mini-fab color="accent" (click)="confirmationPopup(publicacion)">
                           <i class="material-icons"> remove_shopping_cart </i> 
                        </button>
                     </div>
                  </div>
                  <!-- FIN | NOMBRE DE PUBLICACION-->
                  <!-- FUNCIONES DE PUBLICACION-->
                  
                  
                  
                  <!-- FIN | FUNCIONES DE PUBLICACION-->
               </div>
               <!-- FIN | ITERACION DE PUBLICACIONES DE UN CARRITO -->
               <div class="d-flex justify-content-between">
                  <span>Subtotal</span>
                  <span>{{product.subtotal | currency}}</span>
               </div>
               <div class="d-flex justify-content-between">
                  <span>Comision ({{product.getComision()}}%)</span>
                  <span>{{product.getMontoComision() | currency}}</span>
               </div>
               <div class="d-flex justify-content-between">
                  <span>Total</span>
                  <span>{{product.total | currency}}</span>
               </div>
            </div>
            <!--BOTON DE COMPRA DEL CARRITO EN PARTICULAR  -->
            <div class="footer-menu px-3 pb-2 pt-2">
               <button mat-raised-button color="accent" class="w-100 boton-comprar" [routerLink]="['/cart/id/',product.vendedorID]">
                  Comprar a {{ product.vendedor?.empresa }}
               </button>
            </div>
             <!--FIN | BOTON DE COMPRA DEL CARRITO EN PARTICULAR  -->
         </div>
         <!-- FIN | PRIMER NIVELES DE CARRITO , ACA SE ARMAN LOS MULTI CARROS -->

      </ng-container>
         <!--FIN | SI HAY PRODUCTOS ENTONCES SE MUESTRA EL LISTADO DE PRODUCTOS  -->
         <!--SI NO HAY PRODUCTO SE MUESTRA EL CARTEL DE QUE NO SE ENCUENTRAN PRODUCTOS -->
      <ng-template #elseBlock>
         <div class="cart-menu-list p-2" fxLayoutAlign="center center">
            No hay productos en el carrito
         </div>
      </ng-template>
      <!--FIN | SI NO HAY PRODUCTO SE MUESTRA EL CARTEL DE QUE NO SE ENCUENTRAN PRODUCTOS -->
   </mat-menu>
   <!--FIN | MENU QUE SE LEVANTA AL APRETAR EL BOTON DEL CARRITO -->
</ng-container>
<ng-template #elseButtonBlock>
   <button mat-mini-fab [matBadgeHidden]="hiddenBadge" matBadge="{{count}}" matBadgePosition="after" matBadgeColor="accent" [routerLink]="['/cart']">
      <i class="material-icons notranslate">
        shopping_cart
      </i>
  </button>
</ng-template>