<mat-toolbar class="embryo-header-toolbar admin-panel-header" fxLayoutAlign="space-between center">
   <div class="embryo-ham-icon" tourAnchor="start.tour" >
      <button class="" mat-mini-fab color="primary" mat-card-icon (click)="toggleSidebar()">
         <mat-icon>menu</mat-icon>
      </button>
   </div>
   <div class="col-11">
		<span fxFlex></span>
		<div class="list-flex user-tool">
         <div class="list-flex">
            <span class="mr-3 d-none d-md-block">Hola {{nombre}}!</span>
         </div>
         <embryo-notifications [count]="$cantNotif | async"></embryo-notifications>
   		<embryo-header-user-profile></embryo-header-user-profile>
   	</div>
   </div>
   
</mat-toolbar>
<!-- embryo-header-toolbar -->
