import { CategoriaDestacadaApiInterface } from "@app/_interfaces/categoria-api.interface";

export class Categoria{

    constructor(
		public ID:number,
		public nombre:string,
		public icono:string,
		public children: Array<Categoria>,
		public parentName: string
	){
		this.children = new Array<Categoria>();
	}

	static CrearListadoDestacadas(categorias:Array<CategoriaDestacadaApiInterface>){
		let list: Array<Categoria> = []
		categorias.forEach(categoria => {
			list.push(new Categoria(categoria.ID, categoria.nombre, categoria.customIcon, [],categoria.parent.nombre))
		})
		return list
	}
}