import {Injectable} from '@angular/core';
import { io, Socket } from 'socket.io-client';
import {Observable, Subject } from 'rxjs';
import { environment } from '@environments/environment';


@Injectable({providedIn:'root'})



export class ChatSocketService {
    private socket:Socket
    public chatNotificacion = new Subject()
    
    

    constructor(){
    }

    

    disconnectChatRoom(){
        console.log(this.socket)
        this.socket.disconnect()
    }


    connectChatRoom(carritoID:number,talkerID:number,receiverID:number): Subject<MessageEvent>{
        console.log('conectando socket chat')
        this.socket = io(environment.socketChat,{
            query:{
                "carritoID":Number(carritoID),
                "talkerID":Number(talkerID),
                "receiverID":Number(receiverID)
            },
        });

        console.log(this.socket)


        let observable = new Observable(observer =>{
            this.socket.on('mensaje',data=>{
                console.log(data)
                observer.next(data);
            })
            
            this.socket.on('disconnect',res=>{
                console.log('desconectado',res)
            })
            return () =>{
                this.socket.disconnect();
            }
        })

        let observer ={
            next :(data:Object)=>{
                console.log("emit notificar connect",data)
                this.socket.emit('notificar')
            },

            desconectar:()=>{
                this.socket.disconnect()
            }
        };
        
        return Subject.create(observer,observable)
    }
}