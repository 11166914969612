import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.css']
})
export class ModalLoginComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalLoginComponent>,
  ) { }

  ngOnInit() {
    
  }

  
  onNoClick(estadoInicioSesion): void {
    console.log("estadoInicio", estadoInicioSesion)
    if(estadoInicioSesion){
      this.dialogRef.close(estadoInicioSesion);
    }
  }


}
