import { Component, OnInit, Input, Output, EventEmitter, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '@app/_services/chat.service';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { NotificacionesService } from '@app/_services/notificaciones.service';
import { AppSettings } from '@settings';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

interface Notificacion {
   ID?: Number,
   titulo:String,
   descripcion:String,
   interna:boolean,
   url:String,
   queryParams?:String
}

@Component({
  selector: 'embryo-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {

  @Input() notifications : Array<Notificacion> = [];
  @Input() count        : any;
  @Input() currency     : string;

  public cartProducts = []
  public loading = false
  public loadingMore = false
  public page = 0
  public limit = 15
  public totalNotificaciones
  public mensajesSinLeer
  private localStorageService = inject(LocalStorageService)
  public isComprador:boolean = this.localStorageService.getCompradorInfo().ID !== null
  private $onDestroy = new Subject()
  private urlMensajes:String = (this.isComprador)? '/account/mis-compras' : '/admin-panel/mensajes/listado'


  mobWidth : any;
  mobScreenSize : number = 767;

  @Output() removeProductData : EventEmitter<any> = new EventEmitter();  

  hiddenBadge = true;
  sinLeer : Observable<Number>

  constructor(
      public appSettings : AppSettings,
      private _serviceNotificaciones: NotificacionesService,
      private _chatService: ChatService,
      private router : Router
   ) {
     this.mobWidth = window.screen.width;
  }
  
  ngOnInit() {
      let persona = this.localStorageService.getUserInfo().ID
      this._serviceNotificaciones.getCantidadNoLeidas(persona)
      this._serviceNotificaciones.socketNotificaciones.subscribe(res=>{
         console.log(res)
         this._serviceNotificaciones.addNotification()
      },takeUntil(this.$onDestroy))
      /*this.sinLeer = this._chatService.$cantMensajes
      this._chatService.$cantMensajes.subscribe(res=>{
         if(res>0){
            let notificacionMensajes: Notificacion
            if(this.isComprador){
               notificacionMensajes = {
                  titulo:`Tienes ${(res>1)? res+' nuevos mensajes' : '1 nuevo mensaje'}`,
                  descripcion: 'Mira los mensajes que te han enviado',
                  url: '/account/order-history',
                  interna: true
               }  
            }else{
               notificacionMensajes = {
                  titulo:`Tienes ${(res>1)? res+' nuevos mensajes' : '1 nuevo mensaje'}`,
                  descripcion: 'Mira los mensajes que te han enviado',
                  url: '/admin-panel/mensajes/listado',
                  interna: true
               } 
            }
            this.notifications.push(notificacionMensajes)
         }
      },take(1))
      this._chatService.$cantMensajes.subscribe(res=>{
         if(res==0){
            this.notifications.splice(0,1)
         }
      })*/
   }

   verNotificaciones(){
      let persona = this.localStorageService.getUserInfo()?.ID
      this.cargarNotificaciones(persona)
   }

   async cargarNotificaciones(personaId){
      this.loading = true
      let res
      res = await this._serviceNotificaciones.getNotificacionesNoLeidas(personaId,0,this.limit).toPromise()
      console.log(2,res)
      if(res.code == 200){
         res.data.rows.map(row=>{
            let notificacion: Notificacion
            notificacion = {
               ...row,
               url:(row.tipo == 'CHAT-CARRITO')? this.urlMensajes : row.url,
               interna: row.tipo == 'CHAT-CARRITO',
               queryParams: (row.tipo == 'CHAT-CARRITO')? '{"e":"mensajes"}' : ''
            }
            if(!this.notifications.find(not=>not.ID == notificacion.ID)){
               this.notifications.push(notificacion)
            }  
            
         })
         //this.notifications.push(res.data.rows)
         this.totalNotificaciones = res.data.count
      }
      this.loading = false
   }

   async cargarMasNotificaciones(personaId){
      this.loadingMore = true
      this.page++
      let res
      res = await this._serviceNotificaciones.getNotificacionesNoLeidas(personaId,this.page,this.limit).toPromise()
      console.log(res)
      if(res.code == 200){
         this.notifications.push(res.data.rows)
      }
      this.loadingMore = false
   }

   ngOnDestroy(): void {
      //Called once, before the instance is destroyed.
      //Add 'implements OnDestroy' to the class.
      this._serviceNotificaciones.restartNotifications()
      this.$onDestroy.next()
      console.log('destroy notifications')
   }

  ngOnChanges() {
     if(this.count && this.count != 0) {
        this.hiddenBadge = false;
     } else {
        this.hiddenBadge = true;
     }
  }

  /*public confirmationPopup(product:any) {
     this.removeProductData.emit(product);
  }*/

  public async marcarVista(notificacion){
      let personaId = this.localStorageService.getUserInfo().ID
      console.log(personaId)
      let res 
      res = await this._serviceNotificaciones.patchVisto(notificacion.ID,personaId).toPromise()
      if(res.code == 201){
         this.notifications = this.notifications.filter(item=>item['ID'] != notificacion.ID)
         if(this.notifications.length == 3 && this.totalNotificaciones >15){
            this.cargarMasNotificaciones(personaId)
         }
      }
  }

  public async marcarTodasVistas(){
   let personaId = this.localStorageService.getUserInfo().ID
   let res 
   res = await this._serviceNotificaciones.patchVistoTodas(personaId).toPromise()
   console.log(res)
   if(res.code == 201){
      this.notifications = []
      this._serviceNotificaciones.marcarTodasVisto()
   }
   
  }

  public async navegar(notificacion){
   await this.marcarVista(notificacion)
   if(notificacion.url.includes('.com')){
      console.log('tiene el .com')
      if(notificacion.url.includes('hiperabasto.com')){
         console.log('url de hiper')
         let urlPura = notificacion.url.split("hiperabasto.com")[1]
         console.log(urlPura)
         this.router.navigate([urlPura])
       }else{
         if(!notificacion.url.includes('http://') && !notificacion.url.includes('https://')){
            notificacion.url = 'http://'+notificacion.url
         }
         console.log('url de afuera',notificacion.url)
         window.open(notificacion.url,'_blank')
       }
   }else{
      console.log('NO tiene el .com')
      this.router.navigate([notificacion.url],{queryParams:(notificacion.queryParams)? JSON.parse(notificacion.queryParams):''})
   }
  }

  public onScroll(){
   if((this.page + 1) * this.limit < this.totalNotificaciones){
      let personaId = this.localStorageService.getUserInfo().ID
      this.cargarMasNotificaciones(personaId)
   }
  }

}
