
        
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FavoritosService } from '@app/_services/favoritos.service';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { ModalLoginService } from '@services'

        
@Injectable()
export class RoleGuardServiceGlobal implements CanActivate {
    //TODO ver que hace este guardian,en que ruta se encuentra.
    public flag;
    private localStorageService = inject(LocalStorageService)
  constructor(public router: Router,
              private login: ModalLoginService,
              private _favoritosService : FavoritosService)
  {
      this.flag = false
  }

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {
    let userID = this.localStorageService.getCompradorInfo().ID

    if(userID){
        if(!this.flag && !Boolean(sessionStorage.getItem('flagWishList'))) {
          this.cargarFavoritos(userID)
          sessionStorage.setItem('flagWishList','true')
          return true
        }else{       
            console.log("ya no hace falta hacer el get")
          return true;
        }
    }else{
        return true;
    }

  }

  async cargarFavoritos(idComprador) {
    let result = await this._favoritosService.getFavoritos(idComprador)
    let products = []
    result['data'].forEach(favorito => {
      favorito.publicacion.idFavorito = favorito.ID
      products.push(favorito.publicacion)
    });
    localStorage.setItem("wishlist_item", JSON.stringify(products))
    this._favoritosService.calculateLocalWishlistProdCounts()
  }
}
