import { HttpClient, HttpParams } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable, inject } from '@angular/core';
import { ComprasListResponse } from '@app/_interfaces/compras-list.interface';
import { VentasListadoResponse } from '@app/_interfaces/ventas-list.interface';
import { AppSettings } from '@settings'; //modulo global de direcciones.
import { map, take } from 'rxjs/operators';
import { LocalStorageService } from './local-storage-service';
import { format } from 'date-fns';
import { CarritoDetailApi } from '@app/_interfaces/carrito-detail.interface';
import { Carrito, CarritoPublicacion, Producto, ProductoCompleto } from '@app/_models';
import { PublicacionesCarritoList } from '@app/theme/components/publicaciones-carrito-list/publicaciones-carrito-list.component';
import { Publicacion } from '@app/_models/publicacion';
import { PublicacionAlgoliaInterface } from '@app/_interfaces/publicacion-algolia.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { ComisionesService } from './comisiones.service';
import { VendedorDetalleResponse } from '@app/_interfaces/vendedor-detalle.interface';
import { CarritoPrecompraPostInterface } from '@app/_interfaces/carrito-precompra-post.interface';


@Injectable()
export class CarritoService {
  private host = this.appSettings.urls['host']
  private recurso = this.appSettings.urls['carritos']
  private localStorageService = inject(LocalStorageService)
  private comisionesService = inject(ComisionesService)
  private $carritosSubject: BehaviorSubject<Carrito[]> = new BehaviorSubject([])
  private $totalPublicaciones:BehaviorSubject<number> = new BehaviorSubject(0)
  public carritosObservable: Observable<Carrito[]> = this.$carritosSubject.asObservable()
  public totalPublicacionesObservable: Observable<number> = this.$totalPublicaciones.asObservable()
  constructor(
    private http: HttpClient,
    private appSettings:AppSettings) {
      //this.notificarCambioCarrito()
      this.inicializarCarritos()
    }

    getCarritoCompradorID(id,page,limit,from?,to?,tipo?,estado?){
      let params = new HttpParams()
      console.log(from)
      console.log(to)
      const queryParams: { [key: string]: string | number } = {
        estado,
        from: format(new Date(from),'yyyy-MM-dd'),
        to: format(new Date(to),'yyyy-MM-dd'),
        tipo,
      };
    
      Object.keys(queryParams).forEach(key => {
        console.log(queryParams[key])
        if (queryParams[key] && queryParams[key] !== undefined) {
          params = params.set(key, queryParams[key] as string);
        }
      });
      console.log(params)
        return this.http.get<ComprasListResponse>(`${this.host+this.recurso}/compradorID/${id}/p/${page}/l/${limit}`,{params})
    }

    getCarritoCompradorIDFromTo(page:number,limit:number,estado:string, desde:Date, hasta:Date,filtros?:Array<{key:string,value:string}>){
        let id = this.localStorageService.getCompradorInfo().ID
        let from = format(desde,'yyyy-MM-dd')
        let to = format(hasta,'yyyy-MM-dd')
        let queryParams = filtros?.reduce((accumulator, item, index) => {
          const separator = index === 0 ? '?' : '&'; // Use '?' for the first item, '&' for the rest
          return `${accumulator}${separator}${item.key}=${encodeURIComponent(item.value)}`;
        }, ''); 
        console.log(`${this.host+this.recurso}/compradorID/${id}/p/${page}/l/${limit}/f/${from}/t/${to}/e/${estado}${queryParams}`)
        return this.http.get<ComprasListResponse>(`${this.host+this.recurso}/compradorID/${id}/p/${page}/l/${limit}/f/${from}/t/${to}/e/${estado}${queryParams}`)
    }

    getCarritosMensajes(compradorId,page,limit,estado?){
      return this.http.get(`${this.host+this.recurso}/con-chat/compradorID/${compradorId}/p/${page}/l/${limit}/e/${estado? estado :''}`)
    }

    getCarritoID(id){
      return this.http.get<CarritoDetailApi>(`${this.host+this.recurso}/id/${id}`)
    }

    generarCompra(carrito){
      return this.http.post(`${this.host+this.recurso}`, carrito)
    }

    generarCheckoutMobbex(carrito){
      console.log(carrito)
      return this.http.post(`${this.host+this.recurso}/v2`, carrito)
    }

    generarCheckoutPagos360(carrito:CarritoPrecompraPostInterface){
      return this.http.post(`${this.host}/carritos/precompra`,carrito)
    }

    public postReclamo(reclamo,createdBy,carritoID){
      let json = {
        'carritoID':carritoID,
        'createdBy':createdBy,
        'descripcion':reclamo
      }
      return this.http.post(`${this.host+this.recurso}/reclamos`,json)
   }

   public patchLiberarDinero(idCarrito){
    let password = this.localStorageService.getUserInfo().pw
    let json = {
      'estado':"RECIBIDO",
      'password':password
    }
    return this.http.patch(`${this.host+this.recurso}/recibido/id/${idCarrito}`,json)
   }

   public patchOpinion(idCarrito:number,puntaje:number,opinion:string){
    let json = {
      puntaje,
      opinion
    }
    return this.http.patch<{code:number}>(`${this.host+this.recurso}/opinion/id/${idCarrito}`,json)
   }

   public checkCartAlready(singleProduct){
    let carritos:Carrito[] = this.localStorageService.getCarritos();
    let carritoConProducto = carritos.find(carrito=>carrito.publicaciones?.some(p => p.publicacionID === singleProduct.ID));
    if(carritoConProducto){
      return carritoConProducto.publicaciones.find(p=>p.publicacionID === singleProduct.ID).cantidad
    }else{
      return 0
    }
   }

   // Adding new Product to cart in localStorage
   public addToCart(data: Publicacion,cantidad:number){
    let carrito: Carrito
    let userInfo = this.localStorageService.getUserInfo()
    if(!userInfo.ID){
       return data;
    }else{
       carrito = this.localStorageService.getCarritoVendedor(data.vendedor.ID)
       if(carrito){
        let publicacion = data
        carrito.addPublicacion(publicacion,cantidad)
        this.setComision(carrito)
        this.localStorageService.setCarrito(carrito)
        this.notificarCambioCarrito()
       }else{
        let compradorID = this.localStorageService.getCompradorInfo().ID
        this.getVendedor(data.vendedor.ID).pipe(take(1)).subscribe({
          next: (res)=>{
            carrito = new Carrito(data.vendedor.ID,compradorID,null,null,0,res.data,[],null,null,null)
            carrito.addPublicacion(data,cantidad)
            //TODO ver como mejorar este codigo repetido
            this.setComision(carrito)
            this.localStorageService.setCarrito(carrito)
            this.notificarCambioCarrito()
            //-----------------------------------------------//
          },
          error: err=>{
            alert('ocurrio un error buscando el vendedor de la publicacion que intentas agregar al carrito')
            console.error(err)
          }
        })
        
      }
       
    }
  }

  private setComision(carrito:Carrito){
    this.comisionesService.getComisionVentaTotal().pipe(take(1)).subscribe(res=>{
      let comision:number = 1+(res.comisionCompra/100)
      let comisionPagos360: number = 1+(res.comisionPagos360/100)
      let comisionHiper:number = 1+(res.comisionHiper/100) 
      carrito.setComision(comision)
      carrito.setComisionPagos360(comisionPagos360)
      carrito.setComisionHiper(comisionHiper)
      this.localStorageService.setCarrito(carrito)
    })
  }

  public cambiarCantidad(publicacionID:number,cantidad:number,vendedorID:number){
    let carrito = this.localStorageService.getCarritoVendedor(vendedorID)
    if(carrito){
      carrito.cambiarCantidad(publicacionID,cantidad)
    }
    this.localStorageService.setCarrito(carrito)
    this.notificarCambioCarrito()
  }

  public cambiarPrecio(publicacionID:number,precio:number,vendedorID:number){
    let carrito = this.localStorageService.getCarritoVendedor(vendedorID)
    if(carrito){
      carrito.cambiarPrecio(publicacionID,precio)
    }
    this.localStorageService.setCarrito(carrito)
    this.notificarCambioCarrito()
  }

  public deletePublicacion(publicacionID:number){
    let carritoModificar = this.localStorageService.getCarritos().find(c=>c.publicaciones.some(p=>p.publicacionID === publicacionID))
    if(carritoModificar){
      carritoModificar.cambiarCantidad(publicacionID,0)
      this.localStorageService.setCarrito(carritoModificar)
      this.notificarCambioCarrito()
    }
  }

  public deleteCarritoVendedor(vendedorID){
    this.localStorageService.deleteCarrito(vendedorID)
    this.notificarCambioCarrito()
  }

  private notificarCambioCarrito(){
    let carritos = this.localStorageService.getCarritos()
    carritos.forEach(c=>this.setComision(c))
    let publicacionesCount = carritos.reduce((total,carrito)=>total+carrito.publicaciones.length,0)
    this.$carritosSubject.next(carritos)
    this.$totalPublicaciones.next(publicacionesCount)
  }

  private inicializarCarritos(){
    let carritos = this.localStorageService.getCarritos()
    carritos.forEach(c=>this.setComision(c))
    let publicacionesCount = carritos.reduce((total,carrito)=>total+carrito.publicaciones.length,0)
    this.$carritosSubject.next(carritos)
    this.$totalPublicaciones.next(publicacionesCount)
  }

  public getCarritoLocalVendedorId(vendedorID:number){
    return this.localStorageService.getCarritoVendedor(vendedorID)
  }

 public publicacionAlgoliaToApp(publicacion:PublicacionAlgoliaInterface){
    return new Publicacion(
      publicacion.ID,
      publicacion.producto.ID,
      publicacion.imgPortada,
      publicacion.titulo,
      publicacion.stock,
      publicacion.precio,
      publicacion.marca,
      publicacion.vendedor,
      publicacion.precios,
      { 
        alto:publicacion.alto,
        largo:publicacion.largo,
        profundidad:publicacion.profundidad,
        peso:publicacion.peso,
        snFragil:publicacion.snFragil
      }
    )
 }

 private getVendedor(vendedorID:number){
  return this.http.get<VendedorDetalleResponse>(`${this.host}/personas/vendedores/id/${vendedorID}`)
 }



}