import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnChanges, OnInit, SimpleChange, SimpleChanges, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CarritoService, EmbryoService, ModalLoginService, SessionService } from '@services';
import { AppSettings } from '@settings';
import { DescuentosComponent } from './descuentos/descuentos.component';
import { ChangeAddressComponent } from '@components/ChangeAddress/change-address.component'
//import {  SessionService } from '@services';
import { EnviosService } from '@services'
import { OpcionesEnvioComponent } from '@app/theme/components/OpcionesEnvio/opcionesEnvio.component';
import { InfoPrecioEnvioComponent } from '@app/theme/components/InfoPrecioEnvio/infoPrecioEnvio.component';
import { FavoritosService } from '@app/_services/favoritos.service';
import { take, takeUntil } from 'rxjs/operators';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { Subject } from 'rxjs';
import { Publicacion } from '@app/_models/publicacion';
import { PublicacionDetalleInterface } from '@app/_interfaces/publicacion-detalle.interface';
import { PublicacionDetalle } from '@app/_models/publicacionDetalle';

@Component({
  selector: 'embryo-ShopDetails',
  templateUrl: './ShopDetails.component.html',
  styleUrls: ['./ShopDetails.component.scss'],
  providers: [ModalLoginService, EnviosService],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ShopDetailsComponent implements OnInit, OnChanges {

  @Input() detailData:PublicacionDetalleInterface | any;
  @Input() currency: string;
  protected publicacionDetalle : PublicacionDetalle

  mainImgPath: string;
  totalPrice: any;
  type: any;
  isVendedor: boolean;
  public _showButtonAddress: boolean;
  public _showAddress: boolean;
  public _showMessageAddress: boolean;
  public _showMessageLogin: boolean;
  public domicilio;
  public direccionAEnviar;
  public shippingPrice = null
  public buttonChangeAddressText;
  public _cargando: boolean = false;
  public envioVendedor: boolean = false
  private $onDestroy = new Subject()
  private localStorageService = inject(LocalStorageService)
  private sessionService = inject(SessionService)
  private carritos_service = inject(CarritoService)
  constructor(private route: ActivatedRoute,
    private router: Router,
    public embryoService: EmbryoService,
    public appSettings: AppSettings,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public service_modal_login: ModalLoginService,
    private service_envios: EnviosService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private _favoritosService : FavoritosService
  ) {

  }

  public cantidadCompra
  public compraMinima
  public noCantidad = false
  public sinStock = false
  public sinStockInicial = false
  public precioUnitario
  public precioConDescuento
  public descuentoAplicado
  public descuento
  public compraBaja = false
  public fav = false
  public productoStandBy;
  public snEnvioPropio
  protected enCarrito: boolean = false


  ngOnInit() {
    //this.detailData.descripcion = this.detailData.descripcion?.replace(/\n/g, "<br />");
    this.sessionService.userInfo.pipe(takeUntil(this.$onDestroy)).subscribe(res=>{
      this.isVendedor = res.tipo === 'VENDEDOR'
      this._showMessageLogin = !res.snSesion
      if(this.localStorageService.getDomicilioInfo().ID){
        this.armarObjetoDomicilio()
      }else{
        this.buttonChangeAddressText = "Seleccionar Dirección"
        this._showMessageAddress = true
      }
    })
    //controlo si la publicacion ya esta en el carrito
    
    //--------------------------------------------------
    this.ngZone.runOutsideAngular(() => {
      if (this.detailData.compraMinima != null) {
        this.cantidadCompra = this.detailData.compraMinima
        this.compraMinima = this.detailData.compraMinima
      } else {
        this.compraMinima = 1
        this.cantidadCompra = 1
      }
      if (this.detailData.stock < this.compraMinima) {
        this.sinStockInicial = true
      }
      if (this.detailData.favorita != null) {
        this.fav = true
      }
    })

    
    this.calcularPrecio()
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes['detailData']){
      //controlo si la publicacion ya esta en el carrito
      this.carritos_service.carritosObservable.pipe(takeUntil(this.$onDestroy)).subscribe(carritos=>{
        this.enCarrito = carritos.some(c=>c.publicaciones.some(p=>p.publicacionID == this.detailData.ID))
      })
      //--------------------------------------------------
      this.publicacionDetalle = new PublicacionDetalle(this.detailData.ID,0,this.detailData.imgPortada,this.detailData.titulo,this.detailData.stock,this.detailData.precio,this.detailData.marca,this.detailData.vendedor,this.detailData.precios,this.detailData.descripcion,this.detailData.unidadesMedida,{alto:this.detailData.alto,largo:this.detailData.largo,profundidad:this.detailData.profundidad,peso:this.detailData.peso,snFragil:false})
    }
    if (this.detailData.favorita != null) {
      this.fav == true
    }
    if (this.detailData.compraMinima != null) {
      this.cantidadCompra = this.detailData.compraMinima
      this.compraMinima = this.detailData.compraMinima
    } else {
      this.compraMinima = 1
      this.cantidadCompra = 1
    }

    this.mainImgPath = null;
    // this.totalPrice  = null;
    this.mainImgPath = this.detailData.imgPortada;
    // this.totalPrice  = this.detailData.precio;
    this.guardarUltimaVisita()
    this.calcularPrecio()
  }

  ngOnDestroy(): void {
    this.$onDestroy.next()
  }

  public async guardarUltimaVisita() {
    this.ngZone.runOutsideAngular(() => {
      if (this.localStorageService.getUserInfo().ID) {
        let res = this.embryoService.patchUltimaVisita(this.detailData.ID, this.localStorageService.getUserInfo().ID).toPromise()
      }
    })
  }

  public verDescuentos() {
    const dialogRef = this.dialog.open(DescuentosComponent);
    dialogRef.componentInstance.precios = [].concat(this.detailData.precios)
    dialogRef.componentInstance.precios.sort(function (a, b) {
      return a.cantidad - b.cantidad
    })
    dialogRef.componentInstance.unidadMedida = this.detailData.unidadesMedida
  }
  /**
   * getImagePath is used to change the image path on click event. 
   */
  public getImagePath(imgPath: string, index: number) {
    //console.log('img', imgPath)
    document.querySelector('.border-active').classList.remove('border-active');
    this.mainImgPath = imgPath
    document.getElementById(index + '_img').className += " border-active";
  }

  public calcularPrecio() {
    this.compraBaja = false
    this.sinStock = false
    this.compraBaja = false
    if (this.cantidadCompra > this.detailData.stock) {
      this.sinStock = true //quiero comprar una cantidad mayor a la que hay en stock
    }
    if (this.cantidadCompra == 0) {
      this.noCantidad = true //compra con cantidad en 0
    }
    else {
      this.noCantidad = false
    }
    if (this.cantidadCompra < this.compraMinima) {
      this.compraBaja = true //compra con cantidad menor al minimo permitido en la publicacion
    }
    this.descuentoAplicado = false
    this.descuento = null
    //recorro el arreglo de precios para ver si la cantidad seleccionada tiene descuento
    this.detailData.precios.forEach(precio => {
      if (precio.cantidad <= this.cantidadCompra && !this.descuentoAplicado) {
        this.precioConDescuento = (this.detailData.precio * this.cantidadCompra) - ((this.detailData.precio * this.cantidadCompra * precio.descuento) / 100)
        this.precioUnitario = this.detailData.precio * this.cantidadCompra
        this.totalPrice = this.precioConDescuento
        this.descuentoAplicado = true
        this.descuento = precio.descuento
      }
    });
    if (!this.descuentoAplicado) {
      this.precioUnitario = this.detailData.precio * this.cantidadCompra
      this.totalPrice = this.precioUnitario
    }

    if (!this.noCantidad && !this.compraBaja)
      this.calcularCostoPublicacion(this.cantidadCompra)
  }

  cantidadChange(cantidad:number){
    this.carritos_service.cambiarCantidad(this.publicacionDetalle.ID,cantidad,this.publicacionDetalle.vendedor.ID)
  }

  /*public calculatePrice(detailData:any, value: any) {
     detailData.quantity = value;
     this.totalPrice = detailData.price*value;
  }*/

  /*public reviewPopup(detailData) {
    let reviews: any = null;
    for (let review of this.productReviews) {
      reviews = review.user_rating;
    }

    this.embryoService.reviewPopup(detailData, reviews);
  }*/

  async addToWishlist(value: any) {
    //ahora controlar lo de iniciar sesión si no ha iniciado
    this.fav = !this.fav
    if (this.fav) {
      let ifComprador = this.localStorageService.getCompradorInfo().ID
      let auxInnecesario = null
      if (ifComprador == null) {
        try {
          const result = await this.iniciarSesionSinCarrito().then(response => {
            //console.log("response", response)
            return response
          })
          if (result != undefined) {
            this._showButtonAddress = true
            this.armarObjetoDomicilio()
            this.calcularCostoPublicacion(this.cantidadCompra)
          }

          auxInnecesario = result
        } catch (error) {
          console.error("Error", error)
        }



        if (auxInnecesario != null) {
          this._favoritosService.addToWishlist(value);
          //console.log("fav", this.fav)
          this.fav = true
        } else {
          //debería comparar por código de éxito o algo por el estilo
          //console.log("aux")
          this.fav = !this.fav
        }
      } else {
        this._favoritosService.addToWishlist(value);

      }

    } else {
      this._favoritosService.removeLocalWishlistProduct(value)
      //console.log("fav", this.fav)
    }
    this.cdr.detectChanges()
  }

  public async addToCart(value: any) {
    //console.log("-------------", this.appSettings.urls.hostFiles + this.mainImgPath + this.appSettings.imgSM)
    if (!this.sinStock && !this.noCantidad && !this.compraBaja) {
      value.compraMinima = this.cantidadCompra
      value.descuento = this.descuento
      let res = this.carritos_service.addToCart(value,this.cantidadCompra)
      if (res) {
        this.productoStandBy = res;
        //this.iniciarSesion();
        const result = await this.iniciarSesion().then(response => {
          //console.log("response", response)
          return response
        })
        if (result != undefined) {
          this._showButtonAddress = true
          this.armarObjetoDomicilio()
          this.calcularCostoPublicacion(this.cantidadCompra)
        }

      }
    } else {
      this.snackBar.open("Verifique los campos de la compra", "Aceptar", {
        duration: 995000,
        panelClass: ['my-snack-bar']
      });
    }
  }

  public buyNow(value: any) {
    this.embryoService.buyNow(value);
    this.router.navigate(['/checkout']);
  }

  /*Function que levanta el modal e inicia session */
  public iniciarSesion() {
    //this.service_modal_login.iniciarSesionPushCarrito(this.productoStandBy);
    return new Promise(resolve => {
      resolve(this.service_modal_login.iniciarSesionPushCarrito(this.productoStandBy))
    });
  }

  public iniciarSesionSinCarrito() {
    return new Promise(resolve => {
      resolve(this.service_modal_login.iniciarSesion2())
    });

  }

  /* FIN | Function que levanta el modal y da de alta una direccio de la persona */

  openDialog() {
    const dialogRef = this.dialog.open(ChangeAddressComponent, {
      width: '600px',
      autoFocus: false,
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(res => {

      this.armarObjetoDomicilio()
      this.calcularCostoPublicacion(this.cantidadCompra)

    })

  }

  async calcularCostoPublicacion(cantidad) {
    this._cargando = true

    let publicacion = {
      ID: this.detailData.ID,
      precio: this.detailData.precio,
      cantidad: cantidad,
      alto: this.detailData.alto,
      largo: this.detailData.largo * cantidad,
      profundidad: this.detailData.profundidad,
      titulo: this.detailData.titulo,
      peso: this.detailData.peso * cantidad,
    }
    //console.log("publi",publicacion)
    let vendedor_id = this.detailData.vendedor.ID
    if (this.domicilio?.ID != undefined) {
      this.ngZone.runOutsideAngular(async () => {
        this.service_envios.postPrecioEnvio(this.domicilio, publicacion, vendedor_id).subscribe({
          next: result=>{
            console.log(result)
            if (result['code'] == 201) {
              this.shippingPrice = result['data']['precio']
              this.envioVendedor = result['data']['logisticaID'] == 2
              this.snEnvioPropio = result['data']['snEnvioPropio']
            }
          },
          error: err=>{
            console.log(err)
          }
        })
        this._cargando = false
      })

    }
  }



  armarObjetoDomicilio() {
    this._showAddress = true
    this._showMessageLogin = false
    this._showMessageAddress = false
    this.buttonChangeAddressText = "Cambiar Dirección"
    this.domicilio = {
      latitud: this.localStorageService.getDomicilioInfo().latitud,
      longitud: this.localStorageService.getDomicilioInfo().longitud,
      cp: this.localStorageService.getDomicilioInfo().codigoPostal,
      ID: this.localStorageService.getDomicilioInfo().ID,
      provinciaID: this.localStorageService.getDomicilioInfo().provincia.ID
    }
    this.direccionAEnviar = this.localStorageService.getDomicilioInfo().direccion + " - " + this.localStorageService.getDomicilioInfo().numero + " - C. P.: " + this.localStorageService.getDomicilioInfo().codigoPostal
  }

  ngAfterViewChecked(): void {
    //this.cdr.detectChanges();

  }

  verOpcionesEnvio() {
    const dialogRef = this.dialog.open(OpcionesEnvioComponent/*,
      {
        minHeight: '400px',
        minWidth: '500px'
      }*/)
    dialogRef.componentInstance.domicilio = this.domicilio
    dialogRef.componentInstance.vendedor = this.detailData.vendedor
  }

  infoPrecioEnvio(snGratis) {
    console.log(snGratis)
    const dialogRef = this.dialog.open(InfoPrecioEnvioComponent)
    dialogRef.componentInstance.snGratis = snGratis
  }


}
