export class Vendedor{
    constructor(){}

    personaID:string;
    direccion:string;
    telefono:string;
    mail:string;
    DNI:string;
    puntos:string;
    cuit:string;
    cbu:string;
    numeroCuenta:string;
    condicion:string;
    empresa:string;
    estado:string;
    provinciaID: number
    localidadID: number
    montoMinimo
    logo:string
    banner:string 
    bannerHome:string
    tipo:'VENDEDOR' | 'CATALOGO'

}