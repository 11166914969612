import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuItems } from '@models';

@Component({
  selector: 'FooterOne',
  templateUrl: './FooterOne.component.html',
  styleUrls: ['./FooterOne.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterOneComponent implements OnInit {

   constructor( public menuItems : MenuItems,
                private iconRegistry: MatIconRegistry,
                private sanitizer: DomSanitizer
               ) 
  { 

      iconRegistry.addSvgIcon(
      'youtube',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/redes/Youtube.svg'));

      iconRegistry.addSvgIcon(
      'instagram',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/redes/Instagram.svg'));

      iconRegistry.addSvgIcon(
      'facebook',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/redes/Facebook.svg'));
      
      iconRegistry.addSvgIcon(
      'linkedin',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/redes/Linkedin.svg'));
  }

   ngOnInit() {
   }

}
