import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _snackBar: MatSnackBar){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err)
            let error = err
            /*if(!error){
                error = "Error de Servidor.";
                this._snackBar.open('Error de servidor contacte a soporte','Aceptar')
            }*/
            
            if ([401, 403].indexOf(err.status) !== -1) {
                console.log("401,403",error);
            }
            if ([404].indexOf(err.status) !== -1) {
                /*this._snackBar.open('No hay datos.','Aceptar')*/

            }

            if([400].indexOf(err.status) !== -1){
                alert(error)
                console.log("400",error);
            }

            if([500].indexOf(err.status) !== -1){
                console.log("500",error);
                
                this._snackBar.open('Error de servidor contacte a soporte','Aceptar')
            }
            
            return throwError(error);
        }))
        
    }
}