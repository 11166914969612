
        
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { ModalLoginService } from '@services'

        
@Injectable()
export class RoleGuardService implements CanActivate {
  private localStorageService = inject(LocalStorageService)

  constructor(public router: Router,
              private login: ModalLoginService)
  {}

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {
    const snVendedor = this.localStorageService.getVendedorInfo
    if (!snVendedor) {
      let inicio= this.login.iniciarSesion()
      console.log(inicio)
      inicio.afterClosed().subscribe(
        item =>{ 
          if(snVendedor){
            this.router.navigate(['admin-panel'])
            return true
          }else{
            this.router.navigate(['home']);
            return false;
          }
        }
      )
    }else{       
      return true;
    }
  }
}
