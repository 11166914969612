import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { CarritoService, EmbryoService, SessionService } from '@services';
import { Carrito, CarritoPublicacion, Persona } from '@app/_models';
import { AppSettings } from '@settings';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItems } from '@models'
import { NotificacionesService } from '@app/_services/notificaciones.service';
import { Observable } from 'rxjs';
import { FavoritosService } from '@app/_services/favoritos.service';
import { environment } from '@environments/environment';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { CarritoCatalogo } from '@app/_models/carritoCatalogo';




@Component({
  selector: 'HeaderTwo',
  templateUrl: './HeaderTwo.component.html',
  styleUrls: ['./HeaderTwo.component.scss'],
  providers : [],
  //changeDetection: ChangeDetectionStrategy.OnPush
})


export class HeaderTwoComponent implements OnInit {
   popupResponse : any;
   public session:Persona = new Persona();
   public comprador;
   public vendedor;
   public resultados
   public enProductos = false
   public $cantNotif: Observable<Number>
   public $cantFav: Observable<Number>
   public $cantCarrito: Observable<number>
   public $carritos: Observable<Carrito[]>
   public formBusqueda: UntypedFormGroup
   private localStorageService = inject(LocalStorageService)
   private carritoService = inject(CarritoService)


   constructor(public embryoService : EmbryoService,
               public service_session:SessionService,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private cdr: ChangeDetectorRef,
               private _serviceNotificaciones: NotificacionesService,
               public _serviceFavoritos: FavoritosService,
               private formBuilder: UntypedFormBuilder) {
                  router.events.subscribe(ev=>{
                     this.enProductos = router.isActive('/products',false)
                  })
                  this.formBusqueda = formBuilder.group({
                     busqueda: UntypedFormControl
                  })
                }

   ngOnInit() {
      this.carritoService.carritosObservable.subscribe(res=>{
         console.log(res)
      })
      this.$cantNotif = this._serviceNotificaciones.$cantNotificaciones
      this.$cantFav = this._serviceFavoritos.navbarWishlistProdCount
      this.$carritos = this.carritoService.carritosObservable
      this.$cantCarrito = this.carritoService.totalPublicacionesObservable
      this.session.estado = this.localStorageService.getUserInfo().estado
      this.comprador = this.localStorageService.getCompradorInfo().ID !== null;
      this.vendedor = this.localStorageService.getVendedorInfo().ID !== null;       
      this.service_session.estadoUsuarioInterno.subscribe(user=>{
         if(user){
            this.session.estado = 'conectado'
            this.comprador = this.localStorageService.getCompradorInfo().ID !== null;
            this.vendedor = this.localStorageService.getVendedorInfo().ID !== null;
            this._serviceFavoritos.calculateLocalWishlistProdCounts();
            this.embryoService.calculateLocalCartProdCounts();
         }else{
            this.session.estado = 'desconectado'
            this.comprador = 0
            this.vendedor = 0
         }
      })
   }
   

   /*public searchParameters = {
      query: ""
    };*/

   /*public setQuery({ query }: { query: string }) {
      this.searchParameters.query = query;
   }*/

   /*customSearchClient = {
      
      search(requests) {
         console.log('search',requests)
        return fetch('https://api.hiperabasto.com/api/publicaciones/search', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ requests }),
        }).then(results => results.json());
        
      },
    };
    
   public config = {
      indexName: 'test_hiper',
      searchClient: this.customSearchClient,
    };*/

   /*public async buscar(busqueda){
      if(busqueda.length >= 3){
         let result = await this.embryoService.getSearch(busqueda).toPromise()
         if(result['code']==201){
            this.resultados = result['data']
         }
         console.log(result)
      }
      
   }*/

   public buscar(busqueda:string){
      
      //window.location.href = `/products?${encodeURIComponent(`${environment.algoliaIndex}[query]`)}=${encodeURIComponent(busqueda)}`
      this.router.navigate(['products'],{
         relativeTo:this.activatedRoute,
         queryParamsHandling:'merge',
         queryParams:{
            'query':busqueda
         }
      })
      /*this.router.navigateByUrl("/products?query="+encodeURIComponent(busqueda)).then(f=>{
         /*if(this.enProductos){
            window.location.reload()
         }
         //window.location.reload()
      })*/
      
   }

   public toggleSearch() {
      document.querySelector('app-main').classList.toggle('form-open');
   }

   public toggleSidebar()
   {
      this.embryoService.sidenavOpen = !this.embryoService.sidenavOpen;
   }

   public openConfirmationPopup(value:CarritoPublicacion) {
      let message = "¿Está seguro de que desea eliminar este producto?";
      this.embryoService.confirmationPopup(message).
         subscribe(res => {this.popupResponse = res},
                   err => console.log(err),
                   ()  => this.getPopupResponse(this.popupResponse, value, 'cart')
                  );
   }

   public getPopupResponse(response:any, value:CarritoPublicacion, type) {
      if(response) {
         if(type == 'cart'){
            this.carritoService.deletePublicacion(value.publicacionID);
         } else {
            this._serviceFavoritos.removeLocalWishlistProduct(value);
         }
      }
   }

   /*public addAllWishlistToCart(values:any) {
      this._serviceFavoritos.addAllWishListToCart(values);
   } */

   public openWishlistConfirmationPopup(value:any) {
      let message = "¿Está seguro de que desea eliminar este producto?";
      this.embryoService.confirmationPopup(message).
         subscribe(res => {this.popupResponse = res},
                   err => console.log(err),
                   ()  => this.getPopupResponse(this.popupResponse, value, 'wishlist')
                  );
   }

   public selectedCurrency(value) {
      this.embryoService.currency = value;
   }

   public selectedLanguage(value) {
      this.embryoService.language = value;
   }

   public addToCart(value) {
      this.embryoService.addToCart(value, 'wishlist');
   } 

}
