<div>
  <div>
    <h2 mat-dialog-title>Modificación por lote de productos</h2>
  </div>
  <form [formGroup]="form" (ngSubmit)="guardar()">
    <div class="row col-12 mt-3">
      <h6>
        Modificación de precio
      </h6>
    </div>
    <div class="row col-12 mt-1">
      <div class="col-lg-4 col-md-12 col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Operación</mat-label>
          <mat-select formControlName="operacion" (selectionChange)="selectionChangeOpPrice()">
            <mat-option *ngFor="let op of operaciones" [value]="op.value">
              {{op.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Tipo de monto</mat-label>
          <mat-select formControlName="tipoMonto" (selectionChange)="selectionChangeMonto()">
            <mat-option *ngFor="let monto of tiposDeMonto" [value]="monto.value">
              {{monto.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-12 col-md-12 col-sm-12">
        <mat-form-field class="w-100">
          <input matInput type="number" formControlName="valor" placeholder="Valor" [(ngModel)]="modelValor"
            (ngModelChange)="updateControlValor()">
        </mat-form-field>
      </div>
    </div>

    <div class="row col-12 mt-4">
      <h6>
        Modificación de stock
      </h6>
      <div class="row col-12 mt-1">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Operación</mat-label>
            <mat-select formControlName="operacion_stock" (selectionChange)="selectionChangeOpStock()">
              <!--mat-option  value="" (click)="clearSelection()" >
                Seleccionar
              </mat-option-->
              <mat-option *ngFor="let op of operaciones" [value]="op.value">
                {{op.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-12 col-md-12 col-sm-12">
          <mat-form-field class="w-100">
            <input matInput type="number" formControlName="cant" placeholder="Cantidad" [(ngModel)]="modelCant"
              (ngModelChange)="updateControlCant()">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row col-12 mt-1">
      <mat-checkbox [(ngModel)]="eliminar_existencias" color="warn" (ngModelChange)="eliminarExistencias()" [ngModelOptions]="{standalone: true}">
        Eliminar existencias
      </mat-checkbox>
    </div>
    <div class="row col-12  mt-2">
      <h6>
        Modificando para: {{data.data.length}}
      </h6>
    </div>

    <div mat-dialog-actions>
      <button type="submit" [disabled]="!form.valid" mat-raised-button>Guardar</button>
      <button mat-button type="button" (click)="onNoClick()">Cancelar</button>
      <!--button mat-button [mat-dialog-close]="'closed'">Ok</button-->
    </div>
  </form>
</div>