<mat-dialog-content>
   <div class="app-popup">
      <div class="app-popup-content text-center">
         <div class="mb-4">
            <h4 *ngIf="!message">¿Estas seguro?</h4>
            <h4 *ngIf="message">{{message}}</h4>
         </div>
         <button mat-raised-button color="accent" class="mr-3" (click)="dialogRef.close('yes')">Si</button>
         <button mat-raised-button color="accent" class="mr-3" (click)="dialogRef.close()">No</button>
      </div>
   </div>
</mat-dialog-content>