import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import {SessionService} from '@services';
import {Persona} from '@models';
import { Router } from '@angular/router';
import { EmbryoService } from "../../../_services/embryo.service";
import { AppSettings } from "@settings"
import { ChatService } from '@app/_services/chat.service';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'embryo-HeaderUserProfileDropdown',
  templateUrl: './HeaderUserProfileDropdown.component.html',
  styleUrls: ['./HeaderUserProfileDropdown.component.scss'],
  providers :[],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderUserProfileDropdownComponent implements OnInit {

   public session:Persona = new Persona();
   public imgUser;
   public controlComprador;
   public vistaOrdenador:Boolean
   public mensajesSinLeer
   public hiddenBadge
   private localStorageService = inject(LocalStorageService)
   private $onDestroy = new Subject<void>()
   @Output() detectChanges: EventEmitter<any> = new EventEmitter()
   constructor(
    public service_session:SessionService,
    public router:Router,
    private EmbryoService: EmbryoService,
    private settings: AppSettings,
   ){ 

   }

   ngOnInit() {
    if(screen.width >= 810){
      this.vistaOrdenador = true
    }else{
        this.vistaOrdenador = false
    }
     this.session.estado = this.localStorageService.getUserInfo().estado
     this.controlComprador = this.localStorageService.getCompradorInfo().ID
     this.localStorageService.userInfoObservable.pipe(takeUntil(this.$onDestroy)).subscribe(res=>this.imgUser = this.settings.urls.hostFiles+res.foto+this.settings.imgXS)

      this.service_session.estadoUsuarioInterno.subscribe(user=>{
        if(user){
          this.session.estado = 'conectado',
          this.controlComprador = this.localStorageService.getCompradorInfo().ID
        }else{
          this.session.estado = 'desconectado'
        }
      })
        
    
   }

   ngOnDestroy(): void {
    this.$onDestroy.next()
   }

   desconectarSession(){
      this.service_session.logout()
      
   }
}
