//TODO definir la estructura de este objeto para trabajar con el como 

import { Marca, PublicacionInterface, Vendedor } from "@app/_interfaces/publicacion.interface";

//la publicacion dentro de la aplicacion en la parte de compradores
export class Publicacion implements PublicacionInterface{
    constructor(
        public ID:number,
        public productoID: number,
        public imgPortada: string,
        public titulo: string,
        public stock: number,
        public precio: number,
        public marca: Marca,
        public vendedor: Vendedor,
        public precios: any[],
        public dimenciones:{
            alto:number,
            largo:number,
            profundidad:number,
            peso:number,
            snFragil:boolean
        }
    ){

    }
    
}