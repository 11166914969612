import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable } from '@angular/core';
import { Domicilio } from '@models';
import { map } from "rxjs/operators"; //mapeo de respuesta de base de datos.
import { AppSettings } from '../app.settings'; //modulo global de direcciones.
@Injectable()
export class EmailService {
  host = this.appSettings.urls['host'] // variable global con la ip de la api.
  recurso_email = this.appSettings.urls['vendedores']
  recurso_persona = this.appSettings.urls['personas']

  constructor(
    private http: HttpClient,
    public appSettings:AppSettings,
  ) { }

  generarCodigo(vendedorId:number,oldEmail:string,newEmail:string){
    let json =  {
                    'motivo':'mail',
                    'mail': oldEmail,
                    'newMail':newEmail
                }
    return this.http.patch(`${this.host+this.recurso_email}/generar-codigo/id/${vendedorId}`, json).pipe(map(res => res ))
  }

  generarCodigoPersona(personaID:number,oldEmail:string, newEmail: string){
    let json = {
                  'motivo':'mail',
                  'mail':oldEmail,
                  'newMail':newEmail
                }
    return this.http.patch(`${this.host+this.recurso_persona}/generar-codigo/id/${personaID}`, json).pipe(map(res => res ))
  }
  
  confirmarCodigo(vendedorId:number,codigo:string,newEmail:string){
    let json =  {
                    'mail':newEmail,
                    'codigo':codigo
                }
    console.log(`${this.host+this.recurso_email}/confirmar-mail/id/${vendedorId}`)
    return this.http.patch(`${this.host+this.recurso_email}/confirmar-mail/id/${vendedorId}`, json).pipe(map(res => res ))
  }

  confirmarCodigoPersona(personaId:number,codigo:string,newEmail:string){
    let json = {
                  'mail':newEmail,
                  'codigo':codigo
                }
    console.log('enviando',json,'con id',personaId)
    return this.http.patch(`${this.host+this.recurso_persona}/confirmar-mail/id/${personaId}`, json).pipe(map(res => res ))
  }

  generarCodigoRecuperacion(email:string){
    let json = {
                  'motivo':'password',
                  'mail':email
                }
    return this.http.patch(`${this.host+this.recurso_persona}/generar-codigo/id/`, json).pipe(map(res => res ))            
  }

  cambiarContraseña(email:string,contraseña:string,codigo:string){
    let json = {
                  'mail' : email,
                  'password': contraseña,
                  'codigo': codigo
                }
    return this.http.patch(`${this.host+this.recurso_persona}/cambiar-password`, json).pipe(map(res => res ))
  }

  reenviarCodigo(email:string){
    let json = {
                  'mail':email
                }
    return this.http.post(`${this.host+this.recurso_persona}/reenviar-codigo/id/`, json).pipe(map(res => res ))
  }
}
