import { Component, OnInit, Input } from '@angular/core';
import { AppSettings } from '@settings'

@Component({
  selector: 'embryo-LighteningDeals',
  templateUrl: './LighteningDeals.component.html',
  styleUrls: ['./LighteningDeals.component.scss'],
  providers:[AppSettings]
})
export class LighteningDealsComponent implements OnInit {

   @Input() lighteningDeals : any;

   constructor(public appSettings : AppSettings) { }

   ngOnInit() {
   }

}
