import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Domicilio } from '@models';
import { DomiciliosService, ProvinciasService } from '@services';
import { ToastaService, ToastOptions } from 'ngx-toasta';
import { GeocodingMapComponent } from '../GeocodingMap/geocodingMap.component';
import { LocalStorageService } from '@app/_services/local-storage-service';

@Component({
  selector: 'app-AddDress',
  templateUrl: './Add-dress.component.html',
  styleUrls: ['./Add-dress.component.css'],
  providers: [DomiciliosService]
})
export class AddDressComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  @Input() id: number;
  @Input() idDireccion: string;
  public cargando
  public cargandoLocalidades
  public titulo
  public provincias = [];
  public localidades = [];
  public auxProvincia;
  public auxLocalidad;
  public domicilio: Domicilio;
  public formDireccion: UntypedFormGroup;
  public coordenadas
  private localStorageService = inject(LocalStorageService)
  toastOption: ToastOptions = {
    title: "",
    msg: "",
    showClose: true,
    timeout: 3000,
    theme: "material"
  };

  constructor(
    public dialogRef: MatDialogRef<AddDressComponent>,
    public service_provincia: ProvinciasService,
    public formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    public service_domicilio: DomiciliosService,
    private toastyService: ToastaService,
  ) {
    this.formDireccion = this.formBuilder.group({
      provinciaID: ['', Validators.required],
      localidadID: ['', Validators.required],
      codigoPostal: ['', Validators.compose([Validators.min(1000), Validators.max(9500), Validators.required])],
      direccion: ['', Validators.required],
      direccionNumero: ['',[Validators.required,Validators.pattern(/^([0-9])*$/)]],
      direccionPiso: ['',],
      depto: ['',],
      comentarios: ['',],
    });
    this.formDireccion.get('localidadID').disable()
  }

  ngOnInit() {
    this.domicilio = new Domicilio();
    this.cargarProvincias();
    if (this.idDireccion) {
      this.titulo = "Editar dirección"
      this.cargarDireccion();
    } else {
      this.titulo = "Agregar nueva dirección"
    }

  }

  async cargarProvincias() {
    this.cargando = true
    let result = await this.service_provincia.getProvincias().toPromise();
    this.provincias = result['data'];
    this.cargando = false
  }

  async cargarDireccion() {
    this.cargando = true
    let result = await this.service_domicilio.getID(Number(this.idDireccion)).toPromise();
    console.log(result);
    this.formDireccion.patchValue(result['data']);
    this.formDireccion.patchValue({ 'provinciaID': result['data'].provincia.ID })
    this.cargarLocalidades(result['data'].provincia.ID)
    this.formDireccion.patchValue({ 'localidadID': result['data'].localidad.ID })
    this.cargando = false
  }


  async guardarDireccion() {
    if (this.formDireccion.valid) {
      console.log(this.domicilio)
      if (this.idDireccion) {//editando direccion
        this.domicilio.ID = this.idDireccion
        let result = await this.service_domicilio.put(this.domicilio).toPromise()
        console.log(result)
        this.closeModal.nativeElement.click();
      } else {//agregando direccion
        if (this.coordenadas) {
          this.domicilio.longitud = this.coordenadas.lng
          this.domicilio.latitud = this.coordenadas.lat
          this.domicilio.personaID = this.localStorageService.getUserInfo().ID
          let result = await this.service_domicilio.post(this.domicilio).toPromise();
          console.log(result);
          console.log('direccion', this.domicilio);
          this.closeModal.nativeElement.click();
        } else {
          this.toastOption.title = "Error al crear domicilio"
          this.toastOption.msg = "Debes confirmar la ubicacion de la sucursal en el mapa"
          this.toastyService.error(this.toastOption)
        }

      }
      this.actualizarDomicilioLocalStorage()
    } else {
      Object.keys(this.formDireccion.controls).forEach(field => {
        const controlForm = this.formDireccion.get(field);
        controlForm.markAsTouched({ onlySelf: true });
      });
    }
  }

  async cargarLocalidades(provinciaID) {
    this.cargandoLocalidades = true
    this.localidades = []
    let result = await this.service_provincia.getLocalidadesProvinciaID(provinciaID).toPromise();
    if (result["code"] == 200) {
      this.localidades = result['data'];
    }
    this.formDireccion.get('localidadID').enable()
    this.cargandoLocalidades = false
  }

  /*FUNCION QUE CARGA EL OBJETO PROVINCIAID, GENERA UNA VARIABLE AUXILIAR PARA LA DIRECCION Y LLAMA A LAS LOCALIDADES */

  async seleccionarProvincia(event) {
    // LIMPIO LOCALIDADES POR SI LAS CARGARON ANTES
    this.localidades = [];

    // BUSCO LA PROVINCIA SELECCIONADA EN EL ARREGLO DE PROVINCIAS PARA SACARLE EL NOMBRE
    let provincia = this.provincias.filter(function (provincia) {
      return provincia.ID == event.value
    })

    // LLENO ATRIBUTO OBLIGATORIO DE DIRECCION
    this.domicilio.provinciaID = event.value;

    // LLENADO DE VARIABLE AUXILIAR DE PROVINCIA PARA LUEGO CONCATENARLA CON LA DIRECCION COMPLETA EN TIPO STRING
    this.auxProvincia = provincia[0].nombre;

    // PETICION PARA TRAER LAS LOCALIDESDE DE LAS PROVINCIAS SELECCIONADAS
    /*let result = await this.service_provincia.getLocalidadesProvinciaID(event.value).toPromise();
    if (result["code"] == 200) {
      this.localidades = result['data'];
    }*/
    this.cargarLocalidades(event.value)
  }

  /* FIN | FUNCION QUE CARGA EL OBJETO PROVINCIAID, GENERA UNA VARIABLE AUXILIAR PARA LA DIRECCION Y LLAMA A LAS LOCALIDADES */


  /*FUNCION QUE CARGA EL OBJETO LOCALIDADID, GENERA UNA VARIABLE AUXILIAR PARA LA DIRECCION */

  async seleccionarLocalidad(event) {

    // BUSCO LA LOCALIDAD SELECCIONADA EN EL ARREGLO DE LOCALIDADES
    let localidad = this.localidades.filter(function (localidad) {
      return localidad.ID == event.value
    })

    // LLENO ATRIBUTO OBLIGATORIO DE DIRECCION
    this.domicilio.localidadID = event.value;

    // LLENADO DE VARIABLE AUXILIAR DE PROVINCIA PARA LUEGO CONCATENARLA CON LA DIRECCION COMPLETA EN TIPO STRING
    this.auxLocalidad = localidad[0].nombre;
  }

  /*FIN | FUNCION QUE CARGA EL OBJETO LOCALIDADID, GENERA UNA VARIABLE AUXILIAR PARA LA DIRECCION */

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmarUbicacion() {
    let provinciaID = this.formDireccion.get('provinciaID').value
    let provincia = this.provincias.filter(prov=> prov.ID == provinciaID)[0].nombre
    let localidadID = this.formDireccion.get('localidadID').value
    let localidad = this.localidades.filter(loc => loc.ID == localidadID)[0].nombre
    let direccion = this.formDireccion.get('direccion').value
    let numero = this.formDireccion.get('direccionNumero').value
    const dialogRef = this.dialog.open(GeocodingMapComponent, {
      id: 'geocoding',
      height: '80%',
      width: '600px'
    })
    dialogRef.componentInstance.address = 'argentina,'+ direccion + ' ' + numero + ',' + localidad + ',' + provincia
    let asd = dialogRef.afterClosed().subscribe(fn => {
      console.log(fn)
      this.coordenadas = fn
    })
  }
  actualizarDomicilioLocalStorage() {
    if(localStorage.getItem('domicilio_ID') == undefined){
      localStorage.setItem('domicilio_ID', this.domicilio.ID)
      localStorage.setItem('domicilio_codigoPostal', this.domicilio.codigoPostal)
      localStorage.setItem('domicilio_direccion', this.domicilio.direccion)
      localStorage.setItem('domicilio_latitud', this.domicilio.latitud)
      localStorage.setItem('domicilio_longitud', this.domicilio.longitud)
      localStorage.setItem('domicilio_localidadID', String(this.domicilio.localidad.ID))
      localStorage.setItem('domicilio_provinciaID', String(this.domicilio.provincia.ID))
  
      localStorage.setItem('domicilio_depto', this.domicilio.depto != null ? this.domicilio.depto : "")
      localStorage.setItem('domicilio_numero', String(this.domicilio.numero != null ? this.domicilio.numero : ""))
      localStorage.setItem('domicilio_piso', String(this.domicilio.piso != null ? this.domicilio.piso : ""))
    }

  }

}
