<div class="section-gap pt-3 pb-0">
<mat-spinner  *ngIf="cargando" style="left: 50%;"></mat-spinner>
<ngx-slick-carousel class="carousel home-2-slider" [config]="slideConfig">
  <div ngxSlickItem *ngFor="let slide of slides">
    <div class="relative">
      <img appLazyLoad loading="lazy" *ngIf="slide.img" [src]="slide.img" class="">
      <!--<div class="home-2-slider-content w25" >
        <div [innerHTML]="slide?.content"></div>
        <button color="accent" class="w100" mat-raised-button (click)="irAPublicidad(slide.url)">Ir al Sitio</button>
      </div>-->
   </div>
  </div>
</ngx-slick-carousel>
</div>
