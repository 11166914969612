import { NgModule }   from '@angular/core'; 
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
//import { BarRatingModule } from "ngx-bar-rating";
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AngularMaterial } from './material.module';
import { AddToCardButtonComponent } from '@components/AddToCardButton/AddToCardButton.component';
import { HeaderCartComponent } from '@components/HeaderCart/HeaderCart.component';
import { WishListComponent } from '@components/WishList/WishList.component';
import { ConfirmationPopupComponent } from '@components/ConfirmationPopup/ConfirmationPopup.component';
import { PageTitleComponent } from '@components/PageTitle/PageTitle.component';
import { HomePageOneSliderComponent } from '@components/Slider/HomePageOneSlider/HomePageOneSlider.component';
import { TestimonialComponent } from '@components/Testimonial/Testimonial.component';
import { TeamComponent } from '@components/Team/Team.component';
import { MissionVisionComponent } from '@components/MissionVision/MissionVision.component';
import { AboutInfoComponent } from '@components/AboutInfo/AboutInfo.component';
import { ImgZoomComponent } from '@components/ImgZoom/ImgZoom.component';
import { CommonSignInComponent } from '@components/CommonSignIn/CommonSignIn.component';
import { ProductCardComponent } from '@components/ProductCard/ProductCard.component';
import { HeaderUserProfileDropdownComponent } from '@components/HeaderUserProfileDropdown/HeaderUserProfileDropdown.component';
import { AppLogoComponent } from '@components/AppLogo/AppLogo.component';
import { LighteningDealsComponent } from '@components/LighteningDeals/LighteningDeals.component';
import { HomePageTwoSliderComponent } from '@components/Slider/HomePageTwoSlider/HomePageTwoSlider.component';
import { CTATwoComponent } from '@components/CallToAction/CTA-Two/CTA-Two.component';
import { ProductCategoryCardComponent } from '@components/ProductCategoryCard/ProductCategoryCard.component';
import { CTASingleBannerComponent } from '@components/CallToAction/CTA-SingleBanner/CTA-SingleBanner.component';
import { ModalLoginComponent } from '@app/theme/components/Modal-login/modal-login.component';
import { MatDialogModule} from '@angular/material/dialog';
import { ModificationProductsBatchComponent } from '@components/ModificationProductsBatch/modification-products-batch.component';
import { ChangeAddressComponent } from '@components/ChangeAddress/change-address.component'
import { DeleteListDialogComponent } from '@components/DeleteListDialog/DeleteListDialog.component'
//import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AddDressComponent } from '@app/theme/components/AddDress/add-dress.component';
import { MobbexComponent} from '@components/Mobbex/Mobbex.component'
import { NotificationsComponent } from '@app/theme/components/Notifications/notifications.component';
import { NotificacionesService } from '@app/_services/notificaciones.service';
import {InfiniteScrollModule} from 'ngx-infinite-scroll'

/************************************ MAPS *******************************************/
import { GeocodingMapComponent} from '@components/GeocodingMap/geocodingMap.component'
import { DialogMensaje, InfoPopUpComponent } from '@app/theme/components/InfoPopup/infoPopUp.component';
import { CommonModule } from '@angular/common';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule,
        //BarRatingModule,
        FormsModule,
        ReactiveFormsModule,
        SlickCarouselModule,
        AngularMaterial,
        MatDialogModule,
        //PerfectScrollbarModule,
        InfiniteScrollModule
    ],
    declarations: [
        HeaderCartComponent,
        WishListComponent,
        ConfirmationPopupComponent,
        PageTitleComponent,
        HomePageOneSliderComponent,
        TestimonialComponent,
        TeamComponent,
        MissionVisionComponent,
        AboutInfoComponent,
        ImgZoomComponent,
        CommonSignInComponent,
        HeaderUserProfileDropdownComponent,
        AppLogoComponent,
        LighteningDealsComponent,
        HomePageTwoSliderComponent,
        CTATwoComponent,
        ProductCategoryCardComponent,
        CTASingleBannerComponent,
        ModalLoginComponent,
        DeleteListDialogComponent,
        AddDressComponent,
        MobbexComponent,
        ModificationProductsBatchComponent,
        ChangeAddressComponent,
        GeocodingMapComponent,
        NotificationsComponent,
        InfoPopUpComponent,
        DialogMensaje
    ],
    exports: [
        HeaderCartComponent,
        WishListComponent,
        PageTitleComponent,
        HomePageOneSliderComponent,
        TestimonialComponent,
        TeamComponent,
        MissionVisionComponent,
        AboutInfoComponent,
        ImgZoomComponent,
        CommonSignInComponent,
        HeaderUserProfileDropdownComponent,
        AppLogoComponent,
        LighteningDealsComponent,
        HomePageTwoSliderComponent,
        CTATwoComponent,
        ProductCategoryCardComponent,
        CTASingleBannerComponent,
        MatDialogModule,
        FlexLayoutModule,
        AngularMaterial,
        GeocodingMapComponent,
        NotificationsComponent,
        InfoPopUpComponent,
        DialogMensaje,
    ],
    providers: [
        NotificacionesService
    ]
})
export class GlobalModule {}
