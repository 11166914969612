<div class="col-gap product-grid" *ngIf="products && products.length > 0">
   <div class="product-grid-item" 
      [ngClass]="{'item-grid-4':gridLength===4}"
      *ngFor="let product of products; let i=index; trackBy: trackByObjectID"
   >
      <embryo-ProductCard *ngIf="gridLength && (i<gridLength)" [snTienda]="false" [snCheckout]="false" [product]=product [i]=i (onCantidadChange)="cantidadChange($event)" (addToCart)="addToCartProduct($event)" (addToWishList)="addToWishListFunction($event)"></embryo-ProductCard>
      
      <!-- Multiple show products grid -->
      <embryo-ProductCard *ngIf="!gridLength" [product]=product [i]=i (addToCart)="addToCartProduct($event)" (onCantidadChange)="cantidadChange($event)" (addToWishList)="addToWishListFunction($event)"></embryo-ProductCard>
      
   </div>
</div>