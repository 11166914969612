<div  class="img-zoom-container" #container *ngIf="imgSrc">
   <!-- SPINNER CHICO -->
   <div class="row col-12 justify-content-center mt-5" *ngIf="spinnerChico">
      <mat-progress-spinner [mode]="mode" [diameter]="35"></mat-progress-spinner>
    </div>
    <!-- FIN | SPINNER CHICO -->
   <img appLazyLoad width="370" height="370" loading="lazy" id="myimage" [src]="imgSrc" #img [ngStyle]="{visibility:spinnerChico?'hidden':''}" (load)="hideLoader()" (error)="hideLoader()" > 
   <!--<div  [ngClass]="{'hide': hide}" 
        id="myresult" 
        class="img-zoom-result" 
        #result>
   </div>-->
</div>  
