import { Injectable, inject } from '@angular/core';
import { LocalStorageService } from '@app/_services/local-storage-service';


export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'admin-panel/reports',
    name: 'Estadisticas',
    type: 'link',
    icon: 'poll'
  },
  /*
  {
    state: 'admin-panel/invoices',
    name: 'Invoices',
    type: 'link',
    icon: 'recent_actors'
  },
  */
  {
    state: 'admin-panel',
    name: 'Publicaciones',
    type: 'sub',
    icon: 'shopping_cart',
    children: [
      {state: 'products', name: 'Publicaciones',type : 'link'},
      {state: 'products/product-add', name: 'Nueva',type : 'link'},
    ]
  },
  {
    state:'admin-panel',
    name:'Ventas',
    type:'sub',
    icon:'attach_money',
    children:[
      {state:'products/precompras', name:'Preventas', type:'link'},
      {state:'products/ventas', name:'Ventas', type:'link'},
      
    ]
  },
  {
    state: 'admin-panel/preguntas/listado',
    name: 'Preguntas',
    type: 'link',
    icon: 'question_answer'
  },
  {
    state: 'admin-panel/mensajes/listado',
    name: 'Mensajes',
    type: 'link',
    icon: 'chat'
  },
  {
    state: 'admin-panel/account/profile',
    name: 'Perfil',
    type: 'link',
    icon: 'account_circle'
  },
  {
    state: 'admin-panel/fqa',
    name: 'Preguntas frecuentes',
    type: 'link',
    icon: 'help_outline'
  },
  {
    state: '/home',
    name: 'Sitio Web',
    type: 'link',
    icon: 'home'
  },
  {
    state:'admin-panel/publicidades',
    name:'Publicitarme',
    type:'link',
    icon:'campaign'
  }
];

const MENUREDUCIDO = [
  /*
  {
    state: 'admin-panel/invoices',
    name: 'Invoices',
    type: 'link',
    icon: 'recent_actors'
  },
  */
  {
    state: 'admin-panel/account/profile',
    name: 'Perfil',
    type: 'link',
    icon: 'account_circle'
  },
  {
    state: 'admin-panel/fqa',
    name: 'Preguntas frecuentes',
    type: 'link',
    icon: 'help_outline'
  },
  {
    state: '/home',
    name: 'Sitio Web',
    type: 'link',
    icon: 'home'
  }
];

const MENUCATALOGO = [
  {
    state: 'admin-panel',
    name: 'Publicaciones',
    type: 'sub',
    icon: 'shopping_cart',
    children: [
      {state: 'products', name: 'Publicaciones',type : 'link'},
      {state: 'products/product-add', name: 'Nueva',type : 'link'},
      {state: 'products/ventas', name:'Mis Ventas', type: 'link'}
    ]
  },
  {
    state: 'admin-panel/account/profile',
    name: 'Perfil',
    type: 'link',
    icon: 'account_circle'
  },
  {
    state: 'admin-panel/fqa',
    name: 'Preguntas frecuentes',
    type: 'link',
    icon: 'help_outline'
  },
  {
    state: '/home',
    name: 'Sitio Web',
    type: 'link',
    icon: 'home'
  },
  {
    state:'admin-panel/publicidades',
    name:'Publicitarme',
    type:'link',
    icon:'campaign'
  }
]

@Injectable()
export class AdminMenuItems {
  private localStorageService = inject(LocalStorageService)

  getAll(): Menu[] {
    let infoVendedor = this.localStorageService.getVendedorInfo()
    if(infoVendedor.tipo === 'VENDEDOR'){
      //quito la verificacion de mobbex
      //if(!infoVendedor.snMobbex){
        //return MENUREDUCIDO
      //}else{
        return MENUITEMS;
      //}
    }else{
      return MENUCATALOGO;
    }
    //console.log("RUTES ADMIN PANEL",MENUITEMS);
    
  }
}
