import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, inject } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { MenuItems } from '@models';
import { SessionService } from '@services';
@Component({
  selector: 'embryo-Menu',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './Menu.component.html',
  styleUrls: ['./Menu.component.scss'],
  providers:[],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit {
  @Input() from
  @Output() detectChanges: EventEmitter<any> = new EventEmitter()
  expanded: boolean;
  cerrarHover: boolean = true;
  menu = [];
  flagVendedor = false;
  flagComprador = false;
  itemsMenu
  private localStorageService = inject(LocalStorageService)
   constructor(
    public menuItems: MenuItems,
    public router: Router, 
    public service_session:SessionService,
    private cdr: ChangeDetectorRef) 
    {
      
    }

   
   ngOnInit(){
    console.time('iniciando menu')
    if(this.localStorageService.getVendedorInfo().ID)
      this.flagVendedor = true;
    if(this.localStorageService.getCompradorInfo().ID)
      this.flagComprador = true;

    this.service_session.estadoUsuarioInterno.subscribe(user=>{
      if(user){
        if(this.localStorageService.getVendedorInfo().ID){
          this.flagVendedor = true;
        }else{
          this.flagVendedor = false;
        }

        if(this.localStorageService.getCompradorInfo().ID){
          this.flagComprador = true
        }else{
          this.flagComprador = false
        }
      }
    })

    //console.log(this.menuItems.cargarMenu())
    this.menuItems.cargarMenu().finally(()=>{
      this.itemsMenu = this.menuItems.getMainMenu()
      console.log(this.itemsMenu)
      this.detectChanges.emit()
      console.log('emitiendo la deteccion de cambios menuComponent')
    })
    
    console.timeEnd('iniciando menu')
   }



   
   activar(i, menus){
    menus.forEach(menu => {
      menu.hidden = true;
    });
    menus[i].hidden = false;

   }


   public onItemSelected(item: any) {
      if (item.children && item.children.length) {
         this.expanded = !this.expanded;
      }
   }


   public redirectTo(subchildState){
    console.log("",subchildState)
      this.router.navigate([subchildState.state],{ queryParams:{ category: subchildState.queryState }});
   }

   cerrarModal()
   {
      this.cerrarHover = false;
      setTimeout(() => 
      {
        this.cerrarHover = true;
      },
      1000);
   }

   

}
