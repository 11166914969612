import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar'
import { ConfirmationPopupComponent } from '@components/ConfirmationPopup/ConfirmationPopup.component';
//import { ReviewPopupComponent } from '@components/ReviewPopup/ReviewPopup.component';
import { Carrito, CarritoPublicacion } from '@models';
import { AppSettings } from '@settings'; //modulo global de direcciones.
import { ToastaConfig, ToastaService, ToastOptions } from 'ngx-toasta';
import { map } from 'rxjs/operators';
import { DeleteListDialogComponent } from '@components/DeleteListDialog/DeleteListDialog.component';
//import { routes } from '@app/app-routing.module';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import { LocalStorageService } from './local-storage-service';

interface Response {
  data     : any;
}

@Injectable()
export class EmbryoService {
   private imgSource = new BehaviorSubject<any>("default message");
   currentImg = this.imgSource.asObservable();
   private localStorageService = inject(LocalStorageService)

   sidenavOpen                 : boolean = false;
   paymentSidenavOpen          : boolean = false;
   isDirectionRtl              : boolean = false;
   featuredProductsSelectedTab : any = 0;
   newArrivalSelectedTab       : any = 0;
   carritos = new Array<Carrito>();
   vendedorID;
   mostrarCarrito:boolean;
   /**** Get currency code:- https://en.wikipedia.org/wiki/ISO_4217 *****/
   currency  : string = 'USD';
   language  : string = 'en';     

   shipping  : number = 12.95;
   tax       : number = 27.95;

   products

   localStorageCartProducts : any;
   navbarCartCount : number = 0;
   buyUserCartProducts : any;
   cantidadPreguntas : number = 0;

   host = this.appSettings.urls['host'] // variable global con la ip de la api.
   recurso_publicaciones = this.appSettings.urls['publicaciones']
   recurso_vendedores = this.appSettings.urls['vendedores']
   recurso_personas = this.appSettings.urls['personas']
   recurso_FQA = this.appSettings.urls['preguntas_frecuentes']
   recurso_marcas = this.appSettings.urls['marcas']
   
   constructor(private http:HttpClient, 
               private dialog: MatDialog, 
               //private db: AngularFireDatabase,
               private toastyService: ToastaService,
               private toastyConfig: ToastaConfig,
               public appSettings:AppSettings,
               private _snackBar: MatSnackBar,
               private router: Router,
               public service_session:SessionService) { 
      this.mostrarCarrito = true;
      this.toastyConfig.position = "top-right";
      this.toastyConfig.theme = "material";
      this.calculateLocalCartProdCounts();
   }

   //Cambio de imagen de perfil
   changeImg(img: any){
      this.imgSource.next(img);
   }

   public confirmationPopup(message:string)
   {
      let confirmationPopup: MatDialogRef<ConfirmationPopupComponent>;
      confirmationPopup = this.dialog.open(ConfirmationPopupComponent);
      confirmationPopup.componentInstance.message = message;

      return confirmationPopup.afterClosed();
   }
   
   deleteDialog(data:string,titulo:string){
		let dialogRef : MatDialogRef<DeleteListDialogComponent>;
		dialogRef = this.dialog.open(DeleteListDialogComponent);
      dialogRef.componentInstance.data = data;
      dialogRef.componentInstance.titulo = titulo
		
		return dialogRef.afterClosed();
  }

   /*
      ----------  Cart Product Function  ----------
   */

   // Adding new Product to cart in localStorage
   public addToCart(data: any, type:any=""){
      return 'asd'
   /*   let userInfo = this.localStorageService.getUserInfo()
      if(!userInfo.ID){
         return data;
      }else{
         let matriz = JSON.parse(localStorage.getItem("cart_item")) || [];   
         //VARIABLE AUXILIAR QUE LLENA LA INFORMACION PRINCIPAL DEL CARRO
         let carrito = new Carrito();
         carrito.compradorID = localStorage.getItem('comprador_id');
         carrito.vendedorID = data.vendedor.ID;
         carrito.vendedor = data.vendedor;
         //VARIABLE AUXILIAR QUE LLENA LA INFORMACION DE LA PUBLICACION EN EL CARRO
         let carritoPublicaciones = new CarritoPublicacion();
         carritoPublicaciones.precio = data.precio;
         carritoPublicaciones.precios = data.precios
         carritoPublicaciones.cantidad = data.compraMinima;
         carritoPublicaciones.tipoDescuento = '%'
         carritoPublicaciones.descuento = data.descuento
         carritoPublicaciones.titulo = data.titulo;
         carritoPublicaciones.imgPortada = data.imgPortada;
         carritoPublicaciones.ID = data.ID;
         carritoPublicaciones.publicacionID = data.ID;
         //ATRIBUTOS PARA EL CALCULO DEL ENVIO
         let dims = {
            "alto":data.alto,
            "largo":data.largo,
            "profundidad":data.profundidad
         }
         carritoPublicaciones.peso = data.peso
         carritoPublicaciones.dimensiones = dims
         carritoPublicaciones.bultos = 1//data.compraMinima
         //LLENADO DE VARIABLE GLOBAL DE CARRITOS
         carrito.publicaciones.push(carritoPublicaciones);
         let found = false;
         //SI NO HAY NINGUNA PUBLICACION
         if(matriz.length == 0){
            this.carritos.push(carrito);
            // ACTUALIZACION DEL MULTICARROS 
            localStorage.setItem('cart_item',JSON.stringify(this.carritos));
         }else{    
            // control de que si carritos tiene [] entonces es probable que hayan recargado la pagina, en caso de que sea asi, hay que darle lo que hay en session
            if(this.carritos.length == 0){
               this.carritos = JSON.parse(localStorage.getItem('cart_item'));   
            }
            
            //Variable que verifica si el producto que estan agregando a carrito no este repetido.
            this.carritos.forEach(vendedor=>{
               vendedor.publicaciones.forEach(function(pub,index){
                  if(pub.ID == data.ID){
                     found = true;
                  }
               })
            })
            
               
            if(!found){
               let control = true;

               this.carritos.forEach(vendedor => {
                  // SI LO QUE QUIERE COMPRAR PERTENECE ALGUN VENDEDOR YA CARGADO PREVIAMENTE EN EL CARRITO 
                  if(vendedor.vendedorID == data.vendedor.ID){
                     //PUSH DE CARRITOPUBLICACION YA QUE TENES PRECARGADA LA INFORMACION DEL VENDEDOR Y COMPRADOR EN EL PUSH ANTERIOR
                     vendedor.publicaciones.push(carritoPublicaciones);
                     control = false;
                     //console.log("existe vendedor");
                  }
               });
               // SI NO HIZO MACHING LA PUBLICACION CON NINGUN VENDEDOR 
               if(control){
                  //console.log("no existe vendedor");
                  this.carritos.push(carrito);
               }
            }
         }
   
         // ACTUALIZACION DEL MULTICARROS 
         localStorage.setItem('cart_item',JSON.stringify(this.carritos));
         //Toast que avisa cuando agregas un producto al carrito 
         let toastOption: ToastOptions = {
            title: "Agregando producto",
            msg: "Agregando producto al carrito",
            showClose: true,
            timeout: 1000,
            theme: "material"
         };
         //En caso de que no se haya hecho el push del producto, la longuitud de ambos arreglos es la misma, entonces tira producto repetido.
         if(found) {
            toastOption.title = "Producto repetido";
            toastOption.msg = "Ya has agregado este producto al carrito";
         }
               
         this.toastyService.wait(toastOption);
         setTimeout(() => {
            this.calculateLocalCartProdCounts();
         }, 500);
      }
    */ 
   }

   actualizarCantidad(product,cantidad){
      let matriz = JSON.parse(localStorage.getItem("cart_item")) || [];
      console.log(matriz)
      console.log(product)
      //Busco el carrito que pertenece al vendedor del cual quiero actualizar la cantidad del producto
      let carritoVendedor= matriz.find(carrito=> carrito.vendedorID == product.vendedor.ID)
      console.log(carritoVendedor)
      //Busco el producto al que le quiero actualizar la cantidad
      carritoVendedor.publicaciones.forEach(producto => {
         if(producto.ID == product.ID){
            producto.cantidad = cantidad
            if(producto.precios){
               let freno = false
               producto.precios.forEach(precio => {
                  if(precio.cantidad <= producto.cantidad && !freno){
                     freno = true
                     producto.descuento = precio.descuento
                  }
               });
            }
         }
      });

      this.carritos = matriz
      console.log(matriz)

      localStorage.setItem('cart_item',JSON.stringify(matriz));
         /*Toast que avisa cuando cambias la cantidad un producto al carrito */
         let toastOption: ToastOptions = {
            title: "Actualizando carrito",
            msg: "Cambiando la cantidad el producto en el carrito",
            showClose: true,
            timeout: 1000,
            theme: "material"
         };
         //this.toastyService.wait(toastOption);
         setTimeout(() => {

            this.calculateLocalCartProdCounts();
         }, 500);

   }

   public buyNow(data:any) {
      let products : any;
      products = JSON.parse(localStorage.getItem("cart_item")) || [];

      let found = products.some(function (el, index) {
         if(el.name == data.name){
            if(!data.quantity) { data.quantity = 1}
            products[index]['quantity'] = data.quantity;
            return  true;
         }
      });
      if (!found) { products.push(data); }

      localStorage.setItem("cart_item", JSON.stringify(products));
      this.calculateLocalCartProdCounts();
   }


   /*FUNCION QUE BORRA EL MULTICARRO COMPLETO */
   public updateAllLocalCartProduct(products:any) {
      localStorage.removeItem('cart_item');
      localStorage.setItem("cart_item", JSON.stringify(products))
   }

   /* FUNCION QUE RETORNA LA CANTIDAD DE PRODUCTOS QUE HAY EN EL MULTICARRO */
   public calculateLocalCartProdCounts() {
      // PONE EN NULL LA VARIABLE AUXILIAR
      this.localStorageCartProducts = null;
      // TRAE EL MULTICARRITOS
      this.localStorageCartProducts =  JSON.parse(localStorage.getItem('cart_item')) || [];
      // VARIABLE AUXILIAR PARA CONTAR
      let cant = 0;
      // POR CADA VENDEDOR
      this.localStorageCartProducts.forEach(elem=>{
         if(elem.publicaciones){
            // POR CADA PUBLICACION DE VENDEDOR
            elem.publicaciones.forEach(pub=>{
               //SUMO POR CADA PRODUCTO
               cant++;
            })
         }
      })

      // ACTUALIZO VARIABLE EMIT DEL CONTADOR
      this.navbarCartCount = +(cant);
   }

   /* FUNCION QUE REMUEVE LOS PRODUCTOS DE UN CARRITO EN ESPECIFICO */
   public removeLocalCartProduct(product: any) {
      //console.log('eliminando un producto')
      //GET DE MULTICARRITOS
      let products: any = JSON.parse(localStorage.getItem("cart_item"));
      console.log(products)
      //POR CADA VENDEDOR
      for(let i = 0 ; i < products.length ; i++){
         // POR CADA PRODUCTO DE CADA VENDEDOR
         for (let j = 0; j < products[i].publicaciones.length; j++) {
            //UNA VEZ QUE ENCUENTRA EL PRODUCTO QUE SE QUIERE BORRAR
            if (products[i].publicaciones[j].ID == product.ID) {
               // PREGUNTA SI ES EL UNICO
               if(products[i].publicaciones.length == 1){
                  //ELIMINA EL VENDEDOR
                  products.splice(i, 1);
               }else{
                  //SACA UN PRODUCTO DE DICHO VENDEDOR
                  products[i].publicaciones.splice(j, 1);
               }
              break;
            }
         }
      }
      //console.log("products en service",products)

      //TOAST DE AVISO DE ELIMINACION
      let toastOption: ToastOptions = {
         title: "Quitar Producto",
         msg: "Eliminando producto del carrito",
         showClose: true,
         timeout: 1000,
         theme: "material"
      };

      // ACTUALIZACION DE BADGE Y DE LA VARAIBLE GLOBAL QUE MANEJA LOS CARRITOS QUE SE VAN A DAR DE ALTA EN LA API
      this.toastyService.wait(toastOption);
      setTimeout(() => {
         // ReAdding the products after remove
         localStorage.setItem("cart_item", JSON.stringify(products));
         this.carritos = products;
         this.calculateLocalCartProdCounts();
      }, 500);
   }

   /*FUNCION QUE ELIMINA TODOS LOS PRODUCTOS DEL CARRITO DE UN VENDEDOR*/
   public removerProductosVendedor(vendedorid:number){
      //console.log('borrando carrito del vendedor id',vendedorid)
      let products: any = JSON.parse(localStorage.getItem("cart_item"));
      let index= 0
      products.forEach(carrito => {
         if(carrito.vendedorID == vendedorid){
            products.splice(index,1)
         }
         index++
      });
      
      setTimeout(() => {
         // ReAdding the products after remove
         localStorage.setItem("cart_item", JSON.stringify(products));
         this.carritos = products;
         this.calculateLocalCartProdCounts();
      }, 500);
   }

   /* FUNCION QUE GUARDA EN LOCAL STORAGE LA ULTIMA COMPRA PARA PROVEERLA EN EL final-receipt*/
   public guardarCompraTemporal(vendedorid){
      let products: any = JSON.parse(localStorage.getItem("cart_item"));
      products.forEach(carrito => {
         if(carrito.vendedorID == vendedorid){
            localStorage.setItem("last_buy",JSON.stringify(carrito))
         }
      });
   }

   /**
    * Buy Product functions 
    */
   public addBuyUserDetails(formdata) {
      localStorage.setItem("user", JSON.stringify(formdata));
      
      let product = JSON.parse(localStorage.getItem("cart_item"))
      localStorage.setItem("byProductDetails", JSON.stringify(product));
      this.buyUserCartProducts = JSON.parse(localStorage.getItem("byProductDetails"))

      localStorage.removeItem("cart_item");
      this.calculateLocalCartProdCounts();
   }

   /**
    * getMasVentas usado para traer los vendedores con mas ventas en home
    */
   public getMasVentas(){
      return this.http.get(`${this.host+this.recurso_vendedores}/mas-ventas/days/30`).pipe(map(res=>res))
   }

   

   public patchUltimaVisita(productoId:number,personaId:number){
      let data = {'productoID':productoId}
      return this.http.patch(`${this.host+this.recurso_personas}/lastView/id/${personaId}`,data).pipe(map(res=>res))
   }

   public getStatePub(ID){
       //GET DE MULTICARRITOS
       let products: any = JSON.parse(localStorage.getItem("cart_item"));
       if(products){
         //POR CADA VENDEDOR
         for(let i = 0 ; i < products.length ; i++){
            // POR CADA PRODUCTO DE CADA VENDEDOR
            for (let j = 0; j < products[i].publicaciones.length; j++) {
               //UNA VEZ QUE ENCUENTRA EL PRODUCTO QUE SE QUIERE BORRAR
               if (products[i].publicaciones[j].ID == ID) {
                  return true;
               }
            }
         }
         return false
       }

   }

   public getCarritoVendedorID(idVendedor){
      //GET DE MULTICARRITOS
      let products: any = JSON.parse(localStorage.getItem("cart_item"));
      if(products){
        //POR CADA VENDEDOR
        for(let i = 0 ; i < products.length ; i++){
           //SI ENCUENTRO EL VENDEDOR QUE ESTOY BUSCANDO, ENTONCES DEVUELVO EL CARRITO ASOCIADO A EL.
           if(products[i].vendedorID == idVendedor){
              console.log(products[i].publicaciones)
              return products[i].publicaciones;
           }
        }
      }

      return false;
   }

   /**
    * getSearch realiza las busquedas por titulo o marca
    */
   public getSearch(like:string){
      return this.http.post(`${this.host+this.recurso_publicaciones}/algolia/like/page/1`,{'like':like}).pipe(map(res=>res))
   }

   /**
    * getPreguntasRespuestas trae paginadas las preguntas y respuestas de una publicacion
    */
   public getPreguntasRespuestas(idPublicacion:number,page:number){
      return this.http.get(`${this.host+this.recurso_publicaciones}/preguntas/publicacionID/${idPublicacion}/page/${page}`).pipe(map(res=>res))
   }

   /**
    * postPregunta guarda una pregunta hecha por un cliente acerca de una publicacion
    */
   public postPregunta(idPublicacion:number,idComprador:number,pregunta:string,vendedorId:number){
      let data = {
         'publicacionID':idPublicacion,
         'compradorID':idComprador,
         'pregunta':pregunta,
         'vendedorID':vendedorId
      }
      return this.http.post(`${this.host+this.recurso_publicaciones}/preguntas`,data).pipe(map(res=>res))
   }

   

   /**
    * restarPreguntas utilizado para restar el total de preguntas por responder
    */
   public  restarPreguntas(){
      this.cantidadPreguntas = this.cantidadPreguntas - 1
   }

   public  getCantidadPreguntas(){
      return this.cantidadPreguntas
   }

   public setCantidadPreguntas(cantidad){
      this.cantidadPreguntas = cantidad
   }


   //snack bar que se despliega si el vendedor no tiene la validacion de mobbex realizada
   faltaValidacion(message: string, action: string,options) {
      let router = this.router
      let accion = function(){
         router.navigate(['/admin-panel/account/validacion'])
      }
      this._snackBar.open(message, action,options).onAction().subscribe(accion);

   }

   //snack bar que se despliega si el vendedor no tiene sucursales creadas
   sinSucursales(message: string, action: string,options) {
      let router = this.router
      let accion = function(){
         router.navigate(['/admin-panel/account/sucursales'])
      }
      this._snackBar.open(message, action,options).onAction().subscribe(accion);
   }


   public patchUbicacion(vendedorID,json){
      return this.http.patch(`${this.host+this.recurso_vendedores}/ubicacion/id/${vendedorID}`,json).pipe(map(res=>res))
   }

} 
