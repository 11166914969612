<div>
  <div mat-dialog-content>

    <div class="text-center w100">
      <h4><strong>{{titulo}}</strong></h4>
    </div>

    <div class="row col-12 justify-content-center mt-4 mb-4" *ngIf="cargando">
      <mat-spinner></mat-spinner>
    </div>

    <form [formGroup]="formDireccion" *ngIf="!cargando">


      <div fxLayout="row wrap" fxLayoutAlign="none" class="col-gap">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Provincia</mat-label>
              <mat-select (selectionChange)="seleccionarProvincia($event)" formControlName="provinciaID" required
                [(ngModel)]="domicilio.provinciaID">
                <mat-option *ngFor="let provincia of provincias" [value]="provincia.ID">
                  {{provincia.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Localidad</mat-label>
              <mat-select (selectionChange)="seleccionarLocalidad($event)" formControlName="localidadID" required
                [(ngModel)]="domicilio.localidadID">
                <mat-option *ngFor="let localidad of localidades" [value]="localidad.ID">
                  {{localidad.nombre}}
                </mat-option>
              </mat-select>
              <mat-spinner *ngIf="cargandoLocalidades" [diameter]="20" matSuffix></mat-spinner>
            </mat-form-field>
          </div>
        </div>
      </div>


      <div fxLayout="row wrap" fxLayoutAlign="none" class="col-gap" required>
        <div fxFlex.xs="100" fxFlex.sm="1000" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Calle</mat-label>
              <input matInput [(ngModel)]="domicilio.direccion" required formControlName="direccion">
            </mat-form-field>
          </div>
        </div>

        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Número calle</mat-label>
              <input class="no-buttons" matInput type="number" required [(ngModel)]="domicilio.numero" formControlName="direccionNumero">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="none" class="col-gap" required>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Departamento</mat-label>
              <input class="no-buttons" matInput [(ngModel)]="domicilio.depto" formControlName="depto">
            </mat-form-field>
          </div>
        </div>

        <div fxFlex.xs="100" fxFlex.sm="1000" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Piso</mat-label>
              <input matInput [(ngModel)]="domicilio.piso" formControlName="direccionPiso">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="none" class="col-gap" required>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Codigo Postal</mat-label>
              <input class="no-buttons" matInput type="number" min="1000" max="9500" required [(ngModel)]="domicilio.codigoPostal" formControlName="codigoPostal">
            </mat-form-field>
          </div>
        </div>
      </div>

      <button class="w-100" mat-raised-button 
        disabled="{{formDireccion.controls.direccion.invalid && formDireccion.controls.provinciaID.invalid && formDireccion.controls.localidadID.invalid}}" 
        (click)="confirmarUbicacion()">
          Verificar ubicación 
          <span class="material-icons">
              where_to_vote
          </span>
      </button>

      <div fxLayout="row wrap" fxLayoutAlign="none" class="col-gap">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">
          <div class="mb-2">
            <mat-form-field class="w100">
              <mat-label>Comentarios</mat-label>
              <textarea matInput [(ngModel)]="domicilio.comentarios" formControlName="comentarios"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

    </form>

  </div>


  <div mat-dialog-actions class="row justify-content-center" *ngIf="!cargando">
    <button mat-raised-button (click)="onNoClick()" style="font-size:20px;" class="mr5">Salir</button>
    <button mat-raised-button cdkFocusInitial style="font-size:20px;" class="mr5" color="accent" (click)="guardarDireccion()">Guardar
    </button>
    <button [mat-dialog-close]="id" #closeModal hidden></button>
  </div>

</div>