import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './jwt.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { NgxSpinnerModule } from "ngx-spinner";


//import { FontAwesomeModule,FaIconLibrary } from '@fortawesome/angular-fontawesome';
//import {faSignInAlt,faCoffee} from '@fortawesome/free-solid-svg-icons'


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxSpinnerModule,
    //FontAwesomeModule
  ],
  declarations: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    //{ provide : HTTP_INTERCEPTORS , useClass : ErrorInterceptor , multi :true}
  ],
  exports:[
    //FontAwesomeModule
  ]
})

export class CoreModule {
  constructor(/*library: FaIconLibrary*/) {
    // Add an icon to the library for convenient access in other components
    //library.addIcons(faCoffee);
  }
 }
