import { Component, OnInit, Input, Output, EventEmitter, ViewChild, inject} from '@angular/core';
import { MatRipple, MatRippleModule } from '@angular/material/core';
import { Router, RouterLink } from '@angular/router';
import { AppSettings } from '@app/app.settings';
import { CarritoService, EmbryoService, SessionService } from '@app/_services';
import { FavoritosService } from '@app/_services/favoritos.service';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, CurrencyPipe, NgClass, NgIf } from '@angular/common';
import { AddToCardButtonComponent } from '../AddToCardButton/AddToCardButton.component';
import {MatChipsModule} from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { CustomCurrencyPipe } from '@app/_pipes/currency-custom.pipe';


@Component({
  selector: 'embryo-ProductCard',
  templateUrl: './ProductCard.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./ProductCard.component.scss'],
  standalone:true,
  imports:[
   MatFormFieldModule,
   MatIconModule,
   FormsModule,
   RouterLink,
   CustomCurrencyPipe,
   AsyncPipe,
   AddToCardButtonComponent,
   MatChipsModule,
   MatCardModule,
   NgClass,
   MatRippleModule,
   NgIf,
   MatButtonModule,
  ]
})
export class ProductCardComponent implements OnInit {

   @Input() product : any;

   @Input() index   : any;

   @Input() currency : string;

   @Input() type  : string = '';

   @Input() snTienda = true

   @Input() snCheckout = true

   @Input() i //indice que le toca al producto en la grilla

   @Output() addToCart: EventEmitter<any> = new EventEmitter();

   @Output() addToWishList: EventEmitter<any> = new EventEmitter();

   @Output() onCantidadChange:EventEmitter<{product:any,cantidad:number}> = new EventEmitter()

   @ViewChild(MatRipple) ripple: MatRipple;

   public isVendedor: boolean;
   public sinStock
   public menorAMinimo
   public mayorDescuento = 0
   private localStorageService = inject(LocalStorageService)
   private sessionService = inject(SessionService)
   public userInfo = this.sessionService.userInfo
   public snComprador = this.localStorageService.getCompradorInfo().ID !== null
   private carritoService = inject(CarritoService)



   constructor(public appSetting: AppSettings,
      public embryoService : EmbryoService,
      private router: Router,
      private _favoritosService: FavoritosService) { }

   ngOnInit() {
      if(this.localStorageService.getVendedorInfo().ID !== null)
         this.isVendedor = true;
      else
         this.isVendedor = false;
      this.product.precios?.forEach(d=>{
         if(d.descuento > this.mayorDescuento){
            this.mayorDescuento = d.descuento
         }
      })
   }

   public addToCartProduct(value:any) {
      console.log('agregando al carrito',value)
      this.addToCart.emit(value);
   }

   /*public productAddToWishlist(value:any, parentClass) {
      if(!(document.getElementById(parentClass).classList.contains('wishlist-active'))){
         let element = document.getElementById(parentClass).className += " wishlist-active";
      }
      this.addToWishlist.emit(value);
   }*/

   public productAddToWishlist(value:any, parentClass) {
      //si el producto ya esta en favoritos lo quito
      if(value.inWishList){
         value.inWishList = false
         document.getElementById(parentClass).getElementsByTagName('i')[0].classList.remove('favorito-icon-selected');
         this._favoritosService.removeLocalWishlistProduct(value)
      }else{
         value.inWishList = true
         document.getElementById(parentClass).getElementsByTagName('i')[0].classList.add('favorito-icon-selected');
         this._favoritosService.addToWishlist(value)
      }
      this._favoritosService.notificarFavoritosChange()
      this.addToWishList.emit(value);
      /*if(!(document.getElementById(parentClass).classList.contains('wishlist-active'))){
         document.getElementById(parentClass).getElementsByTagName('i')[0].classList.add('favorito-icon-selected');
      }*/
      
   }

   public checkCartAlready(singleProduct) {
      return this.carritoService.checkCartAlready(singleProduct)
   }

   public cantidadChange(cantidad:number){
      this.onCantidadChange.emit({product:this.product,cantidad:cantidad})
   }

   navegar(id){
      const rippleRef = this.ripple.launch({
         persistent: false,
         centered: true
       });
      setTimeout(()=>{
         this.router.navigate(['/products/id', id])
      },100)
   }

   sumarCarrito(producto){
      this.sinStock = false
      if(producto.stock>producto.cantidadSeleccionada){
         producto.cantidadSeleccionada ++
         this.embryoService.actualizarCantidad(producto,producto.cantidadSeleccionada)
      }else{
         this.sinStock = true
      }
   }

   descontarCarrito(producto){
      producto.cantidadSeleccionada--
      if(producto.compraMinima<producto.cantidadSeleccionada){
         this.embryoService.actualizarCantidad(producto,producto.cantidadSeleccionada)
      }else{
         this.embryoService.removeLocalCartProduct(producto)
      }
      if(producto.stock>producto.cantidadSeleccionada){
         this.sinStock = false
      }else{
         this.sinStock = true
      }
   }

   cambiarCantidadCarrito(producto,event){
      this.sinStock = false
      let cant = Number(event.target.value)
      producto.cantidadSeleccionada = cant
      console.log(cant)
      if(cant>0){
         if(producto.stock < cant){
            this.sinStock = true
         }else{
            if(cant<producto.compraMinima){
               this.embryoService.removeLocalCartProduct(producto)
            }else{
               
               this.embryoService.actualizarCantidad(producto,cant)
            }
         }
      }else{
         this.embryoService.removeLocalCartProduct(producto)
      }
   }

   /*public checkCartAlready(singleProduct) {
      let products = JSON.parse(localStorage.getItem("cart_item")) || [];
      if (!products.some((item) => item.id == singleProduct.id)) {
         return true;
      }
   }*/

}
