import { Component, OnInit, inject } from '@angular/core';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { NotificacionesService } from '@app/_services/notificaciones.service';
import { AdminPanelServiceService, EmbryoService } from '@services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header-component',
  templateUrl: './Header.component.html',
  styleUrls: ['./Header.component.scss']
})
export class AdminHeaderComponent implements OnInit {
   private localStorageService = inject(LocalStorageService)
   public nombre = this.localStorageService.getUserInfo().nombre
   public $cantNotif: Observable<Number>
	constructor(private coreService : AdminPanelServiceService,
               public embryoService: EmbryoService,
               private _serviceNotificaciones: NotificacionesService) { }

	ngOnInit() {
      this.$cantNotif = this._serviceNotificaciones.$cantNotificaciones
	}

	/**
     * toggleSidebar method is used a toggle a side nav bar.
     */
   toggleSidebar() {
      this.coreService.sidenavOpen = !this.coreService.sidenavOpen;
   }

   public selectedLanguage(value) {
      this.embryoService.language = value;
   }

}
