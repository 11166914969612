export class MenuCategoria{
	constructor(){
        this.children = new Array<MenuCategoria>();
    }
	state:string;
    name:string;
    type:string;
    mega:boolean;
    icon:string;
    iconCustom:string
    hidden:boolean;
    children:Array<MenuCategoria>;

}