<div class="sidebar-panel embryo-sidenav colorDegradadoSecundario" fxLayout="column">
   <!--<perfect-scrollbar class="scrollbar" [config]="config">-->
      <div class="sidebar-container">
      	<div class="p-4">
      		<a [routerLink]="['/']"><img appLazyLoad width="100" height="60" loading="lazy" src="../../../../../assets/images/logo-blanco.png"></a>
      	</div>
         <div class="mb-5 mt-3"></div>
        	<app-menu-list-items></app-menu-list-items>
      </div>
   <!--</perfect-scrollbar>-->
</div>
<!-- sidebar-panel and embryo-sidenav -->