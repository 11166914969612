import { Component, EventEmitter, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'appInstallBanner',
    templateUrl: 'appInstallBanner.component.html',
    styleUrls: ['appInstallBanner.component.scss']
})

export class AppInstallBannerComponent{
    @Output() userResponse: EventEmitter<boolean> = new EventEmitter()

    constructor(){

    }

    action(res:boolean){
        this.userResponse.emit(res)
    }
}