import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Inject, Injectable, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppSettings } from '@settings'; //modulo global de direcciones.
import SHA3 from 'crypto-js/sha3';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { LoginResponse } from '@app/_interfaces/login.interface';
import { LocalStorageService } from './local-storage-service';

export enum TipoUsuario{
  COMPRADOR='COMPRADOR',
  VENDEDOR='VENDEDOR',
  CATALOGO='CATALOGO'
}

export class UserInfo{
  tipo:TipoUsuario
  snSesion:boolean
}

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class SessionService {
  //estadoUsuario;
  private usuarioConectado
  private $usuarioConectado = new Subject<any>() //subject se encarga de producir el flujo de eventos
  private infoUsuario:UserInfo = {tipo:null,snSesion:false}
  private $userInfo = new BehaviorSubject<UserInfo>(this.infoUsuario) // COMPRADOR VENDEDOR CATALOGO null si no tiene sesion
  public userInfo = this.$userInfo.asObservable()
  public estadoUsuarioInterno  = this.$usuarioConectado.asObservable()
  estadoRefreshFavoritos;
  host = this.appSettings.urls['host']
  recurso = this.appSettings.urls['personas'];
  private localStorageService = inject(LocalStorageService)

  constructor(
    private http: HttpClient,
    private appSettings:AppSettings,
    public ngZone:NgZone,
    private router : Router,
    @Inject(PLATFORM_ID) private platformId) {
      
      if(this.localStorageService.getVendedorInfo().ID){
        this.infoUsuario.snSesion = true;
        (this.localStorageService.getVendedorInfo().tipo === 'CATALOGO')?
          this.infoUsuario.tipo = TipoUsuario.CATALOGO
        :
          this.infoUsuario.tipo = TipoUsuario.VENDEDOR
      }else{
        if(this.localStorageService.getCompradorInfo().ID){
          this.infoUsuario.snSesion= true
          this.infoUsuario.tipo = TipoUsuario.COMPRADOR
        }
      }
      this.$userInfo.next(this.infoUsuario)
    }

    /*GET DE PERSONAS Y SUS TIPOS */

    getID(id){
      return this.http.get(`${this.host+this.recurso}/ID/${id}`)
    }

    getVendedorID(id){
      return this.http.get(`${this.host+this.recurso}/vendedores/personaid/${id}`)
    }

    getCompradorID(id){
      return this.http.get(`${this.host+this.recurso}/compradores/personaid/${id}`)
    }

    /* FIN | FUNCIONES  DE SOCKET */

    /* FUNCIONES  DE SOCKET */
    notificarRefreshFavoritos(){
      this.ngZone.runOutsideAngular(()=>{
        let data ={
          ID : this.localStorageService.getUserInfo().ID
        }
        //this.estadoRefreshFavoritos.next(data);
      })
    }
    
    /* FIN | FUNCIONES  DE SOCKET */


    /* POST DE PERSONA Y SUS TIPOS */
    crearUsuario(user){
      //creo el hash con SHA3
      let hash = SHA3(user.password).toString()

      //creo una sal de 6 digitos
      let sal= ''
      for(let i=0;i<6;i++){
        sal = sal + Math.round(Math.random()*9)
      }

      console.log('sal',sal)

      //concateno la sal y el hash para crear un hash salado
      let hashSalado = sal+hash

      

      //encripto nuevamente con SHA3 el hash salado
      let hashReforsado = SHA3(hashSalado).toString()

      console.log(hashReforsado)

      //guardo en password la contraseña super encriptada y la sal
      let pw = sal+hashReforsado

      let json = {
                  'nombre':user.nombre,
                  'genero' : user.genero,
                  'user':user.user,
                  'password':pw,
                  'DNI':user.DNI,
                  'telefono':user.telefono,
                  'mail':user.mail,
                  'tokenGoogle':user.tokenGoogle
                  }
      //let json = JSON.stringify(user);
      //json = JSON.parse(json);
      return this.http.post(`${this.host+this.recurso}`, json).pipe(map(res => res ))
    }

    cambiarPwInterno(pwAnterior:string, pwNueva:string, idPersona:number){
      //creo el hash con SHA3
      let hash = SHA3(pwNueva).toString()

      //creo una sal de 6 digitos
      let sal= ''
      for(let i=0;i<6;i++){
        sal = sal + Math.round(Math.random()*9)
      }

      //concateno la sal y el hash para crear un hash salado
      let hashSalado = sal+hash

      

      //encripto nuevamente con SHA3 el hash salado
      let hashReforsado = SHA3(hashSalado).toString()

      //guardo en password la contraseña super encriptada y la sal
      let pw = sal+hashReforsado

      let pwAnt = SHA3(pwAnterior).toString()

      let json = {
        'password':pwAnt,
        'password_new': pw
      }

      return this.http.patch(`${this.host+this.recurso}/password/id/${idPersona}`,json).pipe(map(res=>res))

    }

    crearComprador(user){
      let json = JSON.stringify(user);
      json = JSON.parse(json);
      return this.http.post(`${this.host+this.recurso}/compradores`, json).pipe(map(res => res ))
    }

    crearVendedor(user){
      let json = JSON.stringify(user);
      json = JSON.parse(json);
      return this.http.post(`${this.host+this.recurso}/vendedores`, json).pipe(map(res => res ))
    }

    /*FIN | POST DE PERSONA Y SUS TIPOS */


    /* PUT DE PERSONA Y SUS TIPOS */
    putPersona(user,id){
      let json = {
        'nombre':user.nombre,
        'user':user.user,
        'telefono':user.telefono,
        'genero': user.genero
      }
      console.log('enviando',json)
      return this.http.put(`${this.host+this.recurso}/id/${id}`, json).pipe(map(res => res ))
    }

    putVendedor(user,id,tipo:'VENDEDOR'|'CATALOGO'){
      if(tipo == 'VENDEDOR'){
        return this.http.put(`${this.host+this.recurso}/vendedores/id/${id}`, user)
      }else{
        return this.http.put(`${this.host+this.recurso}/vendedores/catalogo/id/${id}`,user)
      }
      
    }

    

    /* FIN | PUT DE PERSONA Y SUS TIPOS */


    /* IMAGENES DE PERSONA Y SUS TIPOS */
    addimgPersona(image,idPersona){
      return this.http.post<{code:number,data:{foto:string}}>(`${this.host+this.recurso}/imgs/id/${idPersona}`, image).pipe(map(res => res ));
    }

    addimgVendedor(image,idPersona){
      var json = JSON.stringify(image)
      return this.http.post(`${this.host+this.recurso}/vendedores/imgs/id/${idPersona}`, json).pipe(map(res => res ));
    }

    addPortadaTienda(image,idPersona){
      var json = JSON.stringify(image)
      return this.http.post<{code:number,data:{foto:string}}>(`${this.host+this.recurso}/vendedores/imgs/banner/id/${idPersona}`, json).pipe(map(res => res ));
    }

    addLogoTienda(image,idVendedor){
      var json = JSON.stringify(image)
      return this.http.post<{code:number,data:{foto:string}}>(`${this.host+this.recurso}/vendedores/imgs/id/${idVendedor}`, json).pipe(map(res => res ));
    }

    addImagenPublicitaria(image,idVendedor){
      var json = JSON.stringify(image)
      return this.http.post<{code:number,data:{foto:string}}>(`${this.host+this.recurso}/vendedores/imgs/bannerHome/id/${idVendedor}`, json).pipe(map(res => res ));
    }

    /* FIN | IMAGENES DE PERSONA Y SUS TIPOS */
    notificarLoginStatus(){
      let user: any;
        if(isPlatformBrowser(this.platformId)){
            if (this.localStorageService.getUserInfo().ID != null) {
                user = {
                  id:this.localStorageService.getUserInfo().ID,
                  snComprador: this.localStorageService.getCompradorInfo() !== undefined,
                  snVendedor: this.localStorageService.getVendedorInfo !== undefined
                }
            }
        }else{
            user = null
        }
        (user != null) ? this.usuarioConectado = true : this.usuarioConectado = false;
        this.$usuarioConectado.next(this.usuarioConectado)
    }

    login(user,tipo){
      let json = {
                    'user':user.user,
                    'password': SHA3(user.password).toString()
                  }
        return this.http.post<LoginResponse>(`${this.host+this.recurso}/login`, json).pipe(map(res => {
          if(res.code===201){
            if(res.data.snComprador){
              this.infoUsuario = {snSesion:true,tipo:TipoUsuario.COMPRADOR}
            }
            if(res.data.snVendedor){
              this.infoUsuario = {snSesion:true,tipo:TipoUsuario[res.data.vendedor.tipo]}
            }
            this.$userInfo.next(this.infoUsuario)
          }
          return res
        } ))
    }

    logout(){
      this.localStorageService.clearAllLocalStorage()
      this.notificarLoginStatus()
      this.infoUsuario = {snSesion:false,tipo:null}
      this.$userInfo.next(this.infoUsuario)
      this.router.navigate(['/home'])
    }

    /* PATCH DE PERSONA Y SUS TIPOS */

    patchSnComprador(id){
      const json_estado = {
        snComprador: '1'
      };
      let json = JSON.stringify(json_estado);
      return this.http.patch(`${this.host+this.recurso}/snComprador/ID/${id}`, json).pipe(map(res => res ));
    }

    patchSnVendedor(id){
      const json_estado = {
        snVendedor: '1'
      };
      let json = JSON.stringify(json_estado);
      return this.http.patch(`${this.host+this.recurso}/snVendedor/ID/${id}`, json).pipe(map(res => res ));
    }

    patchPersona(razon,id){
      const json_estado = {
        estado: 'INACTIVO',
        motivo:  razon
      };
      let json = JSON.stringify(json_estado);
      return this.http.patch(`${this.host+this.recurso}/estado/ID/${id}`, json).pipe(map(res => res ));
    }

    
    /* FIN | PATCH DE PERSONA Y SUS TIPOS */

    /* REENVIO DE EMAILS */
    reenviarEmail(value){
      let json = JSON.stringify(value);
      return this.http.post(`${this.host+this.recurso}/reenviar-codigo/id/${value.ID}`, json).pipe(map(res => res ));
    }

    confirmarCodigo(idPersona,codigo,email){
      let json = {
        'mail':email,
        'codigo':codigo
      }
      return this.http.patch(`${this.host+this.recurso}/confirmar-mail/id/${idPersona}`, json).pipe(map(res => res ));
    }

}