import { Vendedor } from "@app/_interfaces/publicacion.interface";
import { CarritoPublicacion } from "./carritoPublicaciones";
import { Publicacion } from "./publicacion";
import { VendedorDetalle } from "@app/_interfaces/vendedor-detalle.interface";


export class Carrito{
    public total: number
    public subtotal: number
    public totalPrecompra: number
    public subtotalPrecompra: number
    private montoComision:number
    private montoComisionPrecompra:number
    private montoComisionPagos360:number
    private vendedorDetalle: VendedorDetalle
    
    constructor(
        public vendedorID:number,
        public compradorID:number,
        public domicilio,
        public tipoDescuento,
        public descuento,
        public vendedor:Vendedor,
        public publicaciones:Array<CarritoPublicacion>,
        private comision:number,
        private comisionPagos360:number,
        private comisionHiper:number,
        public ID?:number
        ){
        this.publicaciones = this.publicaciones.map(p=>(CarritoPublicacion.Crear(p)))
        this.calcularTotal()
    }

    public addPublicacion(publicacion:Publicacion,cantidad:number){
        let index = this.publicaciones.findIndex(p=>p.ID == publicacion.ID)
        if(index!==-1){
            this.publicaciones[index].cantidad = cantidad
        }else{
            let newPublicacion = new CarritoPublicacion(publicacion.ID,publicacion.precio,publicacion.precios,cantidad,publicacion.titulo,publicacion.imgPortada,0,0,0,0,publicacion.dimenciones) 
            this.publicaciones.push(newPublicacion)
        }
    }

    public cambiarCantidad(publicacionID:number,cantidad:number){
        let publicacion = this.publicaciones.find(p=>p.publicacionID == publicacionID)
        if(cantidad>0){
            publicacion.cantidad = cantidad
        }else{
            this.deletePublicacion(publicacion)
        }
    }

    public cambiarPrecio(publicacionID:number,precio:number){
        let publicacion = this.publicaciones.find(p=>p.publicacionID == publicacionID)
        publicacion.precio = precio
    }

    public deletePublicacion(publicacion:CarritoPublicacion){        
        this.publicaciones.splice(this.publicaciones.indexOf(publicacion),1)
    }

    /**
     * calcula el subtotal del carrito que es la sumatoria de los totales de las publicaciones
     * Calcula el total del carrito que es el subtotal + el porcentaje de comision
     * Calcula el total para la precompra que es un porcentaje del subtotal
     */
    private calcularTotal(){
        this.subtotal = this.publicaciones.reduce((total,publicacion)=>
           publicacion.getPrecioDescuento()? total+publicacion.getPrecioDescuento() : total+publicacion.getPrecioTotal()
        ,0)
        this.total = this.subtotal*this.comision
        this.subtotalPrecompra = this.subtotal*0.10
        this.totalPrecompra = (this.subtotal*(this.comisionHiper-1))*this.comisionPagos360 + this.subtotalPrecompra*this.comisionPagos360
        this.montoComision = this.subtotal * (this.comision-1)
        this.montoComisionPrecompra = this.subtotalPrecompra * (this.comisionPagos360 - 1) + this.subtotal*(this.comisionHiper - 1)
        this.montoComisionPagos360 = (this.subtotalPrecompra + this.subtotal*(this.comisionHiper-1)) * (this.comisionPagos360-1)
    }

    /**
     * Setea la comision para el carrito con la cual se calculara el total del carrito
     * Recalcula el total del carrito con la nueva comision
     * @param comision valor multiplicativo producto de hacer 1+ %comision/100
     */
    public setComision(comision:number){
        this.comision = comision
        this.calcularTotal()
    }

    public setComisionPagos360(comision:number){
        this.comisionPagos360 = comision
        this.calcularTotal()
    }

    public setComisionHiper(comision:number){
        this.comisionHiper = comision
        this.calcularTotal()
    }

    /**
     * 
     * @returns porcentaje de comision aplicada al carrito
     */
    public getComision():number{
        return Number.parseFloat(((this.comision - 1)*100).toFixed(2))  
    }

    /**
     * 
     * @returns El total cobrado en concepto de comision
     */
    public getMontoComision():number{
        return this.montoComision
    }

    public getMontoComisionPrecompra(){
        return this.montoComisionPrecompra
    }

    public getMontoComisionPagos360(){
        return this.montoComisionPagos360
    }

    /**
     * Función que devuelve el total del carrito SIN las comisiones
     * @returns subtotal del carrito
     */
    public getSubtotal(){
        return this.subtotal
    }

    /**
     * devuelve el precio total del carrito CON las comisiones aplicadas
     * @returns precio a pagar por el carrito
     */
    public getTotal(){
        return this.total
    }

    /**
     * Función que retorna el detalle del vendedr asociado al carrito
     * @returns El detalle del vendedor asociado al carrito
     */
    public getVendedorDetalle():VendedorDetalle{
        return this.vendedorDetalle
    }

    public static Crear(obj:Carrito){
        let carrito =  new Carrito(
            obj.vendedorID,
            obj.compradorID,
            obj.domicilio,
            obj.tipoDescuento,
            obj.descuento,
            obj.vendedor,
            obj.publicaciones,
            obj.comision,
            obj.comisionPagos360,
            obj.comisionHiper,           
            obj.ID,
        )
        
        return carrito
    }
    
}