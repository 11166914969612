import { Injectable } from '@angular/core';
import {AppSettings} from '@settings'

@Injectable()
export class IsOnlineService {
  

  constructor(private appSettings:AppSettings) {}

    checkOnlineStatus = async () => {
    try {
      const online = await fetch(this.appSettings.urls.hostFiles+"files/pixel.png");
      return online.status >= 200 && online.status < 300; // either true or false
    } catch (err) {
      return false; // definitely offline
    }
  };
  

  

  
  
}