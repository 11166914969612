<form [formGroup]="loginForm">
  <div class="mb-4">
    <mat-form-field class="w-100">
      <input matInput type="text" formControlName="user" placeholder="Email o Usuario" required>
    </mat-form-field>
    <mat-error *ngIf="loginForm.get('user').touched && loginForm.get('user').hasError('required')">Debes colocar un usuario o email</mat-error>
  </div>
  <div>
    <mat-form-field class="w-100">
      <input matInput type="password" formControlName="password" placeholder="Contraseña" required>
    </mat-form-field>
    <mat-error *ngIf="loginForm.get('user').touched && loginForm.get('password').hasError('required')">Debes colocar una contraseña</mat-error>
  </div>
  <div class="mb-3" *ngIf="desactivada">
    <small class="text-danger"> Tu cuenta esta desactivada <p class="text-info"> Ponganse en contacto con : hiperabasto@gmail.com</p></small>
  </div>
  <div class="mb-3" *ngIf="sinConfirmarEmail">
    <small> <p>No has confirmado tu email, enviamos un codigo a <strong>{{emailRegistro}}</strong> </p></small>
    <button mat-stroked-button (click)="completarRegistro()">completar el registro</button>
  </div>
  <div class="mb-3" *ngIf="userPassIncorrecto">
    <small class="text-danger"> Usuario o contraseña incorrecta</small>
  </div>
  <div class="mb-3">
    <span fxFlex></span> <a [routerLink]="['/session/forgot-password']">¿Olvidaste la contraseña?</a>
  </div>
  <div class="col-12">
    <div class="col-12">
      <button color="primary" (click)="iniciar('comun')" class="button-lg mb-3 w100" mat-raised-button>
        <div *ngIf="!cargando">Continuar</div>
        <div class="row justify-content-center" *ngIf="cargando">
          <mat-progress-spinner
            [mode]="'indeterminate'"
            [diameter] = "30">
        </mat-progress-spinner>
        </div>
      </button>
    </div>
  </div>
  <!-- <div class="col-12 text-center mb-3">
    <mat-hint>o Bien</mat-hint>
  </div>
  <div class="col-12">
    <div class="col-12">
      <button color="white" class="button-lg mb-3 w100" (click)="iniciar('google')" mat-raised-button> 
        <img appLazyLoad loading="lazy" class="iconGoogle" src="https://hiperabasto.com/files/icons/googleIcon.png" >
        Continuar con Google</button>
    </div>
  </div> -->

  <!--<p>¿No tenes cuenta? <a (click)="register()" class="register" >Crea una</a></p>-->
</form>
<div class="col-12 mb-3">
  <mat-divider></mat-divider>
</div>
<div class="row">
  <div class="col-12" style="text-align: center;">
    <h5>¿No tienes cuenta?</h5>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="col-12" style="text-align: center;">
      <button mat-raised-button color="accent" class="button-lg mb-3 w80 btn-nvaCuenta" (click)="register()">Crear una</button>
    </div>
  </div>
</div>