import { Component, EventEmitter, forwardRef, Inject, Output } from "@angular/core";
import { BaseWidget, NgAisIndex, NgAisInstantSearch } from "angular-instantsearch";
import { connectAutocomplete } from "instantsearch.js/es/connectors";
import { Router } from '@angular/router';

  
  @Component({
    selector: "app-autocomplete",
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.css'],
  })
  export class AutocompleteComponent extends BaseWidget {
    parentIndex?: NgAisIndex;
    instantSearchInstance: NgAisInstantSearch;
    state: {
      query: string;
      refine: Function;
      indices: object[];
    };
  
    
  
    @Output() onQuerySuggestionClick = new EventEmitter<{ query : string }>();
  
    constructor(
      @Inject(forwardRef(() => NgAisInstantSearch))
      public instantSearchParent,
      private router:Router
    ) {
      super("AutocompleteComponent");
    }
  
    verProducto(id){
      this.router.navigate(['/products/id/',id])
    }

    public handleChange($event: KeyboardEvent) {
      this.state.refine(($event.target as HTMLInputElement).value);
    }
  
    public ngOnInit() {
      this.createWidget(connectAutocomplete, {});
      super.ngOnInit();
    }

    public toggleSearch() {
        document.querySelector('app-main').classList.toggle('form-open');
    }
  }