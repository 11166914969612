<div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-5">
        <h4>Mis Direcciones</h4>

      </div>
      <!--<div class="col-7 text-right">
        <button mat-raised-button type="button" (click)="resetForm()"><span>Añadir nueva </span> <mat-icon color="primary">add</mat-icon></button>

      </div>-->

    </div>

    <div class="justify-content-center mb-3 mt-3" *ngIf="domicilios.length>0">
      <mat-list role="list" class="col-12">
        <mat-list-item role="listitem" *ngFor="let domi of domicilios; let i = index" class="cp">
          <mat-card matRipple class="col mt-1" (click)="clickEnCard(i)">
            <div class="col-2">

              <mat-radio-button color="primary" [checked]="domi.last == true" [value]="i">
                {{domi.direccion+' '+domi.numero}}
              </mat-radio-button>
            </div>

          </mat-card>

        </mat-list-item>
      </mat-list>
    </div>

    <div class="justify-content-center mb-3 mt-3" *ngIf="domicilios.length == 0">
      <button mat-stroked-button (click)="agregarDirecciones()">Agregar dirección</button>
    </div>


    <!--<form [formGroup]="formDireccion" (ngSubmit)="guardarDireccion()">


      <div fxLayout="row wrap" fxLayoutAlign="none" class="col-gap">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Provincia</mat-label>
              <mat-select (selectionChange)="seleccionarProvincia($event)" formControlName="provinciaID" required
                [(ngModel)]="domicilio.provinciaID">
                <mat-option *ngFor="let provincia of provincias" [value]="provincia.ID">
                  {{provincia.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Localidad</mat-label>
              <mat-select (selectionChange)="seleccionarLocalidad($event)" formControlName="localidadID" required
                [(ngModel)]="domicilio.localidadID">
                <mat-option *ngFor="let localidad of localidades" [value]="localidad.ID">
                  {{localidad.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="none" class="col-gap" required>
        <div fxFlex.xs="100" fxFlex.sm="1000" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Calle</mat-label>
              <input matInput [(ngModel)]="domicilio.direccion" required formControlName="direccion">
            </mat-form-field>
          </div>
        </div>

        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Número calle</mat-label>
              <input class="no-buttons" matInput type="number" min="1000" max="9500" required [(ngModel)]="domicilio.numero" formControlName="numero">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="none" class="col-gap" required>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Departamento</mat-label>
              <input class="no-buttons" matInput type="number" min="1000" max="9500" [(ngModel)]="domicilio.depto" formControlName="depto">
            </mat-form-field>
          </div>
        </div>

        <div fxFlex.xs="100" fxFlex.sm="1000" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Piso</mat-label>
              <input matInput [(ngModel)]="domicilio.piso" formControlName="direccionPiso">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="none" class="col-gap" required>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;" >
              <mat-label>Codigo Postal</mat-label>
              <input class="no-buttons" matInput type="number" required min="1000" max="9500"
                [(ngModel)]="domicilio.codigoPostal" formControlName="codigoPostal">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="none" class="col-gap" required>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
          <div class="mb-2">
            <mat-form-field style="width: 100% !important;">
              <mat-label>Comentarios</mat-label>
              <textarea matInput [(ngModel)]="domicilio.comentarios" formControlName="comentarios"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="none" class="col-gap" required *ngIf="formDireccion.valid">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">
          <app-geocodingMap 
          [modalMode]="false" 
          (coordenadas)="cambiarCoordenadas($event)"
          [address] = "domicilioCompleto"
          [latInicial]="domicilio.latitud" 
          [lngInicial]="domicilio.longitud">
        </app-geocodingMap>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">
          <mat-hint>Para mover el indiciador en el mapa, deberá mantener apretado el botón izquierdo del ratón sobre el indicador.</mat-hint>
        </div>
      </div>

    </form>-->
    <!--<div mat-dialog-actions class="row justify-content-center" >
      <button mat-raised-button (click)="onNoClick()" style="font-size:20px;" class="mr5">Salir</button>
      <button mat-raised-button type="submit" *ngIf="!_showButtonConfirmar" [disabled]="!formDireccion.valid" style="font-size:20px;" class="mr5" color="accent" (click)="guardarDireccion()">Guardar
      </button>
      <button mat-raised-button type="button" *ngIf="_showButtonConfirmar" [disabled]="!formDireccion.valid" style="font-size:20px;" class="mr5" color="accent" (click)="confirmarDireccion()">Confirmar
      </button>
      <button #closeModal hidden></button>
    </div>
  </div>-->
</div>