import {  ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GeocodeService } from '@app/_services/geocode.service';

interface Coord{
    lat:number
    lng:number
}

@Component({
    selector: 'app-geocodingMap',
    templateUrl: './geocodingMap.component.html',
    styleUrls: ['./geocodingMap.component.css'],
    providers: [GeocodeService],
})

export class GeocodingMapComponent{
    @Input() address ;
    @Input() latInicial
    @Input() lngInicial
    @Input() modalMode = true
    @Input() draggable = true
    cantVistas = 0
    @Output() coordenadas = new EventEmitter<Coord>();
    location;
    loading: boolean;
    private mapa : google.maps.Map | undefined
    private coord: Coord | undefined

    constructor(
        private geocodeService: GeocodeService,
        private ref: ChangeDetectorRef,
        public dialogRef: MatDialogRef<GeocodingMapComponent>,
    ) {

    }

    ngAfterViewInit(): void {
        console.log(this.address)
        let loader = this.geocodeService.getLoader()
        loader.load().then(()=>{
            console.log('map loaded')
            this.mapa = new  google.maps.Map(document.getElementById('mapGeocode') as HTMLElement,{
                zoom: 15
            })
            if(typeof this.address === 'string'){
                this.geocodeService.direccionToCoordenadas({address:this.address})?.then(res=>{
                    console.log(res)
                    this.coord = {lat:res.results[0].geometry.location.lat(),lng:res.results[0].geometry.location.lng()}
                    if(this.mapa)
                        this.putMarkerOnMap(this.mapa,this.coord)
                    console.log(this.coord)
                })
            }else{
                this.putMarkerOnMap(this.mapa,{lat:this.latInicial,lng:this.lngInicial})
            }
        }) ; 
    }

    private putMarkerOnMap(mapa:google.maps.Map,coord:Coord){
        console.log(coord)
        mapa.setCenter(coord)
        new google.maps.Marker({
            map:mapa,
            position: coord,
            draggable: true
        }).addListener('dragend',($event:any)=>this.mapDragEnd($event))
        this.coordenadas.emit(coord)
    }

    mapDragEnd(event:any){
        console.log(event.latLng)
        this.coord = {lat:event.latLng.lat(),lng:event.latLng.lng()}
        console.log(this.coord)
        if(!this.modalMode){
            this.coordenadas.emit(this.coord)
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.address && changes.address.currentValue){
            if(typeof this.address === 'string'){
                this.geocodeService.direccionToCoordenadas({address:this.address})?.then(res=>{
                    console.log(res)
                    this.coord = {lat:res.results[0].geometry.location.lat(),lng:res.results[0].geometry.location.lng()}
                    if(this.mapa)
                        this.putMarkerOnMap(this.mapa,this.coord)
                    console.log(this.coord)
                })
            }else{
                this.putMarkerOnMap(this.mapa,{lat:this.latInicial,lng:this.lngInicial})
            }
        }
    }

    /*showLocation() {
        if(!this.latInicial && !this.lngInicial){
            this.addressToCoordinates();
        }else{
            this.cantVistas++
            if(this.cantVistas>1){
                this.addressToCoordinates()
            }
        }
    }*/

    /*addressToCoordinates() {
        this.loading = true;
        this.geocodeService.geocodeAddress(this.address)
            .subscribe((location: Location) => {
                this.location = location;
                console.log(location)
                if(this.cantVistas > 1 ){
                    this.lngInicial = this.location.lng
                    this.latInicial = this.location.lat
                }
                this.loading = false;
                if(!this.modalMode){
                    this.coordenadas.emit(this.location)
                }
                this.ref.detectChanges();
            }
            );
    }*/

    /*markerDragEnd($event: MouseEvent) {
        console.log($event['latLng']);
        this.location = {
            lat: $event['latLng'].lat(),
            lng: $event['latLng'].lng()
        }
        console.log(this.location)
        if(!this.modalMode){
            this.coordenadas.emit(this.location)
        }
    }*/

    confirmar(){
        this.dialogRef.close(this.coord)    
    }
}
