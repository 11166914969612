import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { EmbryoService,PreguntasService } from '@services'


@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.css'],
  providers: [PreguntasService]
})
export class PreguntasAdminComponent implements OnInit {

  constructor(public preguntasService:PreguntasService,
              public embryoService: EmbryoService) { }
  preguntasGrid : any
  preguntasRespGrid: any
  public paginaActual=1
  public cantItems
  public cantItemsResp
  public paginaActualResp = 1
  public pestañaActual=0
  private localStorageService = inject(LocalStorageService)
  @ViewChild(MatPaginator,{static:false}) paginator : MatPaginator;
  @ViewChild(MatSort,{static:false}) sort           : MatSort;
  ngOnInit() {
    this.getPreguntasPendientes()
  }

  public async getPreguntasPendientes(){
    let result = await this.preguntasService.getPreguntas(Number(this.localStorageService.getVendedorInfo().ID),this.paginaActual,'ACTIVO').toPromise()
    console.log(result)
    if(result['code']==200){
      this.cantItems = result['data']['count']
      this.preguntasGrid = result['data']['rows']
    }
  }

  public async getPregunstasRespondidas(){
    let result = await this.preguntasService.getPreguntas(this.localStorageService.getVendedorInfo().ID,this.paginaActualResp,'RESPONDIDA').toPromise()
    console.log(result);
    if(result['code']==200){
      this.cantItemsResp = result['data']['count']
      this.preguntasRespGrid = result['data']['rows']
    }
  }

  public async responder(index, id,respuesta){
    let compradorId= this.preguntasGrid[index].compradorID
    let pregunta = this.preguntasGrid[index].pregunta
    let pubID = this.preguntasGrid[index].publicacion.ID
    //console.log(this.preguntasGrid[index])
    let result = await this.preguntasService.patchRespuesta(id,respuesta,pregunta,compradorId,pubID).toPromise()
    console.log(result)
    if(result['code']==201){
      this.preguntasGrid.splice(index,1)
      this.cantItems--
      this.embryoService.restarPreguntas()
      //MenuListItemsComponent.reloadCantidad()
      //console.log(this.embryoService.getCantidadPreguntas())
    }
  }

  public async borrarPregunta(index,id){
    let res = await this.embryoService.deleteDialog('¿Esta seguro de lo que esta haciendo?','¿Desea eliminar la pregunta?').toPromise()
    if(res == 'yes'){
      let result = await this.preguntasService.patchEstado(id,'BAJA').toPromise()
      console.log(result)
      if(result['code']==201){
        this.preguntasGrid.splice(index,1)
        this.cantItems--
      }
    }
    
  }

  public cambioPest(event){
    console.log(event)
    this.pestañaActual = event.index
    this.getPregunstasRespondidas()
  }

  public cambiarPagina(event){
   
    if(this.pestañaActual==0){
      this.paginaActual = event
      this.getPreguntasPendientes()
    }else{
      this.paginaActualResp =event
      this.getPregunstasRespondidas()
    }
    
  }


}
