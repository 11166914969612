// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

 export const environment = {
   production: false,
   /*algoliaId:'2JEPQWB8Y4',
   algoliaKey:'d261486f7cb4fa88769d43ff30413146',
   algoliaIndex:'dev_hiper',
   host: 'https://repo.ifelse.com.ar:5000/api',
   socket:'https://repo.ifelse.com.ar:8081',
   socketChat:'http://repo.ifelse.com.ar:8001',
   hostFiles:'https://repo.ifelse.com.ar:5000/',
   google_map_key:'AIzaSyC3W5fWQpiOcwqdTxHZnKUyZQrhl4rlZe0',*/

  //---------------produccion----------------//
   algoliaId:'IMTY5KI14X',
   algoliaKey:'88be4d2b2b43991305e52ae22f63345c',
   algoliaIndex:'test_hiper',
   socketChat:'http://api.hiperabasto.com:8001',
   hostFiles:'https://api.hiperabasto.com/',
   google_map_key:'AIzaSyC3W5fWQpiOcwqdTxHZnKUyZQrhl4rlZe0',
   host: 'https://api.hiperabasto.com/api',
   socket:'https://api.hiperabasto.com:8081'
   
 }