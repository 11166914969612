import { Injectable } from 'node_modules/@angular/core';
import { Injectable as Injectable_1 } from "@angular/core";
@Injectable_1()
@Injectable()
export class TarjetasServiceCardsDetected {


  constructor(
  ) { }

  public detectedCard(number:string){
      console.log(number)
    if(number.length >= 2){
        number = number.substring(0,2);

        if(number.substring(0,1) == '4'){
            return 'VISA';
        }
    
        if(number.substring(0,1) == '5' && (number.substring(1,2) == '1' || number.substring(1,2) == '2' || number.substring(1,2) == '3' || number.substring(1,2) == '4'|| number.substring(1,2) == '5' )){
            return 'MASTERCARD';
        }
    
        if(number.substring(0,1) == '3' && (number.substring(1,2) == '4' || number.substring(1,2) == '7')){
            return 'AMERICAN EXPRESS';
        }
    }else{
        if(number.substring(0,1) == '4'){
            return 'VISA';
        }
    }


    console.log("no entro en ninguna")
  }
}
