import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { LocalStorageRefService } from "./local-storage-ref.service";
import { isPlatformBrowser } from "@angular/common";
import { CompradorInfo, DomicilioInfo, LocalStorageData, UserInfo, VendedorInfo } from "@app/_interfaces/local-storage-data.interface";
import { CarritoCatalogo } from "@app/_models/carritoCatalogo";
import { Carrito } from "@app/_models";

@Injectable({
    providedIn:'root'
})

export class LocalStorageService{
    private localStorage : Storage
    private $data = new BehaviorSubject<LocalStorageData | null>(null)
    private $dataUser = new BehaviorSubject<UserInfo | null>(null)
    public localStorageObservable: Observable<LocalStorageData | null> = this.$data.asObservable()
    public userInfoObservable: Observable<UserInfo> = this.$dataUser.asObservable()

    constructor( 
        private localStorageRef : LocalStorageRefService,
        @Inject(PLATFORM_ID) private platformId: Object
    ){
        this.localStorage = localStorageRef.localStorage
        this.$dataUser.next(JSON.parse(this.localStorage.getItem('userInfo')))
    }

    /**
     * setea la info de usuario en localStorage
     * @param userInfo
     */
    public setUserInfo(userInfo:UserInfo){
        const jsonData = JSON.stringify(userInfo)
        this.localStorage.setItem('userInfo',jsonData)
        this.$dataUser.next(userInfo)
    }

    public getUserInfo(){
        let userInfo:UserInfo = JSON.parse(this.localStorage.getItem('userInfo'))
        return userInfo ||
        {
            pw:null,
            ID:null,
            nombre:null,
            telefono:null,
            user:null,
            genero:null,
            foto:null,
            estado:null,
            email:null
        }
    }

    public setCarritosCatalogo(carritos:Array<CarritoCatalogo>){
        const jsonData = JSON.stringify(carritos)
        this.localStorage.setItem('carritosCatalogo',jsonData)
    }

    /**
     * 
     * @returns carritoCatalogo si existe, null en otro caso
     */
    public getCarritosCatalogo():Array<CarritoCatalogo>{
        let carts:Array<CarritoCatalogo> | null = JSON.parse(this.localStorage.getItem('carritosCatalogo'))
        if(carts){
            return carts//carts.map(c=>new CarritoCatalogo(c.compradorID,c.vendedorID,c.total,c.publicaciones))
        }else{
            return []
        }
    }

    public getCompradorInfo(){
        let compradorInfo:CompradorInfo = JSON.parse(this.localStorage.getItem('compradorInfo'))
        return compradorInfo ||  
        {
            telefono:null,
            ID:null,
            DNI:null,
            puntos:null
        }
    }

    public setCompradorInfo(compradorInfo:CompradorInfo){
        const jsonData = JSON.stringify(compradorInfo)
        this.localStorage.setItem('compradorInfo',jsonData)
    }

    public setVendedorInfo(vendedorInfo:VendedorInfo){
        const jsonData = JSON.stringify(vendedorInfo)
        this.localStorage.setItem('vendedorInfo',jsonData)
    }

    public getVendedorInfo(){
        let vendedorInfo : VendedorInfo = JSON.parse(this.localStorage.getItem('vendedorInfo'))
        return vendedorInfo || 
        {
            ID:null,
            cuit:null,
            empresa:null,
            email:null,
            nSucursales:null,
            snMobbex:null,
            telefono:null,
            tipo:null
        }
    }

    public setDomicilioInfo(domicilioInfo:DomicilioInfo){
        const jsonData = JSON.stringify(domicilioInfo)
        this.localStorage.setItem('domicilioInfo',jsonData)
    }

    public getDomicilioInfo(){
        let domicilioInfo : DomicilioInfo = JSON.parse(this.localStorage.getItem('domicilioInfo'))
        return domicilioInfo
    }

    /**
     * busca entre los carritos almacenados en localStorage uno que coincida con el vendedorID del carrito 
     * que intentamos ingresar.
     * si lo encuentra y la cantidad de publicaciones no es 0 remplaza el carrito que estaba por el nuevo,
     * si lo encuentra pero no tiene publicaciones lo elimina 
     * si no lo encuentra agrega el carrito al arreglo de carritos almacenado en localStorage
     * @param carrito el carrito a agregar
     */
    public setCarrito(carrito:Carrito){
        console.log(carrito)
        let carritos:Carrito[] = JSON.parse(this.localStorage.getItem('carritos')) || []
        let carritoIndex = carritos.findIndex(c=>c.vendedorID === carrito.vendedorID)
        if(carrito.publicaciones.length==0){
            carritos.splice(carritoIndex,1)
        }else{
            if(carritoIndex!==-1){
                carritos[carritoIndex]= carrito
            }else{
                carritos.push(carrito)
            }
        }
        const jsonData = JSON.stringify(carritos)
        this.localStorage.setItem('carritos',jsonData)
    }

    public deleteCarrito(vendedorID:number){
        let carritos:Carrito[] = JSON.parse(this.localStorage.getItem('carritos'))
        let carritoIndex = carritos.findIndex(c=>c.vendedorID === vendedorID)
        carritos.splice(carritoIndex,1)
        const jsonData = JSON.stringify(carritos)
        this.localStorage.setItem('carritos',jsonData)
    }

    public getCarritos():Carrito[]{
        let carritos:Carrito[] = JSON.parse(this.localStorage.getItem('carritos')) || [] 
        return carritos.map(c=>Carrito.Crear(c))
    }

    public getCarritoVendedor(vendedorID:number):Carrito{
        let carritos: Carrito[] = JSON.parse(this.localStorage.getItem('carritos')) || []
        let carritoVendedor: Carrito
        if(carritos.length){
            carritoVendedor = carritos.find(c=>c.vendedor.ID==vendedorID)
            if(carritoVendedor){
                let carrito = Carrito.Crear(carritoVendedor)
                return carrito
            }else{
                return null
            }
        }else{
            return null
        }
    }

    /**
     * cambia la info de localStorage por la info que recibe por parametro
     * @param info 
     */
    public setInfo(info:LocalStorageData){
        if(isPlatformBrowser(this.platformId)){
            const jsonData = JSON.stringify(info)
            this.localStorage.setItem('data',jsonData)
            this.$data.next(info)
        }
    }

    /**
     * Carga en un json el contenido del localstorage y lo emite a través del observable
     */
    public loadInfo(){
        if(isPlatformBrowser(this.platformId)){
            const info = JSON.parse(this.localStorage.getItem('data') || '{}') 
            this.$data.next(info)
        }
    }

    public clearInfo(){
        if(isPlatformBrowser(this.platformId)){
            this.localStorage.removeItem('data')
            this.$data.next(null)
        }

    }

    clearAllLocalStorage(){
        if(isPlatformBrowser(this.platformId)){
            this.localStorage.clear() 
            this.$data.next(null) 
        }
    }
}