import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-descuentos',
  templateUrl: './descuentos.component.html',
  styleUrls: ['./descuentos.component.css']
})
export class DescuentosComponent implements OnInit {
  @Input() precios = []
  @Input() unidadMedida
  constructor(public dialogRef: MatDialogRef<DescuentosComponent>) { }

  ngOnInit() {
    
  }

}
