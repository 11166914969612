import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable } from '@angular/core';
import { Domicilio } from '@models';
import { map } from "rxjs/operators"; //mapeo de respuesta de base de datos.
import { AppSettings } from '../app.settings'; //modulo global de direcciones.
@Injectable()
export class DomiciliosService {
  host = this.appSettings.urls['host'] // variable global con la ip de la api.
  recurso_domicilios = this.appSettings.urls['domicilios']
  recurso_personas = this.appSettings.urls['personas']
  constructor(
    private http: HttpClient,
    public appSettings: AppSettings,
  ) { }

  getPersonaID(idPersona: number) {
    return this.http.get(`${this.host + this.recurso_domicilios}/personaID/${idPersona}`).pipe(map(res => res));
  }

  getID(idDomicilio: Number) {
    return this.http.get(`${this.host + this.recurso_domicilios}/id/${idDomicilio}`).pipe(map(res => res));
  }

  getPublicacionVendedor(idPublicacion, idUsuario) {
    return this.http.get(`${this.host + this.recurso_domicilios}/relacionados/id/${idPublicacion}/vendedorID/${idUsuario}`).pipe(map(res => res));
  }

  post(domicilio: Domicilio) {
    let json = JSON.stringify(domicilio);
    json = JSON.parse(json);
    return this.http.post(`${this.host + this.recurso_domicilios}`, json).pipe(map(res => res))
  }

  put(domicilio: Domicilio) {
    let json = JSON.stringify(domicilio);
    json = JSON.parse(json);
    return this.http.put(`${this.host + this.recurso_domicilios}/id/${domicilio.ID}`, json).pipe(map(res => res))
  }

  patchEstado(estado: string, idDomicilio: number) {
    let json = { 'estado': estado }
    return this.http.patch(`${this.host + this.recurso_domicilios}/estado/id/${idDomicilio}`, json).pipe(map(res => res))
  }

  patchLast(idPersona: number, idDomicilio: number) {
    console.log("host", this.host + this.recurso_domicilios+"/last/id/" + idDomicilio +"/personaID/"+idPersona)
    return this.http.patch(`${this.host + this.recurso_domicilios}/last/id/${idDomicilio}/personaID/${idPersona}`,{}).pipe(map(res => res))
  }
}
