import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EmbryoService } from '@services';
import { Router } from '@angular/router';

@Component({
  selector: 'embryo-FixedHeader',
  templateUrl: './FixedHeader.component.html',
  styleUrls: ['./FixedHeader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixedHeaderComponent implements OnInit {
enProductos
  constructor(private embryoService : EmbryoService,
              private router: Router,
              private cdr : ChangeDetectorRef
              ) {
                router.events.subscribe(ev=>{
                  this.enProductos = router.isActive('/products',false)
                })
               }

  ngOnInit() {
  }

  public toggleSidebar()
   {
      this.embryoService.sidenavOpen = !this.embryoService.sidenavOpen;
   }

   public buscar(busqueda:string){
    console.log(busqueda)
    this.router.navigateByUrl("/products?query="+encodeURIComponent(busqueda)).then(f=>{
      if(this.enProductos){
        window.location.reload()
      }
    })
  }

  public detectChanges(){
    
  }
}
