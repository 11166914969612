<!--*ngIf="(mobWidth >= mobScreenSize) else elseButtonBlock" quito eso de ng-container para que el diseño responsive sea como el de pc y no tengamos problema 404 GIANNI-->
<ng-container>
   <!--ICONO NOTIFICACION -->
   <button class="btn-outline-none" mat-mini-fab (click)="verNotificaciones()"
      [matMenuTriggerFor]="viewNotificationsmenu" [matBadgeHidden]="hiddenBadge" [matBadge]="count" matBadgePosition="after" matBadgeColor="accent">
      <i class="material-icons notranslate">
         notifications
      </i>
   </button>
   <!--FIN | ICONO NOTIFICACION -->
   <!--MENU DESPLEGABLE NOTIFICACION -->
   <mat-menu class="menu-container" #viewNotificationsmenu="matMenu" (menuOpened)="verNotificaciones()"
      xPosition="before">

      <!--SI TIENE NOTIFICACION-->
      <div class="header-container">
         <h4 class="header-title">Notificaciones</h4>
         <button mat-icon-button class="btn-outline-none" (click)="$event.stopPropagation(); marcarTodasVistas()">
            <mat-icon>
               clear_all
            </mat-icon>
         </button>
      </div>
      <ng-container *ngIf="loading">
         <div class="spinner-container">
            <mat-spinner></mat-spinner>
         </div>
      </ng-container>
      <mat-divider></mat-divider>
      <ng-container *ngIf="!loading">
         
         
         <ng-container  *ngIf="(notifications && notifications.length > 0); else elseBlock">
            <div infiniteScroll [infiniteScrollDistance]="2"  (scrolled)="onScroll()" [scrollWindow]="false" class="drop-wrap">
               <div fxFlex="row wrap" class="cart-menu-list p-2" fxLayoutAlign="start center"
                  *ngFor="let notification of notifications;">

                  <!--IMAGEN DE LA NOTIFICACION-->
                  <!--<div fxFlex.xs="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.lg="25" fxFlex.xl="25"> 
                    <a href="javascript:void(0);">
                        <img appLazyLoad loading="lazy" [src]="appSettings.urls.hostFiles+product?.imgPortada+appSettings.imgXS" width="60" alt="cart-image">
                    </a> 
                </div>-->
                  <!--FIN | IMAGEN DE LA NOTIFICACION-->

                  <!--TITULO DE LA NOTIFICACION-->
                  <div fxFlex.xs="75" fxFlex.sm="75" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100" class="px-2">
                     <a (click)="navegar(notification)">
                        <h6 class="mb-0">
                           {{notification.titulo}}
                        </h6>
                        <p>
                           {{notification.descripcion}}
                        </p>
                     </a>
                  </div>
                  <!--FIN | TITULO DE LA NOTIFICACION-->

                  <!--BOTONES DE ACCION SOBRE LA NOTIFICACION-->
                  <div fxFlex.xs="35" fxFlex.sm="35" fxFlex.md="35" fxFlex.lg="35" fxFlex.xl="35"
                     class="cart-menu-action menu-action">
                     <button type="button" mat-mini-fab color="primary"
                        (click)="$event.stopPropagation(); marcarVista(notification)">
                        <i class="material-icons">check</i>
                     </button>
                  </div>
                  <!--FIN | BOTONES DE ACCION SOBRE LA NOTIFICACION-->
               </div>
            </div>
         </ng-container>
         <ng-container *ngIf="loadingMore">
            <div class="d-flex justify-content-center">
               <mat-spinner [diameter]="20"></mat-spinner>
            </div>
         </ng-container>
         <!--FIN | SI TIENE NOTIFICACION-->
         <!--SI NO TIENE NOTIFICACION-->
         <ng-template #elseBlock>
            <div class="cart-menu-list p-2" fxLayoutAlign="center center">
               Estas al dia!
            </div>
         </ng-template>
         <!--FIN | SI NO TIENE NOTIFICACION-->
      </ng-container>
   </mat-menu>
   <!--FIN | MENU DESPLEGABLE NOTIFICACION -->
</ng-container>

<!--EN CASO DE SER TELEFONO -->
<!--<ng-template #elseButtonBlock>
    <button mat-mini-fab [matBadgeHidden]="hiddenBadge" matBadge="{{count}}" matBadgePosition="after" matBadgeColor="accent" [routerLink]="['/cart']">
       <i class="material-icons notranslate">
        notifications
       </i>
   </button>
 </ng-template>-->
<!--FIN | EN CASO DE SER TELEFONO -->