<div class="product-detail-page section-gap bg-white">
    <div class="container">
            <h3 class="mb-4">Preguntas y respuestas</h3>
            <div [formGroup]="formPregunta" class="row col-12" *ngIf="(loginStatus | async) && !isVendedor">
                <mat-form-field appearance="outline" class="col-9">
                    <mat-label>Escribir una pregunta</mat-label>
                    <textarea matInput formControlName="pregunta"></textarea>
                </mat-form-field>
                <mat-error *ngIf="formPregunta.get('pregunta').touched && formPregunta.get('pregunta').hasError('minlength')">La pregunta debe contener al menos 10 caracteres</mat-error>
                <mat-error *ngIf="formPregunta.get('pregunta').touched && formPregunta.get('pregunta').hasError('maxlength')">La pregunta debe contener menos de 1000 caracteres</mat-error>
                <div class="col-3 a-s-center">
                    <button mat-flat-button color="primary" (click)="enviarPregunta()">Preguntar</button>
                </div>
            </div>
            <div class="row mb-4 iniciar-sesion" *ngIf="!(loginStatus | async) && !isVendedor">
                <div class="col-12 mb-2">
                    <span>Inicie sesión para preguntar al vendedor</span>
                </div>
                <div class="col-12">
                    <button mat-raised-button class="button-lg" color="accent" (click)="iniciarSesion()">iniciar sesion</button>
                </div>
            </div> 
            <div class="row col-12">
                <h4>Ultimas realizadas</h4>
                <div class="row col-12 justify-content-center" *ngIf="cargandoPreguntas">
                    <mat-spinner></mat-spinner>
                </div>
                <ng-container *ngIf="preguntasRespuestas.length>0 else no_preguntas">
                    <div fxLayout="column" fxFlex="100" class="mb-3" *ngFor="let prgRta of preguntasRespuestas | paginate:{itemsPerPage: 12, currentPage: paginaActual,totalItems: cantidadTotal }; trackBy: trackById;">
                        <div class="pregunta-container">
                            <span class="material-icons mr-2">
                                chat
                            </span>
                            <span>{{prgRta.pregunta}}</span>
                        </div>
                        <div *ngIf="prgRta.respuesta" class="pregunta-container">
                            <span class="material-icons mr-2">
                                comment
                            </span>
                            <span class="respuesta">{{prgRta.respuesta}} {{prgRta.createdAt}}</span>
                        </div>
                    </div>
                    <div class="col-12 row justify-content-center">
                        <pagination-controls (pageChange)="cambiarPagina($event)"
                        previousLabel="Anterior"
                        nextLabel="Siguiente"
                        autoHide="true"
                        responsive="true"
                        maxSize="15"
                        ></pagination-controls>
                    </div>
                </ng-container>
                <ng-template #no_preguntas>
                    <div class="col-12 no-preguntas">
                        <span>aun no se han realizado preguntas en esta publicación.</span>
                    </div>
                </ng-template>
            </div>
    </div>
</div>

