import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { DatosPrecompraInterface } from "@app/_interfaces/datos-bancarios.interface";
import { OpinionCarrito } from "@app/_interfaces/opinion-carrito.interface";
import { VendedorDetalleResponse } from "@app/_interfaces/vendedor-detalle.interface";
import { VendedorListado, VendedoresListadoResponse } from "@app/_interfaces/vendedores-listado.interface";
import { environment } from "@environments/environment";
import { catchError, map, take } from "rxjs/operators";

@Injectable()
export class VendedoresService {
  private http = inject(HttpClient);
  private url = environment.host;

  public getVendedoresCatalogo(page: number, limit: number, search?: string) {
    if (search) {
      let body = { like: search };
      return this.http.post<VendedoresListadoResponse>(
        `${this.url}/personas/vendedores/search/page/${page}/limit/${limit}`,
        body
      );
    } else {
      return this.http.get<VendedoresListadoResponse>(
        `${this.url}/personas/vendedores/page/${page}/limit/${limit}?tipo=CATALOGO`
      );
    }
  }

  /**
   * getTiendas get utilizado para cargar el listado de tiendas
   */
  public getTiendas(page: number, limit: number) {
    return this.http
      .get<VendedoresListadoResponse>(`${this.url}/personas/vendedores/page/${page}/limit/${limit}`)
  }

  public getTiendasEnvioGratis(page:number,limit:number,personaID:number = 0){
    if(personaID){
      //obtiene los vendedores con envio gratis en las provincias donde la persona tiene domicilios cargados
      return this.http.get<{code:number,data:Array<VendedorListado>}>(`${this.url}/personas/vendedores/envio-gratis/personaID/${personaID}/p/${page}/l/${limit}`)
    }else{
      return this.http.get<{code:number,data:Array<VendedorListado>}>(`${this.url}/personas/vendedores/envio-gratis/p/${page}/l/${limit}`)
    }
  }

  

  /**
   * searchTiendas
   */
  public searchTiendas(like: string, page: number, limit: number) {
    return this.http
      .post<VendedoresListadoResponse>(
        `${this.url}/personas/vendedores/search/page/${page}/limit/${limit}`,
        { like: like }
      )
  }

  public getVendedorDetalles(vendedorID:number){
    return this.http.get<VendedorDetalleResponse>(`${this.url}/personas/vendedores/id/${vendedorID}`)
  }

  public getEnlaceWhatsapp(vendedorID:number,mensaje:string){
    const parceMensaje = encodeURIComponent(mensaje)
    return this.http.get<VendedorDetalleResponse>(`${this.url}/personas/vendedores/id/${vendedorID}`).pipe(
      take(1),
      map(res=>{
        if(res.code==200){
          let cleanedPhone = res.data.telefono.replace(/\D/g, '')
          return `https://wa.me/${cleanedPhone}?text=${parceMensaje}`
        }else{
          return ''
        }
      }),
      catchError(()=>'')
    )
  }

  /**
   * 
   * @param vendedorID 
   * @param rango 'TODAS' | 'BAJAS' | 'MEDIAS' | 'ALTAS'
   * @param page 
   * @param limit 
   */
  public getOpinionesVendedor(vendedorID:number,rango:string,page:number,limit:number){
    return this.http.get<{code:number,data:{count:number,rows:Array<OpinionCarrito>}}>(`${this.url}/carritos/opiniones/vendedorID/${vendedorID}/rango/${rango}/p/${page}/l/${limit}`)
  }

  /**
   * Devuelve los datos bancarios cargados por el vendedor para el 
   * @param vendedorID 
   * @returns 
   */
  public getDatosPrecompra(vendedorID:number){
    return this.http.get<{code:number,data:DatosPrecompraInterface}>(`${this.url}/personas/vendedores/datos-bancarios/id/${vendedorID}`)
  }

  /**
   * Setear el valor a partir del que el vendedor acepta la modalidad precompra
   * @param vendedorID 
   * @param monto monto a partir del que se acepta la precompra
   */
  public patchMontoPrecompra(vendedorID:number,monto:number){
    return this.http.patch<{code:number}>(`${this.url}/personas/vendedores/montoPrecompra/id/${vendedorID}`,{montoPrecompra:monto})
  }

  public patchSnPrecompra(vendedorID:number,snPrecompra:boolean){
    return this.http.patch<{code:number}>(`${this.url}/personas/vendedores/snPrecompra/id/${vendedorID}`,{snPrecompra})
  }
  /**
   * Setea los datos bancarios de para la precompra en el vendedor
   * @param vendedorID 
   * @param datos datos bancarios a setear
   * @returns 
   */
  public putDatosPrecompra(vendedorID:number, datos:DatosPrecompraInterface){
    return this.http.put<{code:number}>(`${this.url}/personas/vendedores/datos-bancarios/id/${vendedorID}`,datos)
  }
}
