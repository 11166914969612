import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, OnChanges, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppSettings } from '@settings';
import { CarritoService, EmbryoService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { Carrito, CarritoPublicacion } from '@app/_models';
import { ComisionesService } from '@app/_services/comisiones.service';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { VendedorDetalle } from '@app/_interfaces/vendedor-detalle.interface';
import { VendedoresService } from '@app/_services/vendedores.service';
import { FormControl } from '@angular/forms';
//import { MobbexComponent } from '../Mobbex/Mobbex.component';

@Component({
   selector: 'embryo-Cart',
   templateUrl: './Cart.component.html',
   styleUrls: ['./Cart.component.scss'],
   providers: [AppSettings,ComisionesService,VendedoresService]
})
export class CartComponent implements OnInit {

   carrito:Observable<Carrito>
   popupResponse: any;
   vendedorID;
   private comisionesService = inject(ComisionesService)
   private carritosService = inject(CarritoService)
   private vendedoresService = inject(VendedoresService)
   private $onDestroy = new Subject<void>()
   protected comisiones: Array<{nombre:string,valor:number,porcentaje:number,snPrecompra:boolean,snCompra:boolean}> = []
   protected modoCompra = new FormControl('compra')
   protected $vendedor: Observable<VendedorDetalle> = this._route.params.pipe(
      switchMap(res=>this.vendedoresService.getVendedorDetalles(res.vendedorID)),
      map(res=>{
         if(!res.data.snMobbex){
            this.modoCompra.patchValue('precompra')
         }
         return res.data
      })
   )
   constructor(public embryoService: EmbryoService,
      private router: Router,
      public _route: ActivatedRoute,
      public appSettings: AppSettings,
      public dialog: MatDialog) {
         
   }

   ngOnInit() {
      this._route.params.pipe(takeUntil(this.$onDestroy)).subscribe(
         (params: Params) => {
            this.vendedorID = Number.parseInt(params.vendedorID);
            this.carrito = this.carritosService.carritosObservable.pipe(
               map(r=>r.find(c=>c.vendedor.ID==this.vendedorID)),
               takeUntil(this.$onDestroy)
            )
            this.carrito.subscribe({
               next: carrito=>{
                  this.calcularComisiones(carrito)
               }
            })
         }
      )
   }

   private calcularComisiones(carrito:Carrito){
      if(carrito){
         console.log(carrito)
         this.comisiones = []
         this.comisionesService.getComisiones().pipe(take(1)).subscribe(res=>{
            if(res.code == 200){
               res.data.forEach(c=>{
                  let comision = {
                     nombre:c.titulo,
                     valor: carrito?.total * (c.porcentaje/100),
                     valorPrecompra: c.ID==3? carrito.getMontoComisionPagos360() : carrito?.subtotal * (c.porcentaje/100),
                     porcentaje:c.porcentaje,
                     snPrecompra: c.ID==3 || c.ID ==2, //las comisiones de precompra son hiperabasto y pagos 360
                     snCompra: c.ID==1 || c.ID==2 //las comisiones de compra son hiperabasto y mobbex
                  }
                  this.comisiones.push(comision)
               })
            }
         })
      }
   }
   

   volverTienda(carrito:Carrito){
      this.router.navigate(['products/tienda/',carrito.vendedor.empresa,carrito.vendedor.ID])
   }


   public removeProduct(value:CarritoPublicacion) {
      let message = "¿Estas seguro que quieres eliminar este producto?";
      this.embryoService.confirmationPopup(message).
         subscribe(res => { this.popupResponse = res },
            err => console.log(err),
            () => this.getPopupResponse(this.popupResponse, value)
         );
   }

   public getPopupResponse(response, value:CarritoPublicacion) {
      if(response){
         console.log(value)
         this.carritosService.deletePublicacion(value.publicacionID)
      }
      //SI DECIDE BORRAR LA PUBLICACION
      /*if (response) {
         this.embryoService.removeLocalCartProduct(value);
         //LIMPIEZA DE VARIABLE LOCAL CON LAS PUBLICACIONES 
         this.carrito.publicaciones.forEach((pub, index) => {
            //ENCUENTRO LA PUBLICACION QUE SE ELIMINO
            if (pub.ID == value.ID) {
               // SI ES LA UNICA QUE HAY, SIMPLEMENTE ELIMINO TODO EL ARREGLO.
               if (this.carrito.publicaciones.length == 1) {
                  this.carrito.publicaciones.length = 0;
               } else {
                  // SINO, HAGO UN SPLICE PARA ESE ELEMENTO DEL ARREGLO.
                  this.carrito.publicaciones.splice(index, 1);
               }
            }
         })
      }*/
   }

   public calculateProductSinglePrice(product: any, value: any) {
      //console.log(product)
      let price = 0;
      product.cantidadMinima = Number(value);
      if (product.descuento) {
         price = ((product.precio * product.cantidad) - ((product.precio * product.cantidad) * (product.descuento / 100)))
      } else {
         price = product.precio * value
      }
      return price;
   }

   /*public calculateTotalPrice() {
      let subtotal = 0;
      if (this.carrito.publicaciones.length > 0) {
         for (let product of this.carrito.publicaciones) {
            if (product.descuento) {
               subtotal += ((product.precio * product.cantidad) - ((product.precio * product.cantidad) * (product.descuento / 100)));
            } else {
               subtotal += (product.precio * product.cantidad);
            }

         }
         return subtotal;
      }
      return subtotal;

   }*/

   /*public getTotalPrice() {
      let total = 0;
      if (this.carrito.publicaciones.length > 0) {
         for (let product of this.carrito.publicaciones) {
            total += product.subtotal;
         }
         //total += (this.embryoService.shipping+this.embryoService.tax);
         this.products.publicaciones.total = total
         return total;
      }

      return total;

   }*/

   public updateLocalCartProduct() {
      //this.embryoService.updateAllLocalCartProduct(this.embryoService.localStorageCartProducts);
      if(this.modoCompra.value=='compra'){
         this.router.navigate(['checkout/payment', this.vendedorID])
      }else{
         this.router.navigate(['checkout/confirmar-precompra',this.vendedorID])
      }
   }

   public getQuantityValue(product) {
      //console.log(product)
      if (product.cantidad) {
         return product.cantidad
      } else {
         return 1;
      }
   }

   
}
