<footer>
  <div class="footer-v1">
    <div class="container">
      <div class="footer-top">
        <ul>
          <li>
            <a href="https://youtube.com/channel/UC93kmnk6jgiZqLgkZKzvYrA" target="_blank">
              <mat-icon svgIcon="youtube"></mat-icon>
            </a>
          </li>
          <li>
            <a href="https://m.facebook.com/hiperabasto.argentina/" target="_blank">
              <mat-icon svgIcon="facebook"></mat-icon>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/hiperabasto.arg?igshid=YmMyMTA2M2Y=" target="_blank">
              <mat-icon svgIcon="instagram"></mat-icon>
            </a>
          </li>
          <li>
            <a href="https://ar.linkedin.com/in/hiper-abasto-argentina-64434922b" target="_blank">
              <mat-icon svgIcon="linkedin"></mat-icon>
            </a>
          </li>
        </ul>
      </div>
      <!--<hr>-->
      <div class="footer-bottom-v1">
          <div class="sponsors">
            <a href="http://qr.afip.gob.ar/?qr=zqHLyDIShgzV6LTQ7vyn7Q,," target="_F960AFIPInfo">
              <img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0">
            </a>
            <a href="https://www.cace.org.ar/" target="_blank"><img appLazyLoad loading="lazy" width="100" height="54" src="assets/images/powered/cace-azul.png"></a>
            <a href="https://www.mobbex.com/" target="_blank"><img appLazyLoad loading="lazy" width="100" height="54" src="assets/images/powered/mobbex-azul.png"></a>
            <a href="https://www.mailexpress.com.ar/ecommerce" target="_blank"><img appLazyLoad width="100" height="54" loading="lazy" src="assets/images/powered/mailEx.png"></a>
          </div>
          <div class="derechos">
            &copy; Todos los derechos reservados | Creado por <a target="_blank" href="http://www.ifelse.com.ar"><img appLazyLoad width="60" height="19" loading="lazy" src="../../../../../../assets/images/ico6.png"></a>
          </div>
      </div>
    </div>
  </div>
</footer>
