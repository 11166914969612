import { Component, OnInit } from '@angular/core';
import { AdminMenuItems } from '../../Core/Menu/MenuItems/MenuItems';
import { Router } from '@angular/router';
import { PreguntasService } from '@services'
import { EmbryoService} from '@services'

@Component({
	selector: 'app-menu-list-items',
	templateUrl: './MenuListItems.component.html',
	styleUrls: ['./MenuListItems.component.scss'],
})

export class MenuListItemsComponent implements OnInit {
	public cantidadPreguntas
	constructor(public menuItems: AdminMenuItems,
				private router :Router,
				public preguntasService: PreguntasService,
				public embryo : EmbryoService ) { }

	ngOnInit() {
		this.contarPreguntas()
		//console.log(this.embryo.getCantidadPreguntas())	
	}

	async contarPreguntas(){
		let result = await this.preguntasService.cantidadPreguntas().toPromise()
		if(result['code']==200){
			this.embryo.setCantidadPreguntas(result['data']['cant'])
			console.log(this.embryo.getCantidadPreguntas())
			this.reloadCantidad()
		}	
	}

	public reloadCantidad(){
		this.cantidadPreguntas = this.embryo.getCantidadPreguntas()
	}

	ruteo(menupadre,menuhijo){
		let ruta = menupadre+'/'+menuhijo;
		this.router.navigate([ruta]);
	}

}
