import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'embryo-AppLogo',
  templateUrl: './AppLogo.component.html',
  styleUrls: ['./AppLogo.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
