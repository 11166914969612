<!--<div *ngFor="let precio of precios">
    <span>llevando {{precio.cantidad}} <span *ngIf="precio.cantidad>1">{{unidadMedida.nombrePlural}}</span> <span *ngIf="precio.cantidad==1">{{unidadMedida.nombre}}</span> obtene {{precio.descuento}}% de descuento</span>
</div>-->
<!--<mat-selection-list role="listbox">
    <mat-list-option role="option" *ngFor="let precio of precios">
        <span matLine class="tituloDescuento"><span class="descuento">{{precio.descuento}}%</span> de descuento</span>
        <span matLine class="subtituloDescuento">
            comprando {{precio.cantidad}} 
            <span *ngIf="precio.cantidad>1">{{unidadMedida.nombrePlural}}</span> 
            <span *ngIf="precio.cantidad==1">{{unidadMedida.nombre}}</span> 
        </span>
    </mat-list-option>
  </mat-selection-list>-->
  
  <mat-list >
    <mat-list-item *ngFor="let precio of precios">
        <div class="row">
            <div class="col-12 col-md-6">
                <span matLine class="tituloDescuento"><span class="descuento">{{precio.descuento}}%</span> de descuento</span>
            </div>
            <div class="col-12 col-md-6">
                <span matLine class="subtituloDescuento">
                    comprando {{precio.cantidad}} 
                    <span *ngIf="precio.cantidad>1">{{unidadMedida.nombrePlural}}</span> 
                    <span *ngIf="precio.cantidad==1">{{unidadMedida.nombre}}</span> 
                </span>
            </div>
        </div>
    </mat-list-item>
  </mat-list>
