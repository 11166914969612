import { Component, Input } from "@angular/core";
import { MatDialog,  MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'info-popup',
    templateUrl: './infoPopUp.component.html',
    styleUrls: ['infoPopUp.component.scss']
})

export class InfoPopUpComponent{
    @Input() icon:string = 'help_outline'
    @Input() mensaje
    constructor(private dialog: MatDialog){

    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        console.log('pop up component')
    }

    public openModal(){
        const dialogRef = this.dialog.open(DialogMensaje,{
            panelClass:'pop-up-modal'
        })
        dialogRef.componentInstance.mensaje =  this.mensaje
    }
}

@Component({
    templateUrl:'template-dialog.html',
    styleUrls: ['template-dialog.scss']
})

export class DialogMensaje{
    constructor(private matDialogRef : MatDialogRef<DialogMensaje>){

    }

    closeModal(){
        this.matDialogRef.close()
    }
    @Input() mensaje
}