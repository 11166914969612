<div class="container-texto" *ngIf="!soloImg">
<div style="position: relative;">
        <button mat-icon-button class="cancel-button" (click)="cerrarModal()">
        <mat-icon>cancel</mat-icon>
        </button>
    </div>
<h2 mat-dialog-title class="titulo-popup">{{data.titulo}}
    <br>
    <h6 class="subtitulo">{{data.subtitulo}}</h6>
</h2>
<mat-dialog-content>
    <!--<p class="parrafo">hiperabasto es la primer plataforma que acerca al consumidor final productos a precio de
        mayorista</p>-->
    <div class="parrafo" [innerHTML]="data.pretexto"></div>
    <div class="col-12">
        <img src="{{data.img}}">
    </div>
    <!--<p class="parrafo mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores neque officiis molestiae reiciendis recusandae atque ex nostrum autem, maiores deserunt eius hic. Modi dicta molestias voluptate exercitationem quasi suscipit in.</p>-->
    <div class="parrafo mt-1" [innerHTML]="data.texto"></div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="data.url">
    <button (click)="redireccionar()" mat-raised-button>{{data.boton? data.boton : 'Aceptar'}}</button>
</mat-dialog-actions>
</div>

<div *ngIf="soloImg">
    <div style="position: relative;">
        <button mat-icon-button class="cancel-button-img" (click)="cerrarModal()">
        <mat-icon>cancel</mat-icon>
        </button>
    </div>
    <img src="{{data.img}}" alt="">
    <div style="position: relative;" *ngIf="data.url">
        <button (click)="redireccionar()" mat-raised-button class="btn-img">{{data.boton? data.boton : 'Aceptar'}}</button>
    </div>
</div>