import { Marca, Vendedor } from "@app/_interfaces/publicacion.interface";
import { Publicacion } from "./publicacion";
import { UnidadesMedida } from "@app/_interfaces/publicacion-detalle.interface";

export class PublicacionDetalle extends Publicacion{
    constructor(
        public ID:number,
        public productoID: number,
        public imgPortada: string,
        public titulo: string,
        public stock: number,
        public precio: number,
        public marca: Marca,
        public vendedor: Vendedor,
        public precios: any[],
        public descripcion,
        public unidadesMedida:UnidadesMedida,
        public dimenciones:{
            alto:number,
            largo:number,
            profundidad:number,
            peso:number,
            snFragil:boolean
        }
    ){
        super(ID,productoID,imgPortada,titulo,stock,precio,marca,vendedor,precios,dimenciones)
        this.descripcion = this.descripcion.replace(/\n/g, "<br />")
    }
}