import { PrecioProducto } from "./precioProducto"

export class CarritoPublicacion{
    public total:number
    public precioDescuento:number = 0
    constructor(
        public publicacionID:number,
        public precio:number,
        public precios:PrecioProducto[],
        public cantidad:number,
        public titulo:string,
        public imgPortada:string,
        public descuento: number,
        public tipoDescuento,
        public bultos,
        public peso,
        public dimensiones : {
            alto:number,
            largo:number,
            profundidad:number,
            peso:number,
            snFragil:boolean
        },
        public carritoID?:string,
        public ID?:number
    ){
        this.total = this.cantidad*this.precio
        this.descuento = this.precios.find(p=>p.cantidad<=this.cantidad)?.descuento
        if(descuento){
            this.precioDescuento = this.total - (this.total * (descuento/100))
        }
    }

    public getPrecioDescuento(){
        return this.precioDescuento
    }
    
    public getPrecioTotal(){
        return this.total
    }

    public static Crear(obj:CarritoPublicacion){
        return new CarritoPublicacion(
            obj.publicacionID,
            obj.precio,
            obj.precios,
            obj.cantidad,
            obj.titulo,
            obj.imgPortada,
            obj.descuento,
            obj.tipoDescuento,
            obj.bultos,
            obj.peso,
            obj.dimensiones
        )
    }
}