import { Routes } from '@angular/router';
import { CartComponent } from '@components/Cart/Cart.component';
import { PagesComponent } from '@app/Pages/pages.component';
import { NotFoundComponent } from '@components/NotFound/NotFound.component';
import { RoleGuardServiceGlobal as AuthGuard } from '@app/_helpers/';
import { NgModule } from '@angular/core';
export const routes: Routes = [
  {
    path : '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
   {
     path: '', 
     component: PagesComponent,
     children:[  
      { path : 'checkout', loadChildren: () => import('./Pages/Checkout/Checkout.module').then(m => m.CheckoutModule), canActivate: [AuthGuard]},
      { path : 'products', loadChildren: () => import('./Pages/Products/Products.module').then(m => m.ProductsModule), canActivate: [AuthGuard]},
      { path : 'session', loadChildren: () => import('./Pages/Session/Session.module').then(m => m.SessionModule), canActivate: [AuthGuard]},
      { path : 'account', loadChildren: () => import('./Pages/UserAccount/UserAccount.module').then(m => m.UserAccountModule), canActivate: [AuthGuard]},
      { path : 'tiendas', loadChildren: () => import('./Pages/tiendas/tiendas.module').then(m => m.TiendasModule), canActivate: [AuthGuard]},
      { path : 'catalogos',loadChildren: ()=> import('./Pages/catalogos/catalogos.routing').then(r=>r.CatalogosRoutes)},
      { path : 'about', loadChildren:  ()=>import('./Pages/About/About.module').then(m => m.AboutModule), canActivate: [AuthGuard]},    
      { path : 'chat', loadChildren:()=>import('./Pages/Chat/chat.module').then(m=>m.ChatModule)},
      { path : 'home', loadChildren:()=>import('./theme/components/Home/Home.module').then(m=>m.HomeModule) , canActivate: [AuthGuard] }, 
      { path : 'not-found', component : NotFoundComponent  , canActivate: [AuthGuard]}, 
      { path : 'cart/id/:vendedorID', component : CartComponent  , canActivate: [AuthGuard]}, 
      //{ path : 'blogs', loadChildren: () => import('./pages/Blogs/Blogs.module').then(m => m.BlogsModule), canActivate: [AuthGuard]},
       
     ]
   },
   /*{
      path: '**',
      redirectTo: 'not-found'
   }*/
 ];

 @NgModule({
  /*imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]*/
 })

 export class AppRoutingModule { }

