import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from '@settings'; //modulo global de direcciones.
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatSocketService } from './chatSocket.service';
import { NotificacionesService } from './notificaciones.service';

export interface Mensaje{
  carritoID:Number
  talkerID:Number
  receiverID:Number
  message:String
  estado? :String
  readAt?:Date
  createdAt?:Date
  itsMine?:Boolean
}

@Injectable()

export class ChatService {
  private host = this.appSettings.urls['host']
  private recursoVendedores = this.appSettings.urls['vendedores']
  public socketChat: Subject<any>
  private cantidadMensajes
  private $cantMensajesSource = new Subject<Number>()//new BehaviorSubject<Number>(0)
  public $cantMensajes = this.$cantMensajesSource.asObservable()
  constructor(
    private http: HttpClient,
    private appSettings:AppSettings,
    private _chatSocket: ChatSocketService) {
      
    }

    conectarSocket(carritoID,personaId,receiverID){
      this.socketChat = <Subject<any>>this._chatSocket.connectChatRoom(carritoID,personaId,receiverID).pipe(map(res=>{
        console.log(res)
        return res
      }))
    }

    desconectarChatRoom(){
      this._chatSocket.disconnectChatRoom()
    }

    getDatosVendedor(id){
        return this.http.get(`${this.host+this.recursoVendedores}/for-chat/id/${id}`)
    }

    getMensajesCarrito(idCarrito,page,limit){
      return this.http.get(`${this.host}/carritos/mensajes/carritoID/${idCarrito}/p/${page}/l/${limit}`).pipe(map(res=>res))
    }

    getCantidadNoLeidosCarrito(idCarrito,idReceiver){
      return this.http.get(`${this.host}/carritos/mensajes/cant-no-leidos/carritoID/${idCarrito}/receiverID/${idReceiver}`).pipe(map(res=>res))
    }

    setCantidadMensajes(cantidad:Number){
      this.cantidadMensajes = cantidad
      console.log(this.cantidadMensajes)
      this.$cantMensajesSource.next(cantidad)
    }

    newMensaje(){
      this.cantidadMensajes ++
      this.$cantMensajesSource.next(this.cantidadMensajes)
    }

    viewMensajes(cantidad:number){
      this.cantidadMensajes -= cantidad
      this.$cantMensajesSource.next(this.cantidadMensajes)
    }

    getCantidadNoLeidos(idPersona){
      return this.http.get(`${this.host}/carritos/mensajes/cant-no-leidos/receiverID/${idPersona}`).pipe(map(res=>res))
    }

    postMensaje(mensaje:Mensaje){
      return this.http.post(`${this.host}/carritos/mensajes`,mensaje).pipe(map(res=>res))
    }

    patchLeidos(idCarrito,idReceiver){
      return this.http.patch(`${this.host}/carritos/mensajes/leidos/receiverID/${idReceiver}/carritoID/${idCarrito}`,{}).pipe(map(res=>res))
    }


}