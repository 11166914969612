<span>En carrito:</span>
<div class="input-button">
  <button class="button" (click)="descontarCarrito()">
    <mat-icon>remove_circle</mat-icon>
  </button>
  <input
    class="input"
    type="number"
    [formControl]="cantidadInput"
  />
  <button class="button" (click)="sumarCarrito()">
    <mat-icon>add_circle</mat-icon>
  </button>
</div>
<mat-error *ngIf="cantidadInput.hasError('max')">No hay stock suficiente</mat-error>
<mat-error *ngIf="cantidadInput.hasError('min')"
  >No se permiten menos de {{ product.compraMinima }}</mat-error
>
