import { Injectable } from '@angular/core';
import { Observable,of,from } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { Loader } from "@googlemaps/js-api-loader";

import { environment } from '@environments/environment';

declare var google: any;

const loader = new Loader({
  apiKey: environment.google_map_key,
  version: "weekly",
})

@Injectable()
export class GeocodeService {
  private geocoder: any;
  private mapGeocode: google.maps.Geocoder | undefined

  constructor() {
    loader.load().then(()=>{
      this.mapGeocode = new google.maps.Geocoder();
    })
  }

  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if(!this.geocoder) {
      return from(loader.load())
      .pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }

  public loadMapnHtml(htmlId:string){
    return loader.load().then(()=>{
      return new  google.maps.Map(document.getElementById(htmlId) as HTMLElement,{
          zoom: 15
      })
    })
  }

  public getLoader(){
    return loader
  }

  public direccionToCoordenadas(direccion:{address:string}){
    console.log(this.mapGeocode)
    return this.mapGeocode?.geocode(direccion)
}

  geocodeAddress(location: string){
    console.log('Start geocoding!');
    return this.waitForMapsToLoad().pipe(
      //z filter(loaded => loaded),
      switchMap(() => {
        return new Observable(observer => {
          this.geocoder.geocode({'address': location}, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              console.log('Geocoding complete!',results);
              observer.next({
                lat: results[0].geometry.location.lat(), 
                lng: results[0].geometry.location.lng()
              });
            } else {
                console.log('Error - ', results, ' & Status - ', status);
                observer.next({ lat: 0, lng: 0 });
            }
            observer.complete();
          });
        })        
      })
    )
  }

  
  
}