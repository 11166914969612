//import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
//import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from '@angular/router';
import { Socalo } from '@app/_interfaces/pubs.interface';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { PopUpComponent } from '@app/theme/components/PopUp/popup.component';
import { MenuItems } from '@models';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { EmbryoService, PublicidadesService } from '@services';
import { interval, Subscription } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css'],
  providers:[PublicidadesService]
  //changeDetection : ChangeDetectionStrategy.OnPush
})
export class PagesComponent implements OnInit {

   timer = 0;
   isRtl: any;
   private _dirChangeSubscription = Subscription.EMPTY;
   private _mediaSubscription         : Subscription;
   currentUrl : any; 
   itemsMenu
   snBannerInstall = true
   public estadoSession
   public installEvent = null
   private popUpGeneral: Socalo
   private localStorageService = inject(LocalStorageService)
   windowScrolled
   constructor(private loader : LoadingBarService,
               public embryoService : EmbryoService, 
               public menuItems: MenuItems,
               //dir: Directionality,
               private router: Router,
               //meta: Meta, title: Title,
               //private activatedRoute: ActivatedRoute,
               private media: MediaObserver,
               private publicidadesService: PublicidadesService,
               private dialog: MatDialog,
               @Inject(DOCUMENT) private document: Document
               ) { 

      /*title.setTitle('Embryo - Angular Material Design eCommerce Template');

      meta.addTags([
         { name: 'author',   content: 'The IRON Network'},
         { name: 'keywords', content: ' angular, angular 2, angular 6, angular 7, angular material, clean, creative, ecommerce, frontend, online store, shop, shopping, store, typescript, ui framework '},
         { name: 'description', content: 'Embryo is an E-Commerce angular 7 based template with material design. It also comes with Angular cli. Now you have all the power to maintain your ecommerce site. Responsive design gives your user to use in any modern devices. Clean Code gives you the power to customize the code as per as your requirments. Embryo has all the basics functionality which is required in ecommerce site. Rtl design makes the multi-language support with more easy way.' }
      ]);
      */
      /*if(this.embryoService.isDirectionRtl) {
         this.isRtl = 'rtl';
      } else {
         this.isRtl = 'ltr';
      }*/

      this.router.events
        .subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.currentUrl = event.url;
          }
        });
   }

   @HostListener('window:scroll', [])
  onWindowScroll(): void {
     console.log('scroll')
      if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
          this.windowScrolled = true;
      }
     else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
          this.windowScrolled = false;
      }
   }

   installAction(event){
      if(event){
         this.installByUser()
      }else{
         this.snBannerInstall = false
      }
   }

   @HostListener('window:beforeinstallprompt',['$event'])
   onBeforeInstallPrompt(event:Event){
      console.log(event)
      event.preventDefault()
      this.installEvent = event
   }

   installByUser(){
      if(this.installEvent){
         this.installEvent.prompt()
         this.installEvent.userChoice.then(res=>{
            console.log(res)
         })
      }
   }

   scrollToTop(): void {
      (function smoothscroll(): void {
          const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (currentScroll > 0) {
              //window.requestAnimationFrame(smoothscroll);
              window.scrollTo(0,0);
          }
      })();
   }

   ngOnInit() {
      document.getElementById('html').classList.remove("admin-panel");
      document.getElementById('html').classList.add("user-end");
      this.menuItems.cargarMenu().then(()=>{
         this.itemsMenu = this.menuItems.getMainMenu()
      }
      )
      this.cargarPublicidades()
   }

   cargarPublicidades(){
      this.publicidadesService.getPublicidades('GENERAL').pipe(take(1)).subscribe({
         next: (pub)=>{
            if(pub.code === 200){
               this.popUpGeneral = pub.data[0]
               if(this.localStorageService.getUserInfo().ID === null){ //si el usuario no esta iniciado sesion le muestro el popUp para los sin sesion
                  if(this.popUpGeneral['pubs'][0]){
                     if(sessionStorage.getItem('viewModal-'+this.popUpGeneral['pubs'][0].ID)!='1'){
                        setTimeout(()=>{
                           sessionStorage.setItem('viewModal-'+this.popUpGeneral['pubs'][0].ID,'1')
                           let dialogRef: MatDialogRef<PopUpComponent>
                           dialogRef = this.dialog.open(PopUpComponent,{
                              panelClass:'pop-up-publ'
                           })
                           dialogRef.componentInstance.data = this.popUpGeneral['pubs'][0]
                           dialogRef.afterClosed().subscribe(res=>{
                              console.log(res)
                              if(res?.url){
                                 if(res.snDirInterna){
                                    this.router.navigate([res.url])
                                 }else{
                                    //this.router.navigateByUrl(res.url)
                                    window.open(res.url,'blank')
                                 }
                                 
                              }
                           })
                        },5000)
                     }
                  }
               }
            }
         }
      })
   }



   ngAfterViewInit(){
      document.getElementById('preloader').classList.add('hide');
   }

   

   public startTimer() {
      this.timer = 0;
      interval(1000).pipe(
      take(3),
      tap(value => { this.timer = value + 1; }),
       finalize(() => this.loader.complete()),
      ).subscribe();

      // We're sure that subscription has been made, we can start loading bar service
      this.loader.start();
   }

   public hideSideNav() {
      this.embryoService.sidenavOpen = false;
   }

   /*public changeDirection() {
      if(this.isRtl == "rtl") {
         this.isRtl = "ltr";
         this.embryoService.isDirectionRtl = false;
      } else {
         this.isRtl = "rtl"
         this.embryoService.isDirectionRtl = true;
      }

      this.embryoService.featuredProductsSelectedTab = 0;
      this.embryoService.newArrivalSelectedTab = 0;
   }*/

   /**
    * On window scroll add class header-fixed.
    */
   @HostListener('window:scroll', ['$event'])
   onScrollEvent($event){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (scrollTop >= 200) {
           document.querySelector('app-main').classList.add("header-fixed");
       } else {
            document.querySelector('app-main').classList.remove("header-fixed");
       }
   }   

   /**
     *As router outlet will emit an activate event any time a new component is being instantiated.
     */
   onActivate(e) {
      window.scroll(0,0);
   }
}
