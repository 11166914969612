import {PrecioProducto} from './precioProducto'
import {AtributoProducto} from './atributoProducto'
import { TipoVendedor } from '@app/_interfaces/login.interface'
export class ProductoCompleto{

    constructor(){
       this.precios = new Array<PrecioProducto>()
    }
    categoria:string
    compraMinima:string
    subcategoria:string
    vendedorID:number
    productoID:number
    descripcion:string
    marcaID: number
    maxPublicaciones:number
    unidadMedidaID : number
    titulo:string
    stock: number
    fechaDesde
    fechaHasta
    estado
    precio: number
    peso: number
    alto: number
    largo : number
    profundidad: number
    snFragil: boolean
    precios:Array<PrecioProducto>
    atributos: Array<AtributoProducto>
    tipo:TipoVendedor
    
}