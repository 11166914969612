//modulos
import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable } from '@angular/core'; // capas de hacer peticiones.
import { PubsPageResponse } from '@app/_interfaces/pubs.interface';
import { AppSettings } from '@settings'; //modulo global de direcciones.
import { map } from 'rxjs/operators';
//Cabeceras.
@Injectable( )

export class PublicidadesService {
  private host = this.appSettings.urls['host']; // variable global con la ip de la api.
  private recurso = this.appSettings.urls['publicidad']; //accesso a la variable de api mas el modulo.

  constructor(
  	private http: HttpClient,
    public appSettings:AppSettings  
  	) {}

  getPublicidades(page){
  	return this.http.get<PubsPageResponse>(`${this.host+this.recurso}/page/${page}`);
  }

  patchClicks(id){
    return this.http.patch(`${this.host+this.recurso}/clicks/id/${id}`,{}).pipe(map(res => res))
  }


}