import { ChangeDetectionStrategy, Component, HostListener, ViewContainerRef } from '@angular/core';
import { AppSettings } from './app.settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IsOnlineService, WebSocketService } from '@services'
import { Title, Meta } from '@angular/platform-browser';
import { NotificacionesService } from './_services/notificaciones.service';
import { Observable, Subject } from 'rxjs';
import { filter, map } from "rxjs/operators";
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';




@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.css'],
   providers: [IsOnlineService],
   //changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
   private notificaciones : Subject<any>
   

   constructor(
      public appSettings: AppSettings,
      private _snackBar: MatSnackBar,
      private _serviceNotificaciones : NotificacionesService,
      private _webService : WebSocketService,
      isOnline: IsOnlineService,
      private swUpdate: SwUpdate,
      private titleService: Title, private metaService: Meta) {
      setInterval(async () => {
         const result = await isOnline.checkOnlineStatus();
         if (!result) {
            this._snackBar.open('Tu conexión a internet ha fallado')
         } else {
            this._snackBar.dismiss()
         }
      }, 30000);
   }

   ngOnInit(): void {
      //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
      //Add 'implements OnInit' to the class.
      //console.log('soy el nuevo dist')
      this.titleService.setTitle("HiperAbasto.com");
      this.metaService.addTags([
         { name: 'keywords', content: 'compra, venta, mayorista' },
         { name: 'description', content: 'Llegá a tu consumidor final de forma directa a través de hiperabasto.com' },
         { name: 'robots', content: 'NotFound' }
      ]);
      this.updatePWA()
   }

   updatePWA(){
      /*this.swUpdate.available.subscribe(value=>{
         console.log('update:', value)
         window.location.reload()
      })*/
      const updatesAvailable = this.swUpdate.versionUpdates.pipe(
         filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
         map(evt => {
            window.location.reload()
         return{
            type: 'UPDATE_AVAILABLE',
            current: evt.currentVersion,
            available: evt.latestVersion,
            }
         }));
   }

   

}
