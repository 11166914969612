//modulos
import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable } from '@angular/core'; // capas de hacer peticiones.
import { AppSettings } from '@settings'; //modulo global de direcciones.
//Cabeceras.
@Injectable( )

export class ProvinciasService {
  host = this.appSettings.urls['host']; // variable global con la ip de la api.
  recurso = this.appSettings.urls['extras']; //accesso a la variable de api mas el modulo.

  constructor(
  	private http: HttpClient,
    public appSettings:AppSettings  
  	) {}

  getProvincias(){
  	return this.http.get(`${this.host+this.recurso}/provincias`);
  }

  getLocalidadesProvinciaID(idProvincia){
    return this.http.get(`${this.host+this.recurso}/localidades/provinciaID/${idProvincia}`);
  }

  getCoordenadas(provincia,localidad,direccion){
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyDI3F_f3-0ACNc_N6Ti3uXjuVBwAUpeIQE`)
  }

}