import { Component, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppSettings } from '@settings';
import { CarritoService, EmbryoService } from '@services';

@Component({
   selector: 'embryo-PaymentDetailSideBar',
   templateUrl: './PaymentDetailSideBar.component.html',
   styleUrls: ['./PaymentDetailSideBar.component.scss'],
   providers: [AppSettings,CarritoService]
})

export class PaymentDetailSideBarComponent implements OnInit {
   @Input() vendedorID;
   popupResponse: any;
   private carritoService = inject(CarritoService)
   cartProducts: any;
   constructor(
      public embryoService: EmbryoService,
      private loadingBar: LoadingBarService,
      public route: Router,
      public _route: ActivatedRoute,
      public appSettings: AppSettings) { }

   ngOnInit() {
      //this.getCarrito()
   }

   ngOnChanges() {
   }

   /* FUNCION QUE ACTUALIZA EL CARRITO EN CASO DE QUE SE HAYA CAMBIADO EL VENDEDOR Y NO SE HAYA ACTUALIZADO EL COMPONENTE */
   /*public getCar() {
      return this.embryoService.localStorageCartProducts;
   }*/

   /*FIN | FUNCION QUE ACTUALIZA EL CARRITO EN CASO DE QUE SE HAYA CAMBIADO EL VENDEDOR Y NO SE HAYA ACTUALIZADO EL COMPONENTE */

   /* FUNCION QUE FILTRA EL CARRITO Y MUESTRA POR PRIMERA VEZ */
   public getCarrito(vendedorID:number) {
      console.log(vendedorID)
      this.cartProducts = this.carritoService.getCarritoLocalVendedorId(vendedorID)
      console.log(this.cartProducts)
   }

   /*FIN | FUNCION QUE FILTRA EL CARRITO Y MUESTRA POR PRIMERA VEZ */

   /* FUNCION QUE CALCULA EL PRECIO TOTAL DEL SUBCARRITO QUE SE QUIERE COMPRAR */
   public calculateTotalPrice() {
      let subtotal = 0;
      if (this.embryoService.localStorageCartProducts && this.embryoService.localStorageCartProducts.length > 0) {
         for (let product of this.embryoService.localStorageCartProducts) {
            if (product.vendedor?.ID == this.embryoService.vendedorID) {
               for (let prod of product.publicaciones) {
                  if (!prod.cantidadMinima) {
                     prod.cantidadMinima = 1;
                  }
                  if (prod.descuento) {
                     subtotal += (prod.precio * prod.cantidadMinima) - ((prod.precio * prod.cantidadMinima) * (prod.descuento / 100));
                  } else {
                     subtotal += (prod.precio * prod.cantidadMinima);
                  }

               }
            }
         }
      }
      return subtotal;
   }

   /*FIN | FUNCION QUE CALCULA EL PRECIO TOTAL DEL SUBCARRITO QUE SE QUIERE COMPRAR */

   /* FUNCION QUE BORRA EL ELEMENTO DEL CARRITO */
   public removeProduct(value) {
      let message = "Estas seguro que desea eliminar ese producto?";
      this.embryoService.confirmationPopup(message).
         subscribe(res => { this.popupResponse = res },
            err => console.log(err),
            () => this.getPopupResponse(this.popupResponse, value)
         );
   }

   /* FIN | FUNCION QUE BORRA EL ELEMENTO DEL CARRITO */

   /* FUNCION QUE SE COMUNICA CON EL SERVICIO GLOBAL PARA BORRAR LA PUBLICACION DEL CARRITO  */
   public getPopupResponse(response, value) {
      if (response) {
         this.embryoService.removeLocalCartProduct(value);
         this.embryoService.paymentSidenavOpen = false;
      }
   }
   /*FIN | FUNCION QUE SE COMUNICA CON EL SERVICIO GLOBAL PARA BORRAR LA PUBLICACION DEL CARRITO  */

   /* FUNCION QUE CALCULA EL PRECIO DE CADA PUBLICACION */
   public calculateProductSinglePrice(product: any, value: any) {
      let price = 0;
      product.cantidadMinima = value;
      if (product.descuento) {
         price = (product.precio * product.cantidad) - ((product.precio * product.cantidad) * (product.descuento / 100))
      } else {
         price = product.precio * value;
      }

      return price;
   }
   /* FIN | FUNCION QUE CALCULA EL PRECIO DE CADA PUBLICACION */

   /* FUNCION QUE CALCULA EL PRECIO TOTAL DEL CARRITO */
   public getTotalPrice() {
      let total = 0;
      if (this.embryoService.localStorageCartProducts && this.embryoService.localStorageCartProducts.length > 0) {
         for (let product of this.embryoService.localStorageCartProducts) {
            if (product.vendedor.ID == this.embryoService.vendedorID) {
               for (let prod of product.publicaciones) {
                  if (!prod.cantidadMinima) {
                     prod.cantidadMinima = 1;
                  }
                  if (prod.descuento) {
                     total += (prod.precio * prod.cantidadMinima) - ((prod.precio * prod.cantidadMinima) * (prod.descuento / 100));
                  } else {
                     total += (prod.precio * prod.cantidadMinima);
                  }

               }
            }
         }
         //total += (this.embryoService.shipping+this.embryoService.tax);
      }
      return total;
   }
   /* FIN | FUNCION QUE CALCULA EL PRECIO TOTAL DEL CARRITO */

}
