<div  (scroll)="onScrollEvent($event)" style="display:flex; flex-direction: column; min-height: 100vh;">
   <HeaderTwo></HeaderTwo>
   <ngx-toasta></ngx-toasta>
   <mat-sidenav-container style="flex-grow: 1;">
      <mat-sidenav #sidenav [(opened)]="embryoService.sidenavOpen" class="sidebar-area colorDegradadoSecundario"
         mode="over">
         <mat-nav-list class="pt-0">
            <div class="row">
               <div class="col-6">
                  <img appLazyLoad loading="lazy" width="100" height="60" src="../../assets/images/logo-blanco.png">
               </div>
               <div class="close-btn-sidebar col-6">
                  <a href="javascript:void(0)" (click)="embryoService.sidenavOpen = false" class="cerrar-nav">
                     <i class="material-icons icono-cerrar-nav">
                        arrow_back_ios
                     </i>
                  </a>
               </div>
            </div>

            <embryo-SidebarMenu *ngFor="let item of itemsMenu" [item]="item"></embryo-SidebarMenu>
         </mat-nav-list>
      </mat-sidenav>
      <!--<mat-sidenav class="em-sidebar" #paymentSidenav [(opened)]="embryoService.paymentSidenavOpen" position="end"
         mode="over">
         <embryo-PaymentDetailSideBar></embryo-PaymentDetailSideBar>
      </mat-sidenav>-->

      <!--<div id="overlay"></div>-->
      
      <mat-sidenav-content>
            <router-outlet (activate)="onActivate($event)"></router-outlet>
      </mat-sidenav-content>
   </mat-sidenav-container>
   <FooterOne></FooterOne>
   <appInstallBanner class="d-md-none" *ngIf="snBannerInstall && installEvent" (userResponse)="installAction($event)"></appInstallBanner>
   <div class="scroll-to-top" [ngClass]="{'show-scrollTop': true}">
      <button mat-fab aria-label="ir al inicio" (click)="scrollToTop()" style="outline: none !important; background-color: #fbde15; color:black">
         <span class="material-icons">
            keyboard_arrow_up
         </span>
      </button>
   </div>
</div>