import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
  standalone: true
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: number | null | undefined, currencyCode: string = 'USD', symbolDisplay: boolean = true, digitsInfo: string = '1.2-2'): string | null {
    if (value === null || value === undefined) {
      return null;
    }

    // Verificar si los decimales son 0
    const isDecimalZero = (value % 1 === 0);

    // Si los decimales son 0, eliminar la parte decimal
    if (isDecimalZero) {
      value = Math.trunc(value);
    }

    // Formatear la moneda usando el CurrencyPipe estándar de Angular
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: symbolDisplay ? 'symbol' : 'code',
      minimumFractionDigits: isDecimalZero ? 0 : 2, // 0 decimales si son 0, de lo contrario, 2
      maximumFractionDigits: 2 // Máximo de 2 decimales
    }).format(value);

    return formattedValue;
  }
}
