import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalLoginComponent } from '@components/Modal-login/modal-login.component';
import { take } from 'rxjs/operators';
import { EmbryoService } from '../_services/embryo.service';
@Injectable({
  providedIn: 'root'
})
export class ModalLoginService {
  constructor(
    public dialog:MatDialog,
    public embryoService:EmbryoService
  ) { }

  public async iniciarSesionPushCarrito(publicacion){
    let dialogRef : MatDialogRef<ModalLoginComponent>;
    dialogRef = this.dialog.open(ModalLoginComponent, {width: '400px'});
    dialogRef.afterClosed().pipe(take(1)).subscribe(res=>{
      this.embryoService.addToCart(publicacion);

    })
    let result = dialogRef.afterClosed().toPromise()
    return result;
  }



  public iniciarSesion(){
    let login
    let dialogRef : MatDialogRef<ModalLoginComponent>;
    dialogRef = this.dialog.open(ModalLoginComponent, {width: '400px'});
    
    return dialogRef
    //console.log(dialogRef)
  }

  public async iniciarSesion2(){

    let dialogRef : MatDialogRef<ModalLoginComponent>;
    dialogRef = this.dialog.open(ModalLoginComponent, {width: '400px'});
    let ret = null
    let result = dialogRef.afterClosed().toPromise()
    return result;
  }
}
