import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, inject } from '@angular/core';
import { EmbryoService, SessionService } from '@services'
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalLoginComponent } from '@app/theme/components/Modal-login/modal-login.component';
import { take } from 'rxjs/operators';
import { LocalStorageService } from '@app/_services/local-storage-service';

@Component({
  selector: 'app-preguntas-producto',
  templateUrl: './preguntas-producto.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./preguntas-producto.component.scss']
})
export class PreguntasProductoComponent implements OnInit {
  @Input() idPublicacion
  @Input() vendedorId
  
  public paginaActual = 1
  public cantidadTotal
  public formPregunta: UntypedFormGroup
  public preguntasRespuestas: any = []
  public cargandoPreguntas=false
  public isVendedor: boolean;
  public loginStatus = this.sesionService.estadoUsuarioInterno
  private localStorageService = inject(LocalStorageService)
  public compradorId = this.localStorageService.getCompradorInfo().ID


  constructor(public embrioService:EmbryoService,
              private _snackBar:MatSnackBar,
              private cdr: ChangeDetectorRef,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private sesionService:SessionService) {
                this.formPregunta= this.fb.group({
                  pregunta: ['',[Validators.maxLength(1000),Validators.minLength(10)]]
                })
               }

  ngOnInit() {
    this.sesionService.notificarLoginStatus()
    
    this.cargarPreguntasRespuestas()
    if(this.localStorageService.getVendedorInfo().ID)
         this.isVendedor = true;
    else
         this.isVendedor = false;
  }

  

  ngOnChanges(){
    this.cdr.detectChanges()
  }

  async enviarPregunta(){
    if(this.formPregunta.valid){
      let result = await this.embrioService.postPregunta(this.idPublicacion,this.compradorId,this.formPregunta.get('pregunta').value,this.vendedorId).toPromise()
      if(result['code']==201){
        let newPreg = {
          'pregunta':this.formPregunta.get('pregunta').value
        }
        this.preguntasRespuestas.unshift(newPreg)
        this.formPregunta.setValue({pregunta:""})
        this._snackBar.open('Su pregunta fue enviada al vendedor','Aceptar',{
          duration:5000
        })
      
      }else{
        console.log(result)
        this._snackBar.open('Ocurrio un error, por favor intente mas tarde','Aceptar')
      }
      //this.cdr.detectChanges()
      //this.cdr.markForCheck()
    }else{
      this.formPregunta.markAllAsTouched()
    }
    
  }

  async cargarPreguntasRespuestas(){
    let result = await this.embrioService.getPreguntasRespuestas(this.idPublicacion,this.paginaActual).toPromise()
    //console.log(result)
    this.cargandoPreguntas=true
    if(result['code']==200){
      this.preguntasRespuestas = result['data'].rows
      this.cantidadTotal = result['data'].count
    }else{
      console.log(result)
    }
    this.cargandoPreguntas = false
    this.cdr.detectChanges()
    //this.cdr.markForCheck()
  }

  cambiarPagina(event){
		console.log(event)
    this.paginaActual= event;
    this.cargarPreguntasRespuestas()
  }
  
  trackById(index, item) {
    return item.ID;
  }

  iniciarSesion(){
    this.dialog.open(ModalLoginComponent).afterClosed().pipe(take(1)).subscribe(res=>{
      this.compradorId = this.localStorageService.getCompradorInfo().ID
      console.log(this.compradorId)
    })
  }
}
