import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterial } from '@app/_helpers';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';;
import { FlexLayoutModule } from '@angular/flex-layout';
import { TitleComponent } from './TitleComponent/TitleComponent.component';
import { TopsideMenuComponent } from './Menu/TopsideMenu/TopsideMenu.component';
import { HeaderUserProfileDropdownComponent } from './HeaderUserProfileDropdown/HeaderUserProfileDropdown.component';
import { RouterModule } from '@angular/router';
@NgModule({
    declarations: [
        TitleComponent,
        TopsideMenuComponent,
        HeaderUserProfileDropdownComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterial,
        FlexLayoutModule,
        RouterModule,
    ],
    exports: [
        TitleComponent,
        TopsideMenuComponent,
        HeaderUserProfileDropdownComponent,
    ],
    schemas: []
})
export class WidgetModule { }
