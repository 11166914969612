export class Comprador{
    constructor(){}

    personaID:string;
    nombre:string
    direccion:string;
    telefono:string;
    mail:string;
    DNI:string;
    puntos:string;
	estado:string;

}