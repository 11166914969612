//modulos
import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable } from '@angular/core'; // capas de hacer peticiones.
import { AppSettings } from '@settings'; //modulo global de direcciones.

@Injectable( )

export class MenuService {
  host = this.appSettings.urls['host']; // variable global con la ip de la api.
  recurso = this.appSettings.urls['categorias']; //accesso a la variable de api mas el modulo.

  constructor(
  	private http: HttpClient,
    public appSettings:AppSettings  
  	) {}

  get(){
  	return this.http.get(`${this.host+this.recurso}`);
  }

}