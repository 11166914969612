import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PubInterface, Socalo } from '@app/_interfaces/pubs.interface';
import { AppSettings } from '@app/app.settings';


@Component({
  selector: 'popUp',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
})

export class PopUpComponent implements OnInit {
    @Input() data:PubInterface
    public soloImg = true
  vistaOrdenador: boolean;

    constructor(public dialogRef: MatDialogRef<PopUpComponent>,
                public sanitizer: DomSanitizer,
                public router: Router,
                public appSettings: AppSettings){
                  if(screen.width >= 767){
                    this.vistaOrdenador = true
                 }else{
                    this.vistaOrdenador = false
                 }
    }

    cerrarModal(url?,snDirInterna?){
      let data 
      if(url && snDirInterna){
        data = {
          'url':url,
          'snDirInterna':snDirInterna
        }
      }
      this.dialogRef.close(data)
    }

    redireccionar(){
      if(this.data.url.includes('hiperabasto.com')){
        let urlPura = this.data.url.split("hiperabasto.com")[1]
        console.log(urlPura)
        this.cerrarModal(urlPura,true)
        //this.router.navigate(urlPura)
      }else{
        this.cerrarModal(this.data.url,false)
      }
      //window.open(this.data.url)
      //this.cerrarModal()
    }

    ngOnInit(): void {

        console.log(this.data)
        if(this.vistaOrdenador){
          this.data.img = this.appSettings.urls.hostFiles+this.data.img+'-w640.jpg'
        }else{
          this.data.img = this.appSettings.urls.hostFiles+this.data.img_mobile+'-w300.jpg'
        }
        
        if(this.data.texto){
          this.data.texto = this.sanitizer.bypassSecurityTrustHtml(this.data.texto)
        }
        if(this.data.pretexto){
          this.data.pretexto = this.sanitizer.bypassSecurityTrustHtml(this.data.pretexto)
        }
        if(this.data.titulo && this.data.titulo.length>0){
          this.soloImg = false
        }
    }

}