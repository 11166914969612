<div class="sidenav-scrollbar-container">

   <mat-nav-list menuToggleDirective class="navigation d-flex flex-column">
      <mat-list-item menuToggleLink *ngFor="let menuitem of menuItems.getAll()">
         <!--ITEM DE MENU TIPO LINK (SIN HIJOS)-->
         <a menuToggle class="relative" mat-ripple [routerLink]="['/'+menuitem.state]" routerLinkActive="active-link" *ngIf="menuitem.type === 'link'">
         <mat-icon matListItemIcon *ngIf="menuitem.name!='Preguntas'">{{ menuitem.icon }}</mat-icon>
         <mat-icon matListItemIcon *ngIf="menuitem.name=='Preguntas' && (embryo.getCantidadPreguntas()>0)"  [matBadge]="embryo.getCantidadPreguntas()">{{menuitem.icon}}</mat-icon>
         <mat-icon matListItemIcon *ngIf="menuitem.name=='Preguntas' && (embryo.getCantidadPreguntas()==0)">{{menuitem.icon}}</mat-icon>
         <span style="white-space:break-spaces">{{ menuitem.name }}</span>
         <ng-container *ngIf="menuitem.label">
            <span class="sidebar-label">{{ menuitem.label}}</span>
         </ng-container>
         </a>

         <!--ITEM DE MENU TIPO DESPLEGABLE-->
         <a menuToggle class="relative" mat-ripple href="javascript:;" *ngIf="menuitem.type === 'sub'">
            <mat-icon matListItemIcon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <ng-container *ngIf="menuitem.label">
               <span class="sidebar-label">{{ menuitem.label}}</span>
            </ng-container>
            <mat-icon matListItemIcon class="menu-caret">chevron_right</mat-icon>
         </a>
         <mat-nav-list class="sub-menu" *ngIf="menuitem.type === 'sub'">
            <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="open"  style="background-color: #ee2b24;">
               <a (click)="ruteo(menuitem.state,childitem.state)" routerLinkActive="active-link" class="relative" mat-ripple>
                  {{ childitem.name }}
                  <ng-container *ngIf="childitem.label">
                     <span class="sidebar-label">{{childitem.label}}</span>
                  </ng-container>
               </a>
            </mat-list-item>
         </mat-nav-list>
      </mat-list-item>
   </mat-nav-list>
</div>
<!-- sidenav-scrollbar-container -->