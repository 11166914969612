import { HttpClient } from '@angular/common/http'; //protocolo de comunicacion.
import { Injectable } from '@angular/core';
import { map } from "rxjs/operators"; //mapeo de respuesta de base de datos.
import { AppSettings } from '@settings'; //modulo global de direcciones.
import { Tarjeta } from '@models';
@Injectable()
export class TarjetasService {
  host = this.appSettings.urls['host'] // variable global con la ip de la api.
  recurso_tarjetas = this.appSettings.urls['tarjetas']

  constructor(
    private http: HttpClient,
    public appSettings:AppSettings,
  ) { }

  getPersonaID(idPersona:number){
    return this.http.get(`${this.host+this.recurso_tarjetas}/personaID/${idPersona}`).pipe(map(res => res ));
  }

  getID(idTarjeta:Number){
    return this.http.get(`${this.host+this.recurso_tarjetas}/id/${idTarjeta}`).pipe(map(res => res ));
  }


  post(tarjeta:Tarjeta){
    let json = JSON.stringify(tarjeta);
    return this.http.post(`${this.host+this.recurso_tarjetas}`, json).pipe(map(res => res ))
  }

  put(tarjeta:Tarjeta){
    let json = JSON.stringify(tarjeta);
    json = JSON.parse(json);
    return this.http.put(`${this.host+this.recurso_tarjetas}/id/${tarjeta.ID}`, json).pipe(map(res => res ))
  }
}
