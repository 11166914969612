import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { AngularFireObject } from "@angular/fire/database";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
//import { AddNewUserComponent } from '@components/AddNewUser/AddNewUser.component';
import { DeleteListDialogComponent } from '@components/DeleteListDialog/DeleteListDialog.component';
//import { SeeListDialogComponent } from '@components/SeeListDialog/SeeListDialog.component';

@Injectable({
  providedIn: 'root'
})

export class AdminPanelServiceService {

	sidenavOpen 	 : boolean = true;
	sidenavMode 	 : string = "side";
	chatSideBarOpen : boolean = true;
	editProductData : any;
	products;

	constructor(private http:HttpClient,
					private dialog: MatDialog) { }

	/*
		---------- Pop Up Function ----------


	/*deleteDiaglog function is used to open the Delete Dialog Component. */
	deleteDialog(data:string){
		let dialogRef : MatDialogRef<DeleteListDialogComponent>;
		dialogRef = this.dialog.open(DeleteListDialogComponent);
		dialogRef.componentInstance.data = data;
		
		return dialogRef.afterClosed();
	} 

	//getProducts method is used to get the products.
   /*public getProducts() {
      this.products = this.db.object("products");
      return this.products;
   }

	//getTableTabContent method is used to get the transcation table data.
	getTableTabContent() {
		let tableContent : any;
		tableContent = this.db.object("transcationTable");
		return tableContent;
	}

	//getBuySellChartData method is used to get the buy and sell chart data.
	getBuySellChartContent() {
		let buySellChart : any;
		buySellChart = this.db.list("buySellChartData");
		return buySellChart;
	}

	//getInvoiceContent method is used to get the Invoice table data.
	getInvoiceContent() {
		let invoiceList : any;
		invoiceList = this.db.list("invoiceData");
		return invoiceList;
	}

	//getCollaborationContent method is used to get the Collaboration table data.
	getCollaborationContent () {
		let collaboration : any;
		collaboration = this.db.list("collaborationData");
		return collaboration;
	}*/

	/*seeList function is used to open the see Dialog Component.*/
	/*seeList(){
		let dialogRef : MatDialogRef<SeeListDialogComponent>;
		dialogRef = this.dialog.open(SeeListDialogComponent);
	}	*/

	/*addNewUserDialog function is used to open Add new user Dialog Component. */
	/*addNewUserDialog(){
		let dialogRef : MatDialogRef<AddNewUserComponent>;
		dialogRef = this.dialog.open(AddNewUserComponent);
		
		return dialogRef.afterClosed();
	}*/
}
