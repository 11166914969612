import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "@app/app.settings";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnviosService {
  host = this.appSettings.urls['host'] // variable global con la ip de la api.
  recurso_envios = this.appSettings.urls['envios']
  constructor(
    private http: HttpClient,
    public appSettings:AppSettings,
  ) {

   }

   postPrecioEnvio(domicilio, publicacion, vendedor_id){
     let json = {
       domicilio: domicilio,
       publicacion: publicacion,
       vendedor: {
         ID: vendedor_id
       }
     }
    return this.http.post(`${this.host+this.recurso_envios}/precio/v3`,json);
  }

  postInfoPrecios(domicilio,vendedor_id){
    let json = {
      domicilio:domicilio,
      vendedor:{
        ID:vendedor_id
      }
    }
    return this.http.post(`${this.host+this.recurso_envios}/precio/info`,json).pipe(map(res => res ));
  }

  postPrecioEnvioCarrito(domicilioComprador,precioCarrito,publicaciones,vendedorID){
    let json = {
      "envio":{
        "latitud":domicilioComprador.latitud,
        "longitud": domicilioComprador.longitud,
        "cp": domicilioComprador.codigoPostal,
        "provinciaID":domicilioComprador.provincia.ID
      },
      "total":precioCarrito,
      "publicaciones":publicaciones,
      "vendedorID": vendedorID
    }

    console.log(json)

    return this.http.post(`${this.host+this.recurso_envios}/precio/carrito`,json).pipe(map(res => res ))
  }

  postPedidoRetiro(carritosIDs,sucursalID,fechaRetiro,horarioRetiro,observaciones,bultos){
    let json = {
      "carritosIDs":carritosIDs,
      "fecha" : fechaRetiro,
      "observaciones": observaciones,
      "horario_retiro" :horarioRetiro,
      "cantidad":bultos
    }

    console.log(json)

    return this.http.post(`${this.host+this.recurso_envios}/retirar/sucursalID/${sucursalID}`,json).pipe(map(res=>res))
  }

  postEtiquetas(codigo){
    return this.http.post(`${this.host+this.recurso_envios}/etiqueta`,{'codigo':codigo}).pipe(map(res=>res))
  }
}
