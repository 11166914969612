import { Vendedor } from "./vendedor";
import { Comprador } from "./comprador";
export class Persona{
    constructor(){
        this.comprador = new Comprador();
        this.vendedor = new Vendedor();
    }
    ID:string;
	nombre:string;
	user:string;
    password:string;
    vendedor:Vendedor;
    comprador:Comprador;
    mail:string;
    foto:string;
    provider:string;
    localidadID;
    provinciaID;
    token?: string;  
    idToken?: string; 
    codigo?: string;  
    estado:string;
    genero:string;
    telefono:string;
}