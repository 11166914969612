import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '@app/_services/local-storage-service';

        
@Injectable()
export class MobbexGuardService {
  private localStorageService = inject(LocalStorageService)
  constructor(public router: Router)
  {}

  canActivate(): boolean {
    
    const snMobbex = this.localStorageService.getVendedorInfo().snMobbex
    const snVendedor = this.localStorageService.getVendedorInfo().tipo ==='VENDEDOR'
    console.log('mobbex on guard',snMobbex)
    if (snVendedor && !snMobbex) {
        this.router.navigate(['/admin-panel/account/profile'])
        return false
    }else{       
      return true;
    }
  }
}

export const AuthMobbex:CanActivateFn = (route:ActivatedRouteSnapshot,state:RouterStateSnapshot)=>{
  return inject(MobbexGuardService).canActivate()
}
