import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PreguntasProductoComponent } from '@app/Pages/Templates/preguntas-producto/preguntas-producto.component';
import { ReviewComponent } from '@app/Pages/Templates/Review/Review.component';
import { DescuentosComponent } from '@app/Pages/Templates/ShopDetails/descuentos/descuentos.component';
import { ShopDetailsComponent } from '@app/Pages/Templates/ShopDetails/ShopDetails.component';
import { GlobalModule } from '@app/_helpers';
import {NgxPaginationModule} from 'ngx-pagination';
import { InfoPrecioEnvioComponent } from '@app/theme/components/InfoPrecioEnvio/infoPrecioEnvio.component';
import { ProductGridComponent } from '@app/Pages/Templates/Grid/ProductGrid/ProductGrid.component';
import { ProductCardComponent } from '@app/theme/components/ProductCard/ProductCard.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AddToCardButtonComponent } from '@app/theme/components/AddToCardButton/AddToCardButton.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule,
        FormsModule,
        GlobalModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        ProductCardComponent,
        MatDialogModule,
        AddToCardButtonComponent
    ],
    declarations: [
        ProductGridComponent,
        ReviewComponent,
        ShopDetailsComponent,
        DescuentosComponent,
        PreguntasProductoComponent,
        InfoPrecioEnvioComponent
    ],
    exports: [
        ProductGridComponent,
        ReviewComponent,
        ShopDetailsComponent,
        PreguntasProductoComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TemplatesModule {}
