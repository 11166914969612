<div *ngIf="modalMode" class="contenedor-map">
    <div id="mapGeocode" class="map-modal"></div>
    <button mat-raised-button color="primary" (click)="confirmar()" class="mt-3 w-100">Confirmar</button>
</div>

<ng-container *ngIf="!modalMode">
    <div id="mapGeocode" class="map-modal"></div>
</ng-container>
<!--<div *ngIf="modalMode" class="contenedor-map">
  <agm-map class="map-modal" *ngIf="!latInicial && !lngInicial"
      [latitude]="location?.lat"
      [longitude]="location?.lng"
      [zoom]="15"
      [disableDefaultUI]="false"
      >
      <agm-marker 
          [latitude]="location?.lat"
          [longitude]="location?.lng"
          [markerDraggable]="draggable"
          (dragEnd)="markerDragEnd($event)">   
      </agm-marker>
  </agm-map>

  <agm-map *ngIf="latInicial && lngInicial" class="map-component"
  [latitude]="latInicial"
  [longitude]="lngInicial"
  [zoom]="15"
  [disableDefaultUI]="false"
  >
    <agm-marker 
        [latitude]="latInicial"
        [longitude]="lngInicial"
        [markerDraggable]="draggable"
        (dragEnd)="markerDragEnd($event)">   
    </agm-marker>
  </agm-map>

  <button mat-raised-button color="primary" (click)="confirmar()" class="mt-3 w-100">Confirmar</button>
</div>

<div *ngIf="!modalMode">
  <agm-map *ngIf="!latInicial && !lngInicial" class="map-modal"
      [latitude]="location?.lat"
      [longitude]="location?.lng"
      [zoom]="15"
      [disableDefaultUI]="false"
      >
      <agm-marker 
          [latitude]="location?.lat"
          [longitude]="location?.lng"
          [markerDraggable]="draggable"
          (dragEnd)="markerDragEnd($event)">   
      </agm-marker>
  </agm-map>

  <agm-map *ngIf="latInicial && lngInicial" class="map-component"
    [latitude]="latInicial"
    [longitude]="lngInicial"
    [zoom]="15"
    [disableDefaultUI]="false"
    >
    <agm-marker 
        [latitude]="latInicial"
        [longitude]="lngInicial"
        [markerDraggable]="draggable"
        (dragEnd)="markerDragEnd($event)">   
    </agm-marker>
</agm-map>
</div>-->