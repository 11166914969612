<div style="position: relative;">
    <span class="material-icons cancel-button" (click)="cerrarModal()" >close</span>
</div>
<h2 mat-dialog-title>Opciones de envío <span class="material-icons vertical-middle">local_shipping</span></h2>
<mat-dialog-content>
    
    <div *ngIf="loading" class="col-12 d-flex justify-content-center">
        <mat-spinner ></mat-spinner>
    </div>

    <div *ngIf="!loading">
        <ng-container *ngIf="infoEnvio; else sinEnvio">
        <div class="opcion-container mb-2">
            <div class="viñeta">
                <span class="material-icons vertical-middle">
                    shopping_cart
                </span>
            </div>
            <div class="opcion-envio-container">
                <div class="opcion-envio">
                    <span class="text-envio">Pedidos de precio menor a ${{infoEnvio.envioMinimo}}</span>
                    <span class="material-icons vertical-bottom">
                        arrow_right_alt
                    </span>
                    <span class="text-envio">Envío a cargo de MailExpress</span>
                </div>
            </div>
        </div>
        
        <!--<mat-divider class="precio-divider"></mat-divider>-->
        <div class="opcion-container">
        <div>
            <span class="material-icons vertical-bottom">
                shopping_cart
            </span>
        </div>
        <div class="opcion-envio-container">
            <div class="opcion-envio">
                <span class="text-envio">Pedidos de precio mayor a ${{infoEnvio.envioMinimo}}</span>
                <span class="material-icons vertical-bottom">
                    arrow_right_alt
                </span>
                <span class="text-envio">Envío a cargo de {{vendedor.empresa}}</span>
            </div>
            <div class="opcion-envio">
                <span class="text-envio-secundario" *ngIf="infoEnvio.precio_provincia.costo>0">(El costo de envío a tu dirección es de ${{infoEnvio.precio_provincia.costo}} por kg del pedido)</span>
                <span class="text-envio-gratis" *ngIf="infoEnvio.precio_provincia.costo==0">¡Envio gratis!</span>
            </div>
        </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h6>¿como coordino la entrega del producto?</h6>
            </div>
            <div class="col-12">
                <ol>
                    <li>Al finalizar tu compra, se te habilitara un chat interno que accederás desde la sección <a class="call-to-action-link cp" (click)="navigate('/account/order-history')">mis compras.</a></li>
                    <li>Puedes acordar el envío y pedir clase factura en caso de necesitarla</li>
                    <li>Recordá que el vendedor puede contestar entre las 8 y las 20</li>
                </ol>
            </div>
        </div>
        </ng-container>

        <ng-template #sinEnvio>
            <div class="row">
                <div class="col-12">
                    <h4>Por el momento no contamos con mas opciones de envío para tu ubicación</h4>
                </div>
            </div>
        </ng-template>
        
    </div>
</mat-dialog-content>