import { Routes } from '@angular/router';
import { MainAdminPanelComponent } from './Main/Main.component';
import { RoleGuardService as AuthGuard } from '@app/_helpers/';
import { AuthMobbex} from '@app/_helpers/mobbex.guard'
import { authPanel as authPanel} from '@app/_helpers/canLoadPanel.guard'
import { inject } from '@angular/core';

export const AdminPanelRoutes : Routes = [
   {
      path : 'admin-panel',
      component : MainAdminPanelComponent,
      canLoad:[authPanel],
      children: [ 
         {
            path: '',
            loadChildren: ()=>import('./Reports/Reports.module').then (m => m.ReportsModule),
            //quito validacion de mobbex
            canActivate: [AuthGuard/*,AuthMobbex*/],
         },
         {
            path: 'reports',
            loadChildren: ()=>import('./Reports/Reports.module').then (m => m.ReportsModule),
            canActivate: [AuthGuard/*,AuthMobbex*/], 
         },
         /*{
            path: 'invoices',
            loadChildren: ()=> import('./Invoices/Invoices.module').then (m => m.InvoicesModule),
            canActivate: [AuthGuard], 
         },*/
         {
            path: 'products',
            loadChildren: ()=> import('./Products/Products.module').then(m => m.ProductsModule),
            canActivate: [AuthGuard/*,AuthMobbex*/], 
         },
         {
            path: 'account',
            loadChildren: ()=> import('./AdminAccount/AdminAccount.module').then(m => m.AdminAccountModule),
            canActivate: [AuthGuard],
         },
         {
            path: 'preguntas',
            loadChildren: ()=>import('./Preguntas/Preguntas.module').then (m => m.PreguntasAdminModule),
            canActivate: [AuthGuard/*,AuthMobbex*/]
         },
         {
            path:'fqa',
            loadChildren: ()=>import('@app/pages/AdminPanel/preguntasFrecuentes/preguntasFrecuentes.module').then(m=>m.PreguntasFrecuentesModule)
         },
         {
            path:'chat/venta/:carrito_id',
            loadChildren: ()=>import('./ChatVendedor/chatVendedor.module').then(m=>m.chatVendedorModule)
         },
         {
            path:'mensajes',
            loadChildren: ()=>import('./mensajes/mensajes.module').then(m=>m.MensajesModule)
         },
         {
            path:'publicidades',
            loadComponent: ()=>import('src/app/Pages/AdminPanel/publicidades/publicidades.component').then(c=>c.PublicidadesPageComponent)
         },
         {
            path:'publicidades/crear',
            loadComponent: ()=>import('src/app/Pages/AdminPanel/publicidades/publicidadesCrear/publicidadesCrear.component').then(c=>c.PublicidadesCrearComponent)
         },
         {
            path:'publicidades/imagenes/:publicidadID',
            loadComponent: ()=>import('src/app/Pages/AdminPanel/publicidades/publicidadesImagenes/publicidades-imagenes.component').then(c=>c.PublicidadesImagenesComponent)
         },
         {
            path:'publicidades/detalle/:publicidadID',
            loadComponent: ()=>import('src/app/Pages/AdminPanel/publicidades/publicidadesDetalle/publicidad-vendedor-detalle.component').then(c=>c.PublicidadVendedorDetalleComponent),
            data:{editar:false}
         },
         {
            path:'publicidades/editar/:publicidadID',
            loadComponent: ()=>import('src/app/Pages/AdminPanel/publicidades/publicidadesDetalle/publicidad-vendedor-detalle.component').then(c=>c.PublicidadVendedorDetalleComponent),
            data: {editar:true}
         }
      ]
   }
]