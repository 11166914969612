<div *ngIf="session.estado == 'conectado'">
  <button mat-mini-fab class="log-in-user" [matMenuTriggerFor]="loginuser" [matBadge]="mensajesSinLeer" matBadgeColor="accent" [matBadgeHidden]="hiddenBadge">
    <img appLazyLoad loading="lazy" height="35" width="35" style="height: 100%" class="log-in-user" [src]="imgUser">
  </button>
  <mat-menu #loginuser="matMenu">
    <button *ngIf="controlComprador" mat-menu-item fxLayoutAlign="start center" [routerLink]="['/account/profile']"><i class="material-icons mr-1">account_circle</i> <span>Perfil</span>
    </button>
    <button *ngIf="controlComprador"  mat-menu-item [routerLink]="['/account/order-history']"><i class="material-icons mr-1">history</i><span matBadgeColor="accent" [matBadgeHidden]="hiddenBadge" [matBadge]="mensajesSinLeer">Mis Compras</span></button>
    <button *ngIf="controlComprador"  mat-menu-item [routerLink]="['/account/mis-pedidos']"><i class="material-icons mr-1">list_alt</i><span>Mis Pedidos</span></button>
    <!--<button mat-menu-item [routerLink]="['/account/profile']"> <i class="material-icons mr-1">local_post_office</i> <span>Mensajes</span></button>-->
    <button mat-menu-item (click)="desconectarSession()"><i class="material-icons mr-1">power_settings_new</i><span>Cerrar Sesión</span></button>
  </mat-menu>
</div>

<div class="row" *ngIf="session.estado != 'conectado' && vistaOrdenador">
  
  <div class="col-12 col-md-6 col-lg-6">
    <button mat-button class="cp btn-iniciar-sesion" [routerLink]="['/session']">
      <span class="material-icons">
        account_circle
        </span> inicie sesión
    </button>
  </div>

  <!--<div class="col-12 col-md-6 col-lg-6 cont-registro">
    <button mat-stroked-button class="cp btn-registro" [routerLink]="['/session/register']">
      <span class="material-icons">
        exit_to_app
        </span>
      Registrarse
    </button>
  </div>-->
</div>
<button *ngIf="session.estado != 'conectado' && !vistaOrdenador" mat-button class="cp btn-iniciar-sesion-celu" [routerLink]="['/session']">
      <span class="material-icons icono-iniciar-celular">
        account_circle
        </span>
</button>
