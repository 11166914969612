import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, inject, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { SelectorCantidadComponent } from '../selector-cantidad/selector-cantidad.component';
import { CarritoService, SessionService, UserInfo } from '@app/_services';
import { Observable } from 'rxjs';

@Component({
  selector: 'embryo-AddToCardButton',
  templateUrl: './AddToCardButton.component.html',
  styleUrls: ['./AddToCardButton.component.scss'],
  standalone:true,
  imports:[
   NgIf,
   MatButtonModule,
   MatDialogModule,
   SelectorCantidadComponent,
  ]
})
export class AddToCardButtonComponent implements OnInit {

   @Input() product : any ;
   @Input() inCart: number
   //@Input() snLogin : boolean //flag para ver si debo agregar producto a carrito o requerir login

   @Output() addToCart: EventEmitter<any> = new EventEmitter();
   @Output() onCantidadChange: EventEmitter<number> = new EventEmitter()
   private dialog = inject(MatDialog)
   private sesionService = inject(SessionService)
   private carritosService = inject(CarritoService)
   protected snLogin: Observable<UserInfo> = this.sesionService.userInfo
   constructor() { }

   ngOnInit() {
   }

   ngOnChanges(changes: SimpleChanges): void {
      if(changes['product']){
         //this.inCart = this.carritosService.checkCartAlready(this.product)
      }
   }

   public addToCartProduct(product:any) {
      this.snLogin.pipe(take(1)).subscribe(res=>{
         if(!res.snSesion){
            this.openLogin()
         }else{
            this.addToCart.emit(product);
         }
      })
   }

   async openLogin(){
      const {ModalLoginComponent} = await import('src/app/theme/components/Modal-login/modal-login.component')
      let dialogRef = this.dialog.open(ModalLoginComponent)
      dialogRef.afterClosed().pipe(take(1)).subscribe(r=>{
         if(r){
            this.addToCart.emit(this.product)
         }
      })
   }

   cantidadChange(event){
      this.onCantidadChange.emit(event)
   }
}
