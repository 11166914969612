import { Routes } from '@angular/router';

import { PreguntasAdminComponent } from './preguntas/preguntas.component';


export const PreguntasRoutes : Routes = [
        { 
                path: 'listado', 
                component: PreguntasAdminComponent,
                pathMatch : 'full'
        },
]