import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { Domicilio } from '@models';
import { DomiciliosService, ProvinciasService } from '@services';

@Component({
  selector: 'app-change-address',
  templateUrl: './change-address.component.html',
  styleUrls: ['./change-address.component.css'],
  providers: [DomiciliosService, ProvinciasService],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeAddressComponent implements OnInit {
  public cargando
  public titulo
  public provincias = [];
  public localidades = [];
  public auxProvincia;
  public auxLocalidad;
  public domicilio: Domicilio;
  public formDireccion: UntypedFormGroup;
  public domicilios: Array<Domicilio>;
  public selectedDom: number;
  public _showButtonConfirmar: boolean;
  public domicilioCompleto: string;
  public _showMap: boolean;
  private localStorageService = inject(LocalStorageService)
  constructor(
    public dialogRef: MatDialogRef<ChangeAddressComponent>,
    public formBuilder: UntypedFormBuilder,
    public service_provincia: ProvinciasService,
    public service_domicilio: DomiciliosService,
    private router: Router
  ) {

    this._showMap = false;
    this.domicilios = []
    this.formDireccion = this.formBuilder.group({
      ID: [''],
      provinciaID: ['', Validators.required],
      localidadID: ['', Validators.required],
      codigoPostal: ['', Validators.compose([Validators.min(1000), Validators.max(9500), Validators.required])],
      direccion: ['', Validators.required],
      numero: ['',[Validators.required]],
      direccionPiso: ['',],
      depto: ['',],
      comentarios: ['',],
      latitud: [],
      longitud: [],
    });
    this.selectedDom = null;
    this._showButtonConfirmar = true

    /*this.formDireccion.get("numero").valueChanges.subscribe(value =>
      this.formarDireccion()
    )
    this.formDireccion.get("direccion").valueChanges.subscribe(value =>
      this.formarDireccion()
    )
    this.formDireccion.get("localidadID").valueChanges.subscribe(value =>
      this.formarDireccion()
    )
    this.formDireccion.get("provinciaID").valueChanges.subscribe(value =>
      this.formarDireccion()
    )*/
  }

  ngOnInit(): void {
    this.domicilio = new Domicilio();

    //para añadir nueva
    //this.cargarProvincias();
    this.cargarDirecciones()
  }

  /*async cargarProvincias() {
    this.cargando = true
    let result = await this.service_provincia.getProvincias().toPromise();
    this.provincias = result['data'];
    this.cargando = false
  }*/

  /*async cargarLocalidades(provinciaID) {
    this.cargando = true
    this.localidades = []
    let result = await this.service_provincia.getLocalidadesProvinciaID(provinciaID).toPromise();
    if (result["code"] == 200) {
      this.localidades = result['data'];
    }
    this.cargando = false
  }*/

  /*async seleccionarProvincia(event) {
    // LIMPIO LOCALIDADES POR SI LAS CARGARON ANTES
    this.localidades = [];

    // BUSCO LA PROVINCIA SELECCIONADA EN EL ARREGLO DE PROVINCIAS PARA SACARLE EL NOMBRE
    let provincia = this.provincias.filter(function (provincia) {
      return provincia.ID == event.value
    })

    // LLENO ATRIBUTO OBLIGATORIO DE DIRECCION
    this.domicilio.provinciaID = event.value;

    // LLENADO DE VARIABLE AUXILIAR DE PROVINCIA PARA LUEGO CONCATENARLA CON LA DIRECCION COMPLETA EN TIPO STRING
    this.auxProvincia = provincia[0].nombre;

    // PETICION PARA TRAER LAS LOCALIDESDE DE LAS PROVINCIAS SELECCIONADAS
    let result = await this.service_provincia.getLocalidadesProvinciaID(event.value).toPromise();
    if (result["code"] == 200) {
      this.localidades = result['data'];
    }
  }*/

  agregarDirecciones(){
    this.router.navigate(['account/address'])
    this.dialogRef.close()
  }

  async seleccionarLocalidad(event) {

    // BUSCO LA LOCALIDAD SELECCIONADA EN EL ARREGLO DE LOCALIDADES
    let localidad = this.localidades.filter(function (localidad) {
      return localidad.ID == event.value
    })

    // LLENO ATRIBUTO OBLIGATORIO DE DIRECCION
    this.domicilio.localidadID = event.value;

    // LLENADO DE VARIABLE AUXILIAR DE PROVINCIA PARA LUEGO CONCATENARLA CON LA DIRECCION COMPLETA EN TIPO STRING
    this.auxLocalidad = localidad[0].nombre;
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  /* CÓDIGO NUEVO */
  async cargarDirecciones() {

    try {
      let id = this.localStorageService.getUserInfo().ID
      const result = await this.service_domicilio.getPersonaID(id).toPromise()
      if (result['code'] == 200) {
        console.log("direcciones", result)
        this.domicilios = result['data']
      }else{
        this._showButtonConfirmar = false
      }
    } catch (error) {
      this._showButtonConfirmar = false
      //error
      //console.error("Error en Modal", error)
    }

  }

  async clickEnCard(index) {
    this._showMap= false
    let personaID = this.localStorageService.getUserInfo().ID

    console.log("index", index, this.domicilios[index])
    //this.formDireccion.reset()
    //this._showButtonConfirmar = true;
    this.domicilios.forEach(dom => {
      dom.last = false
    })

    this.domicilios[index].last = true;
    /*this.formDireccion.patchValue({
      ID: this.domicilios[index].ID,
      provinciaID: this.domicilios[index].provincia.ID,
      numero: this.domicilios[index].numero,
      codigoPostal: this.domicilios[index].codigoPostal,
      direccion: this.domicilios[index].direccion,
      comentarios: this.domicilios[index].comentarios
    })*/

    //let result = await this.service_provincia.getLocalidadesProvinciaID(this.domicilios[index].provincia.ID).toPromise();

    /*if (result["code"] == 200) {
      this.localidades = [];
      this.localidades = result['data'];

      this.formDireccion.patchValue({
        localidadID: this.domicilios[index].localidad.ID,
      })
    }*/

    /*let dir = this.formDireccion.get('direccion').value
    let locId = this.formDireccion.get('localidadID').value
    let loc = this.localidades.filter(loc => loc.ID == locId)[0].nombre
    let provId = this.formDireccion.get('provinciaID').value
    let prov = this.provincias[provId-1].nombre
    let numero = this.formDireccion.get('numero').value
    this.domicilioCompleto = dir+' '+numero+', '+prov+', '+loc+', Argentina'*/
    this.domicilio = this.domicilios[index]
    this.actualizarUltima(personaID,this.domicilios[index].ID)
    this.actualizarDomicilioLocalStorage()
    this.dialogRef.close(this.domicilios[index].ID)
    this._showMap= true
    //console.log("this.form", this.formDireccion.value)
  }

  resetForm() {

    this.formDireccion.reset()
    this._showButtonConfirmar = false
  }

  public cambiarCoordenadas(coordenadas){
    console.log(coordenadas)
    this.domicilio.latitud = coordenadas.lat 
    this.domicilio.longitud = coordenadas.lng

    this.formDireccion.patchValue({
      latitud: this.domicilio.latitud,
      longitud: this.domicilio.longitud
    })

    console.log("domicilio",this.domicilio )
  }

  

  async actualizarUltima(idPersona, idDomicilio) {
    console.log("idpersona", idPersona,'id domicilio', idDomicilio)
    try {
      const result2 = await this.service_domicilio.patchLast(idPersona, idDomicilio).toPromise()
      console.log("result en actualizar última", result2, idPersona, idDomicilio)
    } catch (error) {
      console.error("Error actualizar última", error)
    }
  }

  actualizarDomicilioLocalStorage(){
    localStorage.setItem('domicilio_ID', this.domicilio.ID)
    localStorage.setItem('domicilio_codigoPostal', this.domicilio.codigoPostal)
    localStorage.setItem('domicilio_direccion', this.domicilio.direccion)
    localStorage.setItem('domicilio_latitud', this.domicilio.latitud)
    localStorage.setItem('domicilio_longitud', this.domicilio.longitud)
    localStorage.setItem('domicilio_localidadID', String(this.domicilio.localidad.ID))
    localStorage.setItem('domicilio_provinciaID', String(this.domicilio.provincia.ID))

    localStorage.setItem('domicilio_depto', this.domicilio.depto != null ?this.domicilio.depto :"" )
    localStorage.setItem('domicilio_numero', String(this.domicilio.numero != null ?this.domicilio.numero :""))
    localStorage.setItem('domicilio_piso', String(this.domicilio.piso != null ?this.domicilio.piso :""))
  }

}
