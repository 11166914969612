
<!--<embryo-PageTitle heading="Here’s what’s in your bag." subHeading="Our latest news and learning articles."></embryo-PageTitle>-->
<img appLazyLoad loading="lazy" src="../../../../assets/images/checkout.jpg">
<ng-container *ngIf="carrito|async as carrito; else elseBlock">

  <div class=" bg-white cart-page section-gap" *ngIf="carrito && carrito.publicaciones.length>0; else elseBlock">
    <div class="container">
      <mat-button-toggle-group [formControl]="modoCompra" *ngIf="($vendedor | async)?.snPrecompra && ($vendedor | async) as vendedor ">
        <mat-button-toggle *ngIf="vendedor.snMobbex" value="compra">Compra</mat-button-toggle>
        <mat-button-toggle value="precompra" color="primary">Pre-compra</mat-button-toggle>
      </mat-button-toggle-group>
      <div class="cart-wrapper">
        <div class="cart-shop-list mb-5 text-center  card-shadow">
          <div fxLayout='row wrap' fxLayoutAlign="start center" class="cart-item" *ngFor="let product of carrito.publicaciones">
            <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="10" fxFlex.lg="20" fxFlex.xl="20" class="cart-product-image">
              <div class="cart-item-thumb">
                <a href="javascript:void(0)" class="p-link">
                  <img appLazyLoad loading="lazy" width="626" height="800" class="card-shadow" [src]="appSettings.urls.hostFiles+product.imgPortada+appSettings.imgXS" alt="cart image">
                </a>
              </div>
            </div>
            <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="90" fxFlex.lg="80" fxFlex.xl="80">
              <div fxLayout='row wrap' fxLayoutAlign="center center" class="cart-product-info">
                <div fxFlex.xs="100" fxFlex.sm="40" fxFlex.md="30" fxFlex.lg="40" fxFlex.xl="40">
                  <h5>{{product?.titulo}}</h5>
                  <!--<p class="mb-0">Delivery in 3-4 days | Free</p>
                  <p> 10 Days Replacement Policy</p>-->
                </div>
                <div fxFlex.xs="50" fxFlex.sm="20" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20">
                  <mat-form-field class="w-100">
                    <input disabled matInput type="number" #quantity [value]="getQuantityValue(product)" min="1" max="10" placeholder="Cantidad">
                  </mat-form-field>
                </div>
                <div fxFlex.xs="50" fxFlex.sm="40" fxFlex.md="30" fxFlex.lg="20" fxFlex.xl="20">
                  <div *ngIf="product.descuento" class="precioSinDescuento">
                    <h6 class="ml-3">${{product.precio*product.cantidad}}</h6>
                  </div>
                  <div class="cart-item-price">
                    <h4>{{ calculateProductSinglePrice(product, quantity.value) | currency:embryoService?.currency}}</h4>
                  </div>
                </div>
                <div class="close-sm" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20">
                  <div class="cart-item-price">
                    <a href="javascript:void(0)" class="accent-color remove-cart" (click)="removeProduct(product)"><i class="material-icons">
                        close
                      </i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cart-total" fxLayout='row wrap' fxLayoutAlign="space-between">
          <div class="warning-container" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="40" fxFlex.lg="40" fxFlex.xl="40">
            <div *ngIf="modoCompra.value=='compra'" class="card container-bordered">
              <span>Tu pago esta protegido hasta que recibas tu compra.</span>
              <span>Recomendaciones:</span>
              <ul>
                <li>Luego de confirmar la compra se habilitara un chat interno para puedas  comunicarte con el vendedor y acordar detalles de envío o facturación</li>
                <li>Usa el chat interno siempre, para que la conversación quede registrada, así Hiperabasto puede mediar si ocurre un inconveniente.</li>
                <li>Si no recibes tu compra podes iniciar un reclamo al vendedor, hiper abasto te devolverá el dinero si no hay una solución</li>
              </ul>
            </div>
            <div *ngIf="modoCompra.value=='precompra'" class="card container-bordered">
              <span>Solo pagaras a través de la plataforma un 10% de la compra total  (¡Ahorraras en comisiones! )</span>
              <span>Sigue estas recomendaciones a la hora de realizar el pago para evitar  estafas.</span>
              <ul>
                <li>Al finalizar la pre-compra, se te habilitara un chat interno para acordar con tu vendedor el resto del pago.</li>
                <li>Usa el chat interno siempre, para que la conversación quede registrada ,así Hiperabasto puede mediar si ocurre un inconveniente.</li>
                <li>Una vez acordada la forma y método de pago con el vendedor por el saldo pendiente, realiza el pago.</li>
                <li>Verifica el nombre de la razón social de la empresa con el nombre de la cuenta bancaria  antes  de realizar los pagos al vendedor. Evita estafas.</li>
              </ul>
            </div>
          </div>
          <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="40" fxFlex.lg="40" fxFlex.xl="40">
            <!--<div>
              <p>Subtotal</p><span fxFlex></span> {{calculateTotalPrice() | currency:embryoService?.currency}}
            </div>-->
            <!--<div>-->
              <!--<p>Impuestos</p><span fxFlex></span> 0--> <!--{{embryoService.shipping | currency:embryoService?.currency}} -->
            <!--</div>
            <div>
              <p>Otro Impuesto</p><span fxFlex></span> 0--><!-- {{embryoService.tax | currency:embryoService?.currency}} -->
            <!--</div>-->
            <ng-container *ngIf="modoCompra.value=='compra' && ($vendedor|async) as vendedor">
              <hr class="spacer">
              <div>
                <h4>Subtotal</h4><span fxFlex></span>
                <h4> {{carrito.subtotal | currency:embryoService?.currency}}</h4>
              </div>
              <div *ngFor="let comision of comisiones">
                <ng-container *ngIf="comision.snCompra">
                  <h4>{{comision.nombre}} ({{comision.porcentaje}}%)</h4><span fxFlex></span>
                  <h4> {{comision.valor | currency:embryoService?.currency}}</h4>
                </ng-container>
              </div>
              <hr class="spacer">
              <div class="mb-4">
                <h4>Total</h4><span fxFlex></span>
                <h4> {{carrito.total | currency:embryoService?.currency}}<!--{{getTotalPrice() | currency:embryoService?.currency}}--></h4>
              </div>
              <div >
                <h6 *ngIf="carrito.total < carrito.vendedor.montoMinimo">El monto minimo de compra en {{carrito.vendedor.empresa}} es de ${{carrito.vendedor.montoMinimo}}</h6>
              </div>
              <div *ngIf="vendedor.snMobbex; else noMobbex"> 
                <span fxFlex></span>
                <button mat-raised-button class="button-lg mr-2" (click)="volverTienda(carrito)">Volver a la tienda</button>
                <button *ngIf="!carrito.vendedor.montoMinimo || carrito.total >= carrito.vendedor.montoMinimo" mat-raised-button color="accent" class="button-lg" (click)="updateLocalCartProduct()">Confirmar Pedido</button>
              </div>
              <ng-template #noMobbex>
                <h6>El vendedor no ha configurado su billetera virtual</h6>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="modoCompra.value=='precompra' && ($vendedor|async) as vendedor">
              <hr class="spacer">
              <div>
                <h4>Subtotal</h4><span fxFlex></span>
                <h4> {{carrito.subtotal | currency}}</h4>
              </div>
              <hr class="spacer">
              <div class="mb-4">
                <h4>Total Pre-compra</h4><span fxFlex></span>
                <h4> {{carrito.subtotalPrecompra | currency}}<!--{{getTotalPrice() | currency:embryoService?.currency}}--></h4>
              </div>
              <div *ngFor="let comision of comisiones">
                <ng-container *ngIf="comision.snPrecompra">
                  <h4>{{comision.nombre}} ({{comision.porcentaje}}%)</h4><span fxFlex></span>
                  <h4> {{comision.valorPrecompra | currency}}</h4>
                </ng-container>
              </div>
              <hr class="spacer">
              <div class="mb-4">
                <h4>A pagar hoy</h4><span fxFlex></span>
                <h4> {{carrito.totalPrecompra | currency}}<!--{{getTotalPrice() | currency:embryoService?.currency}}--></h4>
              </div>
              <div >
                <h6 *ngIf="carrito.total < vendedor.montoPrecompra">El monto mínimo de Pre-compra en {{carrito.vendedor.empresa}} es de {{vendedor.montoPrecompra|currency}}</h6>
              </div>
              <div> <span fxFlex></span>
                <button mat-raised-button class="button-lg mr-2" (click)="volverTienda(carrito)">Volver a la tienda</button>
                <button *ngIf="carrito.total >= vendedor.montoPrecompra" mat-raised-button color="accent" class="button-lg" (click)="updateLocalCartProduct()">Confirmar Pedido</button>
              </div>
            </ng-container>
  
            
            
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #elseBlock>
  <div class="section-gap-lg text-center">
    <div class="mb-4">
    <img appLazyLoad loading="lazy" src="assets/images/empty-cart.png" height="128" width="128" alt="cart-empty">
  </div>
    <h4> Tu carrito de compras esta vacio</h4>
    <a href="javascript:void(0)" class="primary-color" [routerLink]="['/']">Ir de shooping!</a>
  </div>
</ng-template>

