import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EmbryoService, SessionService } from '@app/_services';
import { FavoritosService } from '@app/_services/favoritos.service';
import { AppSettings } from '@settings';
@Component({
  selector: 'embryo-WishList',
  templateUrl: './WishList.component.html',
  styleUrls: ['./WishList.component.scss'],
  providers : [AppSettings],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class WishListComponent implements OnInit, OnChanges {

   @Input() wishListProducts : any;

   @Input() count        : number;

   @Input() currency      : string;

   @Output() removeWishListData : EventEmitter<any> = new EventEmitter();

   //@Output() addAllWishlistToCart : EventEmitter<any> = new EventEmitter();

   //@Output() addToCart: EventEmitter<any> = new EventEmitter();

   @Output() detectChanges : EventEmitter<any> = new EventEmitter();

   hiddenBadge = true;

   constructor(
      public appSettings:AppSettings,
      public router:Router,
      private _favoritos_service: FavoritosService,
      public embryo:EmbryoService,
      private cdr : ChangeDetectorRef
   ) { }

   ngOnInit() {
      let userID = localStorage.getItem('comprador_id')
      if(userID){
         this.cargarFavoritos(userID)
      }

      this._favoritos_service.favoritosChange.subscribe(favChange=>{
         console.log('fav change')
         this._favoritos_service.calculateLocalWishlistProdCounts()
      })
      
   }

   ngOnChanges() {
      if(this.count && this.count != 0) {
         this.hiddenBadge = false;
      } else {
         this.hiddenBadge = true;
      }
   }

   async cargarFavoritos(idComprador) {
      let result = await this._favoritos_service.getFavoritos(idComprador)
      let products = []
      result['data']?.forEach(favorito => {
        favorito.publicacion.idFavorito = favorito.ID
        products.push(favorito.publicacion)
      });
      localStorage.setItem("wishlist_item", JSON.stringify(products))
      this._favoritos_service.calculateLocalWishlistProdCounts()
    }

   public verProducto(id){
      this.router.navigate(['products/id',id]);
   }

   public confirmationPopup(product:any) {
      this.removeWishListData.emit(product);
   }

   /*public addAllToCart() {
      this.addAllWishlistToCart.emit(this.wishListProducts);
   }*/

   public calculatePrice(product) {
      let total = null;
      total = product.price*product.quantity;
      return total;
   }

   /*public addToCartProduct(product) {
      this.addToCart.emit(product);
   }*/

}
