<div style="position: relative;">
    <span class="material-icons cancel-button" (click)="cerrarModal()" >close</span>
</div>
<h2 mat-dialog-title *ngIf="snGratis">¿Como funciona el envío gratis?</h2>
<h2 mat-dialog-title *ngIf="!snGratis">¿Que significa este precio?</h2>
<mat-dialog-content>
    <div *ngIf="snGratis">
        <div class="row">
            <div class="col-12">
                <span class="text-envio">Agregando esta cantidad de productos al carrito ya completas el monto para envío gratis, por lo tanto todo lo que compres a este vendedor también tendrá envío gratis</span>
            </div>
        </div>
    </div>

    <div *ngIf="!snGratis">
        <div class="row">
            <div class="col-12">
                <span class="text-envio">Este precio de envío es calculado solo para esta publicación, el precio de envío del carrito completo podría ser menor por  volumen.</span>
            </div>
        </div>
    </div>
</mat-dialog-content>