export class Sucursal{
    constructor(){
        this.provincia = {'ID':0,'nombre':'nombre'}
        this.localidad = {'ID':0,'nombre':'nombre'}
        this.vendedor = {'ID':0,'empresa':''}
    }

    ID
    vendedor : {
        'ID',
        'empresa': string
    }
    vendedorID;
    provinciaID;
    localidadID;
    provinciaNombre;
    localidadNombre;
    longitud;
    latitud;
    direccion;
    numero; //numero de la direccion
    depto;
    piso;
    snEnvio = true; //por ahora solo tendremos sucursales que realicen envios, no hay opcion retiro en sucursal
    codigoPostal;
    comentarios;
    encargado;
    telefono;
    email;
    horario_corte;
    //voy a repetir datos para poder respetar la estructura de la api, que usa una forma para la edicion de una sucursal 
    //y otra para la representacion del get, para el post pide provinciaID hy localidadID pero en el get me trae 
    //provincia:{ID:id, nombre:provincia}
    provincia:{
        'ID':number,
        'nombre':string
    }
    localidad:{
        'ID':number,
        'nombre':string
    }
}