import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { EmbryoService, SessionService } from '@services';


@Component({
  selector: 'embryo-SidebarMenu',
  templateUrl: './SidebarMenu.component.html',
  styleUrls: ['./SidebarMenu.component.scss'],
  providers:[],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarMenuComponent implements OnInit {
   
   expanded       : boolean = false;
   @Input() item  : any;
   @Input() depth : number;
   @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;

   flagVendedor;
   flagComprador
   private localStorageService = inject(LocalStorageService)


   constructor(public router: Router, 
               public embryoService: EmbryoService, 
               public service_session:SessionService) {

      if (this.depth === undefined) {
         this.depth = 0;
      }
   }

   ngOnInit() {
      if(this.localStorageService.getVendedorInfo().ID)
        this.flagVendedor = true;

      if(this.localStorageService.getCompradorInfo().ID){
        this.flagComprador = true;
      }

    this.service_session.estadoUsuarioInterno.subscribe(user=>{
      if(user){
        if(this.localStorageService.getVendedorInfo().ID){
          this.flagVendedor = true;
        }else{
          this.flagVendedor = false;
        }

        if(this.localStorageService.getCompradorInfo().ID){
          this.flagComprador = true
        }else{
          this.flagComprador = false
        }
      }
    })
   }

   onItemSelected(item: any) {
     item.active = true
     console.log(item)
      if (!item.children || !item.children.length) {
         if(item.type == 'link'){
            this.router.navigate([item.state]);
         } else {
            this.router.navigate([item.state],{ queryParams:{ category: item.queryState }});
         }
         this.embryoService.sidenavOpen = false;
      }
      if (item.children && item.children.length) {
         this.expanded = !this.expanded;
      }
   }
}
