<mat-toolbar class="embryo-fixed-header colorDegradadoOscuro">
  <mat-toolbar-row fxHide.xs="true" fxHide.sm="true">
    <div class="container">
      <div fxLayoutAlign="space-between">
        <!--<div class="logo-site">
          <embryo-AppLogo></embryo-AppLogo>
        </div>-->
        <embryo-Menu (detectChanges)="detectChanges()"></embryo-Menu>
        <!--<button class="responsive-toggle boton-menu" mat-icon-button (click)="toggleSidebar()">
          <i class="material-icons">menu</i>
        </button>-->

      </div>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="responsive-bar relative colorDegradadoOscuro p-0">
    <div class="">
      <div fxLayoutAlign="space-between">
       <div fxFlex.sm="20">
         <button  class="responsive-toggle" mat-icon-button (click)="toggleSidebar()">
           <i class="material-icons">menu</i>
         </button>
       </div>
         <div fxFlex.sm="60" fxFlex.md="40" fxFlex.sm.hdie fxFlex.lg="60%" fxFlex.xl="60%" class="align-center">
           <div class="search-v2">
             <form action="">
               <input #busquedaMobil type="text" class="buscador-celular" (keyup.enter)="buscar(busquedaMobil.value)">
             </form>
           </div>
         </div>
         <div fxFlex.sm="20" class="ml-2">
           <embryo-HeaderUserProfileDropdown></embryo-HeaderUserProfileDropdown>
         </div>
         
        <!--<div>
            <div class="search-box-bar">
              <button type="button" mat-mini-fab (click)="toggleSearch()">
                <i class="material-icons notranslate">
                  search
                </i>
              </button>
            </div>
            <div class="search-form">
              <form>
                <input type="text" placeholder="Search and hit enter">
                <button type="button" mat-fab class="close-btn" (click)="toggleSearch()">
                  <i class="material-icons">
                    close
                  </i>
                </button>
              </form>
            </div>
          </div>-->
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
