import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppSettings } from '@settings';
import { Router } from '@angular/router';
import { Carrito, CarritoPublicacion } from '@app/_models';
@Component({
   selector: 'embryo-HeaderCart',
   templateUrl: './HeaderCart.component.html',
   styleUrls: ['./HeaderCart.component.scss'],
   providers: [AppSettings]
})
export class HeaderCartComponent implements OnInit, OnChanges {

   @Input() cartProducts : Array<Carrito>;
   @Input() count        : any;
   @Input() currency     : string;

   mobWidth : any;
   mobScreenSize : number = 767;

   @Output() removeProductData : EventEmitter<any> = new EventEmitter(); 
   @Output() detectChanges : EventEmitter<any> = new EventEmitter(); 

   hiddenBadge = true;

   constructor(
      public appSettings : AppSettings,
      public router:Router
      ) {
      this.mobWidth = window.screen.width;
   }
   
   
   ngOnInit() {
      console.log(this.cartProducts)
      
   }

   ngOnChanges() {
      if(this.count && this.count != 0) {
         this.hiddenBadge = false;
      } else {
         this.hiddenBadge = true;
      }
   }

   public confirmationPopup(publicacion:CarritoPublicacion) {
      this.removeProductData.emit(publicacion);
   }

   public calculatePrice(product) {
      //console.log('calculando precio',product.precio, product.cantidad)
      //console.log(product)
      let total = null;
      if(product.precios){
         let descuentoAplicado = false
         product.precios?.forEach(precio => {
            if (precio.cantidad <= product.cantidad && !descuentoAplicado) {
               descuentoAplicado = true
               total = (product.precio * product.cantidad) - ((product.precio * product.cantidad * precio.descuento) / 100)
            }
          });
          if(!descuentoAplicado){
            total = product.precio *product.cantidad;
          }
      }else{
         total = product.precio *product.cantidad;
      }
      return total;
   }
   /*
   public checkOut(idVendedor){
      this.router.navigate(['/checkout/payment',idVendedor])
   }
   */
}