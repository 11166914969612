import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "@app/app.settings";
import { map } from 'rxjs/operators';

var headers = new Headers();
headers.append("Content-Type", "application/json");

@Injectable()
export class MobbexService {
    host = this.appSettings.urls['host'] // variable global con la ip de la api.
    

    constructor(private http:HttpClient,
                public appSettings:AppSettings
                )
    {
    }
    public generarValidador(idVendedor){
        return this.http.patch(`${this.host}/personas/vendedores/mobbex/id/${idVendedor}`,{}).pipe(map(res=>res))
    }

    public getCredenciales(idVendedor){
        return this.http.patch(`${this.host}/personas/vendedores/mobbex/credentials/id/${idVendedor}`,{}).pipe(map(res=>res))
    }
}