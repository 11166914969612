import {Propiedad} from './propiedad'
export class Etiqueta{

    constructor(){
        this.propiedades = new Array<Propiedad>()
    }
    id : number
    nombre : string
    etq: string
    required:boolean
    tipo: string
    children : Etiqueta
    propiedades = []
    col:number
    value:any
    idSelected:number
    valorSelected:string
    maxlenghtflag:boolean
}