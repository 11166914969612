<mat-card matRipple [matRippleDisabled]="true"  class="publicacion-card" >
   <mat-card-content>
      <div class="d-flex justify-content-between" >
         <div class="offer-badge">
            <mat-chip color="primary" *ngIf="mayorDescuento>0" selected >Hasta -{{mayorDescuento}}%</mat-chip>
         </div>
         <div class="{{i}}-wishlist" id="{{i}}-wishlist" *ngIf="!isVendedor">
            <a (click)="productAddToWishlist(product, i+'-wishlist')"><i [ngClass]="{'favorito-icon-selected': product.inWishList}" class="material-icons favorito-icon">
                  favorite </i></a>
         </div>
      </div>
   <div class="d-flex flex-row flex-md-column">
      <div class="box" (click)="navegar(product.ID)">
         <img appLazyLoad loading="lazy" width="221" height="300" src="{{appSetting.urls.hostFiles+product?.imgPortada+appSetting.imgSM}}" alt="imagen-producto">
      </div>
      <div class="product-content">
         <h5 style="margin-bottom: 0px;"><a *ngIf="product?.ID" (click)="navegar(product.ID)"
               class="tituloPublicacion">{{product?.titulo}}</a> </h5>
         <small *ngIf="snTienda">Tienda: <span style="font-weight: 600;">{{product?.vendedor?.empresa}}</span></small>
         <div class="compraMinimaContainer" *ngIf="product.minPrecio && product.compraMinima>1">
            <small class="compraMinima">Compra minima:
               {{product.minPrecio | customCurrency}}
               ({{product.compraMinima}} {{product.unidadesMedida.siglas}})
            </small>
         </div>
         <div class="d-flex justify-content-between mb-3 align-items-center">
            <div class="label-precio mr-3">
               <h4 class="precioPublicacion m-0">{{product.precio | customCurrency}}</h4>
            </div>
            <div class="add-to-cart-container" *ngIf="(userInfo | async)?.tipoUsuario != 'VENDEDOR' && snCheckout">
               <embryo-AddToCardButton [inCart]="checkCartAlready(product)" [product]="product" (addToCart)="addToCartProduct($event)" (onCantidadChange)="cantidadChange($event)">
               </embryo-AddToCardButton>
            </div>
         </div>
         <button class="w-100" mat-stroked-button color="primary" [routerLink]="['/products/id',product.ID]" fragment="preguntas">Consultar</button>
      </div>
   </div>
            
                     
         
         
         
         <!--<div class="sumador-container" *ngIf="!checkCartAlready(product) && snComprador &&snCheckout">
            <span>En carrito:</span>
            <div class="input-button">
               <button class="button" (click)="descontarCarrito(product)"><mat-icon>remove_circle</mat-icon></button>
               <input class="input" type="number" [(ngModel)]="product.cantidadSeleccionada" (blur)="cambiarCantidadCarrito(product,$event)">
               <button class="button" (click)="sumarCarrito(product)"><mat-icon>add_circle</mat-icon></button>
            </div>
            <mat-error *ngIf="sinStock">No hay stock suficiente</mat-error>
            <mat-error *ngIf="menorAMinimo">No se permiten menos de {{product.compraMinima}}</mat-error>    
         </div>-->
   
         
      </mat-card-content>
</mat-card>


