import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PublicacionesService } from '@services';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface DialogData {
  data: Array<any>
}

@Component({
  selector: 'app-modification-products-batch',
  templateUrl: './modification-products-batch.component.html',
  styleUrls: ['./modification-products-batch.component.scss'],
  providers: [ PublicacionesService]
})

export class ModificationProductsBatchComponent implements OnInit {

  public form: UntypedFormGroup;
  public operaciones: Array<any>;
  public tiposDeMonto: Array<any>;
  public modelValor: number = null;
  public modelCant: number = null;
  public _showButtonSave: boolean;
  public eliminar_existencias;
  

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ModificationProductsBatchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private service_publicaciones: PublicacionesService,
    private _snackBar: MatSnackBar,
  ) {
    this.form = this.formBuilder.group({
      operacion: ["",],
      tipoMonto: ["",],
      valor: ["",],
      cant: ["",],
      operacion_stock: ["",]
    })
    this._showButtonSave = true
    this.eliminar_existencias = false
    this.operaciones = [
      {
        name: "Aumentar",
        value: "+"
      },
      {
        name: "Disminuir",
        value: "-"
      },
      {
        name: "Asignar",
        value: "="
      }
    ];

    this.tiposDeMonto = [
      {
        name: "$ (monto fijo)",
        value: "$"
      },
      {
        name: "% (monto porcentual)",
        value: "%"
      },
    ];

  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async guardar() {
    if (this.form.valid) {
      //console.log("hodsda", this.data)
      let precio = null;
      let stock = null;
      if (this.form.get("valor").value != null) {
        precio = {
          op: this.form.get('operacion').value,
          tipo: this.form.get('tipoMonto').value,
          cant: this.form.get('valor').value
        }
      }
      if (this.form.get('cant').value != null) {
        stock = {
          op: this.form.get('operacion_stock').value,
          cant: this.form.get('cant').value
        }
      }
      let code;
      if(precio != null || stock != null){
        try {
          const result = await this.service_publicaciones.putManyPrecioStock(precio, stock, this.data.data).toPromise()
          console.log("result", result)
          code = result['code']
        } catch (error) {
          console.error(error)
        }
      this.dialogRef.close(code);

      }else{
        this._snackBar.open('Seleccione cancelar si no desea cambiar el precio o el stock', 'Entendido', {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
        })
      }



    } else {
      console.log("no válido")
    }
  }

  clearSelection() {
    console.log("hola e")
    this.form.controls['operacion_stock'].setValidators([])
  }

  selectionChangeOpPrice() {

    this._showButtonSave = false

    if (this.form.get("operacion").value == "=") {
      this.form.patchValue({
        tipoMonto: "$"
      })
      this.form.controls["tipoMonto"].disable()


    } else {
      this.form.controls["tipoMonto"].enable()


    }
    this.form.get("operacion").setValidators([Validators.required])
    this.form.get("tipoMonto").setValidators([Validators.required])
    this.form.get("valor").setValidators([Validators.required])

    this.form.get("operacion").updateValueAndValidity()
    this.form.get("tipoMonto").updateValueAndValidity()
    this.form.get("valor").updateValueAndValidity()
  }

  selectionChangeMonto() {
    this._showButtonSave = false
    this.form.get("operacion").setValidators([Validators.required])
    this.form.get("tipoMonto").setValidators([Validators.required])
    this.form.get("valor").setValidators([Validators.required])

    this.form.get("operacion").updateValueAndValidity()
    this.form.get("tipoMonto").updateValueAndValidity()
    this.form.get("valor").updateValueAndValidity()
  }

  selectionChangeOpStock() {
    this._showButtonSave = false

    this.form.get('operacion_stock').setValidators([Validators.required]);
    this.form.get('cant').setValidators([Validators.required])

    this.form.get("cant").updateValueAndValidity()
    this.form.get("operacion_stock").updateValueAndValidity()
  }

  updateControlValor() {
    console.log("update", this.modelValor)
    if (this.modelValor != null) {
      this.form.controls['valor'].setValidators([Validators.required])
      this.form.get("operacion").setValidators([Validators.required])
      this.form.get("tipoMonto").setValidators([Validators.required])

      this.form.get("operacion").updateValueAndValidity()
      this.form.get("tipoMonto").updateValueAndValidity()
      this.form.get("valor").updateValueAndValidity()

    } else {
      this.form.controls['valor'].setValidators([])
      this.form.get("operacion").setValidators([])
      this.form.get("tipoMonto").setValidators([])

      this.form.get("operacion").updateValueAndValidity()
      this.form.get("tipoMonto").updateValueAndValidity()
      this.form.get("valor").updateValueAndValidity()
    }
  }

  updateControlCant() {

    if (this.modelCant != null) {
      this.form.controls['cant'].setValidators([Validators.required])
      this.form.get("operacion_stock").setValidators([Validators.required])

      this.form.get("operacion_stock").updateValueAndValidity()
      this.form.get("cant").updateValueAndValidity()

    } else {
      this.form.controls['cant'].setValidators([])
      this.form.get("operacion_stock").setValidators([])

      this.form.get("operacion_stock").updateValueAndValidity()
      this.form.get("cant").updateValueAndValidity()
    }
  }

  eliminarExistencias(){
    console.log(this.eliminar_existencias)
    if(this.eliminar_existencias){

      this.form.controls['operacion_stock'].disable()
      this.form.controls['cant'].disable()
      this.form.patchValue({
        operacion_stock: "=",
        cant: 0
      })
    }else{
      this.form.controls['operacion_stock'].enable()
      this.form.controls['cant'].enable()
    }
  }
}
