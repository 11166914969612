<mat-toolbar color="primary" class="header-v2">
   <mat-toolbar-row class="header-v2-top colorDegradado claseindex">
     <div class="container">
       <div fxLayout="row wrap">
         <div class="logo-site" fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="35" fxFlex.lg="25%" fxFlex.xl="25%">
          <embryo-AppLogo></embryo-AppLogo>
         </div>
         <div fxFlex.sm="60" fxHide.xs="true" fxHide.sm="true" fxFlex.md="40" fxFlex.sm.hdie fxFlex.lg="60%" fxFlex.xl="60%" class="align-center">
            
            <div class="search-v2">
               <form [formGroup]="formBusqueda">
                  <input  #busqueda type="text" placeholder="" (keyup.enter)="buscar(busqueda.value)" >
                  <!--<app-autocomplete placeholder="" (onQuerySuggestionClick)="setQuery($event)"></app-autocomplete>-->
               </form>
            </div>
         </div>
         <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="25" fxFlex.lg="15%" fxFlex.xl="15%">
       <span fxFlex></span>
           <div class="list-flex user-tool">
             <embryo-HeaderCart *ngIf="comprador == 1" [currency]="embryoService?.currency" [cartProducts]="$carritos | async" [count]="$cantCarrito | async" (removeProductData)="openConfirmationPopup($event)"></embryo-HeaderCart>
            <embryo-notifications *ngIf="comprador == 1 || vendedor == 1" [count]="$cantNotif | async"></embryo-notifications>
             <embryo-WishList *ngIf="comprador == 1" [currency]="embryoService?.currency" [wishListProducts]="_serviceFavoritos?.localStorageWishlist" [count]="$cantFav | async" (removeWishListData)="openWishlistConfirmationPopup($event)"></embryo-WishList>
             <embryo-HeaderUserProfileDropdown fxHide.xs="true" fxHide.sm="true"></embryo-HeaderUserProfileDropdown>
           </div>
         </div>
       </div>
     </div>
   </mat-toolbar-row>
   <mat-toolbar-row class="header-v2-bottom main-menu-wrap colorDegradadoOscuro menu-horizontal">
     <div class="container">
       <div class="header-v2-bottom-inner">
         <embryo-Menu></embryo-Menu>
    
       </div>
     </div>
   </mat-toolbar-row>
   <mat-toolbar-row class="responsive-bar relative colorDegradadoOscuro p-0">
         <div class="">
           <div fxLayoutAlign="space-between">
            <div fxFlex.sm="20">
              <button  class="responsive-toggle" mat-icon-button (click)="toggleSidebar()">
                <i class="material-icons">menu</i>
              </button>
            </div>
              <div fxFlex.sm="60" fxFlex.md="40" fxFlex.sm.hdie fxFlex.lg="60%" fxFlex.xl="60%" class="d-block align-center">
                <div class="search-v2">
                  <form action="" >
                    <input #busquedaMobil type="text" class="buscador-celular" (keyup.enter)="buscar(busquedaMobil.value)">
                  </form>
                </div>
              </div>
              <div fxFlex.sm="20" class="ml-2">
                <embryo-HeaderUserProfileDropdown></embryo-HeaderUserProfileDropdown>
              </div>
              
             <!--<div>
                 <div class="search-box-bar">
                   <button type="button" mat-mini-fab (click)="toggleSearch()">
                     <i class="material-icons notranslate">
                       search
                     </i>
                   </button>
                 </div>
                 <div class="search-form">
                   <form>
                     <input type="text" placeholder="Search and hit enter">
                     <button type="button" mat-fab class="close-btn" (click)="toggleSearch()">
                       <i class="material-icons">
                         close
                       </i>
                     </button>
                   </form>
                 </div>
               </div>-->
           </div>
         </div>
       </mat-toolbar-row>
 </mat-toolbar>
 <embryo-FixedHeader></embryo-FixedHeader>
 