export class Domicilio{
    constructor(){
        this.last = false;
        this.provincia = {
            'ID':0,
            'nombre':''
        }

        this.localidad = {
            'ID':0,
            'nombre':''
        }
    }

    ID;
    personaID:string | number;
    provinciaID:string;
    localidadID:string;
    codigoPostal:string;
    direccion:string;
    numero: number;
    piso:number = null;
    depto:string = null;
    comentarios:string = null;
    longitud
    latitud
    precioEnvio
    //flag
    last:boolean;
    localidad : {
        'ID': number,
        'nombre':string
    }
    provincia : {
        'ID': number,
        'nombre':string
    }

}