
<button class="btn-outline-none" mat-mini-fab [matMenuTriggerFor]="viewwishlist" [matBadgeHidden]="hiddenBadge" matBadge="{{count}}" matBadgePosition="after" matBadgeColor="accent">
  <i class="material-icons notranslate">
    favorite
  </i>
</button>

<mat-menu #viewwishlist="matMenu">
  <ng-container *ngIf="(wishListProducts && wishListProducts.length > 0); else elseBlock">
      <div class="drop-wrap">
    <div class="cart-menu-list p-2" fxLayoutAlign="start center" *ngFor="let product of wishListProducts;">
      <div> 
        <a href="javascript:void(0);">
          <img appLazyLoad loading="lazy" (click)="verProducto(product?.ID)" [src]="appSettings.urls.hostFiles+product?.imgPortada+appSettings.imgXS" width="60" alt="cart-image">
        </a> 
      </div>
      <div class="px-3">
        <h6 class="mb-0"><a (click)="verProducto(product?.ID)" href="javascript:void(0);">{{product?.titulo}}</a></h6>
        <span>{{calculatePrice(product) | currency:currency}}</span>
      </div>
      <div class="actions-container">
        <button mat-mini-fab (click)="verProducto(product?.ID)" routerLinkActive="router-link-active"> <i class="material-icons">
            remove_red_eye
          </i> </button>
        <button mat-mini-fab (click)="confirmationPopup(product)"><i class="material-icons">
            remove
          </i></button>
      </div>
    </div>
  </div>
  </ng-container>
  <ng-template #elseBlock>
    <div class="cart-menu-list p-2" fxLayoutAlign="center center">
      No tienes productos favoritos.
    </div>
  </ng-template>
</mat-menu>
