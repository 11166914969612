
        
import { Injectable, inject } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { ModalLoginService, EmbryoService } from '@services'

        
@Injectable()
export class authPanel implements CanLoad {
    private localStorageService = inject(LocalStorageService)
    public flag;
    constructor(public router: Router,
                private login: ModalLoginService,
                public embryo: EmbryoService)
    {
        this.flag = false
    }
    canLoad(route: Route){
        const snVendedor = this.localStorageService.getVendedorInfo()
        console.log(snVendedor)
        if(snVendedor){
            return true
        }else{
            return false
        }
        
    }

  

}
