<button mat-mini-fab class="log-in-user" [matMenuTriggerFor]="loginuser">
  <img appLazyLoad loading="lazy" [src]="imgUser" style="height: 100%;" alt="log-in-user">
</button>
<!-- log-in-user -->
<mat-menu #loginuser="matMenu">
  <button mat-menu-item fxLayoutAlign="start center" (click)="irAPerfil()"><i class="material-icons mr-1">account_circle</i> <span>Perfil</span>
  </button>
  <!--<button mat-menu-item [routerLink]="['/account/profile']"><i class="material-icons mr-1">settings</i><span>Configuracion</span></button>-->
  <!--<button mat-menu-item [routerLink]="['/account/profile']"> <i class="material-icons mr-1">local_post_office</i> <span>Mensajes</span></button>-->
  <button mat-menu-item (click)="desconectarSession()"><i class="material-icons mr-1">power_settings_new</i><span>Cerrar Sesión</span></button>
</mat-menu>
