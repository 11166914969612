import { Component, EventEmitter, Input, OnInit, Output, Inject, Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppSettings } from '@settings'; //modulo global de direcciones.
import { Persona } from '@models';
import { EmbryoService, SessionService } from '@services';
import { RegisterComponent } from '@app/pages/Session/Register/Register.component';

import SHA3 from 'crypto-js/sha3';
import { NotificacionesService } from '@app/_services/notificaciones.service';
import { FavoritosService } from '@app/_services/favoritos.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { CompradorInfo, UserInfo, VendedorInfo } from '@app/_interfaces/local-storage-data.interface';
import { LocalStorageService } from '@app/_services/local-storage-service';
import { VerificacionEmailComponent } from '@app/Pages/Session/verificacion-email/verificacion-email.component';

@Component({
  selector: 'embryo-SignIn',
  templateUrl: './CommonSignIn.component.html',
  styleUrls: ['./CommonSignIn.component.scss']
})
export class CommonSignInComponent implements OnInit {
  @Input() controlLogin: boolean; //controla si al loguear rutea o solo cierra el modal
  private localStorageService = inject(LocalStorageService)

  @Output() cerrarModal: EventEmitter<any> = new EventEmitter();

  response;
  //user = new Persona();
  loginForm : UntypedFormGroup
  cargando;
  desactivada;
  sinConfirmarEmail
  userPassIncorrecto
  idPersona
  emailRegistro
  constructor(  
    private service_session: SessionService,
    private _serviceNotificaciones : NotificacionesService,
    private router: Router,
    public embryo: EmbryoService,
    public appSettings: AppSettings,
    public dialog: MatDialog,
    private _favoritosService : FavoritosService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.desactivada = false; // VARIABLE FLAG DE CUENTA DESACTIVADA
    this.cargando = false; // VARIABLE FLAG DE CARGANDO.
    this.userPassIncorrecto = false
    this.loginForm = this.formBuilder.group({
      user: new UntypedFormControl('',Validators.required),
      password: new UntypedFormControl('',Validators.required)
    })
  }


  /* METODO QUE SE UTILIZA PARA INICIAR SESSION CON REDES SOCIALES O POR BD PROPIA */
  public iniciar(red) {
      this.login('comun');
  }

  /* FIN | METODO QUE SE UTILIZA PARA INICIAR SESSION CON REDES SOCIALES O POR BD PROPIA */

  /* METODO DE LOGEO EN LA API DE ECOMMERCE, TANTO CON GOOGLE COMO INTERNO */
  async login(tipo) {
    this.desactivada = false;
    this.cargando = true;
    this.loginForm.markAllAsTouched()
      /* LOGEO INTERNO */
      if (tipo == 'comun' && this.loginForm.valid) {
        let user = {
          user : this.loginForm.get('user').value,
          password: this.loginForm.get('password').value
        }
        this.service_session.login(user, tipo).pipe(take(1)).subscribe({
          next: res=>{
            console.log("login",res)
            if (res.code === 201) {
              //LIMPIAMOS POR SI HAY BASURA
              localStorage.clear();
    
              //SI NO TIENE NINGUN FLAG EN TRUE (VENDEDOR/COMPRADOR) LO MANDO A COMPLETAR DATOS
              if((!res.data.snVendedor) && (!res.data.snComprador)){
                console.log('no es comprador ni vendedor')
                //this.router.navigate(['session/register',res.data.ID])
                this.router.navigate(['session/register/tipo-usuario'],{queryParams:{id:res.data.ID}})
              }else{
                //INFORMACION DE USUARIO
                let userInfo:UserInfo = {
                  pw: SHA3(user.password).toString(),
                  ID: res.data.ID,
                  nombre: res.data.nombre,
                  email:res.data.mail,
                  telefono:res.data.telefono,
                  user:user.user,
                  genero:res.data.genero,
                  foto: (res.data.foto)? res.data.foto : 'files/personas/default',
                  estado:'conectado'
                }
                this.localStorageService.setUserInfo(userInfo)
                this._serviceNotificaciones.getCantidadNoLeidas(userInfo.ID)
    
                //INFORMACION DE COMPRADOR
                if (res.data.comprador) {
                  this.cargarFavoritos(res.data.comprador.ID)
                  let compradorInfo: CompradorInfo = res.data.comprador
                  this.localStorageService.setCompradorInfo(compradorInfo)
                  if(res.data.domicilios){
                    this.guardarDomicilio(res.data.domicilios[0])
                  }
                }
    
                //INFORMACION DE VENDEDOR
                if (res.data.vendedor) {
                  let vendedorInfo:VendedorInfo = {...res.data.vendedor,email:res.data.vendedor.mail}
                  this.localStorageService.setVendedorInfo(vendedorInfo)
                }
    
                this.service_session.notificarLoginStatus()
                if(!this.controlLogin){
                  if (res.data.snComprador) {
                    this.router.navigate([`/home`]);
                  } else {
                    if (res.data.snVendedor && res.data.vendedor.snMobbex && res.data.vendedor.nSucursales>0) {
                      this.router.navigate([`/admin-panel`]);
                    } else {
                      if(res.data.snVendedor && (res.data.vendedor.snMobbex || res.data.vendedor.nSucursales == 0)){
                        this.router.navigate([`/admin-panel/account/profile`]);
                      }
                    }  
                  }
                }
                this.cerrarModal.emit(true);
              }
            } else {
              if (res.code === 202) {
                console.log(res);
                if (res.msg === 'PENDIENTE') {
                  this.idPersona = res.data.ID
                  this.emailRegistro = res.data.mail
                  this.sinConfirmarEmail = true
    
                } else {
                  this.desactivada = true
                }
                this.userPassIncorrecto = false
              } else if (res.code === 404) {
                this.desactivada = false
                this.userPassIncorrecto = true
              }
              else {
                this.cerrarModal.emit(false);
              }
            }

          }
        });
      }
    /* FIN | LOGEO INTERNO */
    this.cargando = false;
  }
  /*FIN | METODO DE LOGEO EN LA API DE ECOMMERCE, TANTO CON GOOGLE COMO INTERNO */

  completarRegistro() {
    this.router.navigate(['session/register/validar-codigo'],{
      queryParams:{id:this.idPersona,email:this.emailRegistro}
    })
  }

  /*METODO QUE RUTEA A REGISTRAR CUENTA Y CIERRA MODAL EN CASO DE HABER ENTRADO POR EL CARRITO */
  public register() {
    if (this.controlLogin)
      this.cerrarModal.emit(true);
    this.router.navigate(['/session/register'])
  }
  /*FIN | METODO QUE RUTEA A REGISTRAR CUENTA Y CIERRA MODAL EN CASO DE HABER ENTRADO POR EL CARRITO  */

  /*METODO QUE CARGA EL LISTADO DE FAVORITOS DEL USUARIO COMPRADOR EN EL PRIMER INICIO DE SECION*/

  async cargarFavoritos(idComprador) {
    let result = await this._favoritosService.getFavoritos(idComprador)
    console.log(result)
    if(result['code']!=404){
      let products = []
      result['data'].forEach(favorito => {
        favorito.publicacion.idFavorito = favorito.ID
        products.push(favorito.publicacion)
      });
      localStorage.setItem("wishlist_item", JSON.stringify(products))
      this._favoritosService.calculateLocalWishlistProdCounts()
      //para que se ejecute la deteccion de cambios desde wishlist
      this.service_session.notificarRefreshFavoritos()
    }
    
  }

  /*FIN | METODO QUE CARGA EL LISTADO DE FAVORITOS DEL USUARIO COMPRADOR EN EL PRIMER INICIO DE SECION*/

  /*  MÉTODO QUE CARGA EL DNI DEL COMPRADOR */

  async guardarDomicilio(domicilio){
    console.log("domicilio en login", domicilio)
    this.localStorageService.setDomicilioInfo(domicilio)
  }

  /* FIN |  MÉTODO QUE CARGA EL DNI DEL COMPRADOR */
}
