
<!--  Top menu -->
<nav class="app-nav">
	<ul class="app-nav-list" [ngClass]="{'closeHover': !cerrarHover}">
		<li class="app-nav-item" routerLinkActive="active-nav" *ngFor="let item of itemsMenu" [ngClass]="{'mega': item.mega}">
			<div class="dark-background" *ngIf="item.type == 'sub'"></div>	
			<ng-container  *ngIf="item.type == 'sub'">
				<a id="cats" class="nav-link" routerLinkActive="active-link">
				  {{item.name}}
				</a>
				
			</ng-container>
			
			<a class="nav-link" *ngIf="item.type == 'link' && (item.name != 'Panel' && item.name != 'Registrar tu negocio')" [routerLink]="['/'+item.state]" routerLinkActive="active-link">
				  {{item.name}}
			</a>
			<a class="nav-link" *ngIf="item.type == 'link' && (item.name == 'Panel' && flagVendedor)" [routerLink]="['/'+item.state]" routerLinkActive="active-link">
				{{item.name}}
			</a>

			<a class="nav-link" *ngIf="item.type == 'link' && (item.name == 'Registrar tu negocio' && !(flagVendedor || flagComprador))" [routerLink]="['/'+item.state]" routerLinkActive="active-link">
				{{item.name}}
			</a>
			
				<div class="container sub-menu row col-10 sidebar-panel" *ngIf="item.children && item.children.length >0">
					
					<div class="col-3 navPadres">
						<div *ngFor="let child of item.children; let i = index">
							<div class="col-12" [ngClass]= "{'menu-item-has-children': child.type == 'subChild', 'active': !child.hidden}" (mouseover)="activar(i, item.children)" >
								<a class="categoriasPadres" (click)="cerrarModal()" [routerLink]="['/'+child.state]" routerLinkActive="active-link" >
									<div style="display: flex; align-items: center;">
										<div class="mr-2" style="display: flex;">
											<span [ngClass]="child.iconCustom" class="icono-categoria"></span>
										</div>
										<div>
											<span>{{child.name}}</span>
										</div>
									</div>
								</a>
							</div>
							
						</div>
					</div>
					<div class="col-9 navChild">
						<div *ngFor="let child of item.children; let i = index">
							<div class="sub-menu row" style="display: flex;" *ngIf="child.children && child.children.length >0 && !child.hidden">
								<div class="col-lg-4 col-sm-6 children" *ngFor="let subChild of child.children" >
									<a *ngIf="subChild.type == 'queryParams'" (click)="cerrarModal()" class="nav-link subChild"  (click)="redirectTo(subChild)">
										{{subChild.name.substring(0,30)}} <span *ngIf="subChild.name.length > 30"> ...</span>
									</a>
									<a *ngIf="subChild.type == 'link'" (click)="cerrarModal()" class="nav-link subChild"  [routerLink]="['/'+subChild.state]">
										{{subChild.name.substring(0,30)}} <span *ngIf="subChild.name.length > 30"> ...</span>
									</a>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				
				
		</li>
	</ul>
</nav>
<!--
	 
-->