import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Settings } from './app.settings.model';

@Injectable()
export class AppSettings {
    //public indexName = 'dev_hiperabasto' //algolia desarrollo
    //public indexName = 'test_hiper' //algolia produccion
    public imgXS = '-xs.jpg';
    public imgSM = '-sm.jpg';
    public imgMD = '-md.jpg'
    public imgLG = '-lg.jpg';
    public imgPubSM = '-w300';
    public imgPubLG = '-w1110';
    public urls = {

        //hostSuper : 'https://crm.ifelsesl.com:443/api_super', //API IFELSE 
        //hostSuper : 'http://lautaropalacin.com.ar:5000/api_super', //API IFELSE TEST

        //host: 'https://hiperabasto.com/api',
        //host: 'http://192.168.0.14:5000/api',
        //host: 'https://repo.ifelse.com.ar:5000/api', // API DESARROLLO
        //host: 'https://api.hiperabasto.com/api',//API PRODUCCION
        host: environment.host,

        hostFiles: environment.hostFiles, // FILES PRODUCCION
        //hostFiles: 'https://repo.ifelse.com.ar:5000/',//FILES DESARROLLO

        
        publicidad : '/pubs',
        extras : '/extras',
        categorias: '/categorias',
        carritos: '/carritos',
        productos : '/productos',
        marcas: '/marcas',
        publicaciones: '/publicaciones',
        unidadesMedidas: '/unidades-medidas',
        personas : '/personas',
        domicilios : '/personas/domicilios',
        vendedores: '/personas/vendedores',
        favoritas: '/favoritas',
        tarjetas : '/personas/tarjetas',
        preguntas: '/publicaciones/preguntas',
        preguntas_frecuentes : '/preguntas-frecuentes',
        sucursales: '/personas/vendedores/sucursales',
        envios: '/envios',
        notificaciones: '/notificaciones'
    };
    
    /*public settings = new Settings(
        'Generico',
        'Angular Admin Template with Bootstrap 4',
        {
            menu: 'vertical', //horizontal , vertical
            menuType: 'default', //default, compact, mini
            showMenu: true,
            navbarIsFixed: true,
            footerIsFixed: false,
            sidebarIsFixed: true,
            showSideChat: false,
            sideChatIsHoverable: true,
            skin:'combined'  //light , dark, blue, green, combined, purple, orange, brown, grey, pink          
        }
    )*/
}