import {Injectable, inject} from '@angular/core';
import { io } from 'socket.io-client';
import {Observable, Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { LocalStorageService } from './local-storage-service';


@Injectable()



export class WebSocketService {
    private socket
    public notification = new Subject()
    private localStorageService = inject(LocalStorageService)
    
    

    constructor(){
    }

    

    disconnectNotificaciones(){
        this.socket?.disconnect()
    }


    connectNotificaciones(): Subject<MessageEvent>{
        let room 
        if(this.localStorageService.getCompradorInfo().ID){
            room = 'COMPRADOR'
        }else{
            room = 'VENDEDOR'
        }
        this.socket = io(environment.socket,{
            query:{
                personaID:this.localStorageService.getUserInfo().ID,
                room : room
            },
            reconnectionAttempts:10,
            reconnectionDelay:10000
        });


        let observable = new Observable(observer =>{
            this.socket.on('notificado',data=>{
                console.log(data)
                observer.next(data);
            })
            
            this.socket.on('disconnect',res=>{
                console.log('desconectado',res)
            })
            return () =>{
                this.socket.disconnect();
            }
        })

        let observer ={
            next :(data:Object)=>{
                console.log("emit notificar connect",data)
                this.socket.emit('notificar')
            },

            desconectar:()=>{
                this.socket.disconnect()
            }
        };
        
        return Subject.create(observer,observable)
    }
}