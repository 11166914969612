import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { ComisionesInterface } from "@app/_interfaces/comision.interface";
import { environment } from "@environments/environment";
import { map, take } from "rxjs/operators";

@Injectable({providedIn:'root'})

export class ComisionesService{
    private http = inject(HttpClient)
    private url = environment.host

    public getComisiones(){
        return this.http.get<{code:Number,data:Array<ComisionesInterface>}>(`${this.url}/extras/comisiones`)
    }

    /**
     * 
     * @returns valor porcentual (%) total de todas las comisiones de la plataforma (pasarela de pago, hiperabasto, etc)
     */
    public getComisionVentaTotal(){
        return this.getComisiones().pipe(
            take(1),
            map((res)=>{
                let comisionCompra: number = 0
                let comisionPagos360: number = 0
                let comisionHiper: number = 0
                if(res.code==200){
                   //return res.data.reduce((total,comision)=>total+comision.porcentaje,0)
                   res.data.forEach(comision=>{
                        if(comision.ID == 2){
                            comisionHiper = comision.porcentaje
                        }
                        if(comision.ID == 3){
                            comisionPagos360 = comision.porcentaje
                        }
                        if(comision.ID == 1 || comision.ID ==2){
                            comisionCompra += comision.porcentaje
                        }
                   })
                }
                return {comisionCompra,comisionPagos360,comisionHiper}
            })
        )
    }
}