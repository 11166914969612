
  <div class="dialog-container">
    <div mat-dialog-content>
        <div class="d-flex">
            <div>
                <div class="sign-in-form">
                    <h4>Iniciar Sesion</h4>
                    <embryo-SignIn [controlLogin]="true" (cerrarModal)="onNoClick($event)" ></embryo-SignIn>
                </div>
            </div>
        </div>
    </div>
  </div>

<!--

<div class="w50">
    <div mat-dialog-content>
        <div class="container">
            <mat-card class="sign-in-form form-margin">
                <h4>Iniciar Sesion</h4>
                <embryo-SignIn [controlLogin]="true"></embryo-SignIn>
            </mat-card>
        </div>
    </div>
</div>

-->